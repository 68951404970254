import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import Form1 from './Form1'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import Form2 from './Form2'
import Spinner from '../../../_metronic/partials/spinner/Spinner'

// const nameValidation = Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Name is required')
const nameValidation = /^[A-Za-z\s]+$/
const editUserSchema = Yup.object().shape({
  tel: Yup.string().required('Le numéro de téléphone est requis'),
  name: Yup.string()
    .matches(nameValidation, 'Le nom ne peut contenir que des lettres')
    .required('Le nom est requis'),
  email: Yup.string().email("Format d'email incorrect").required("L'email est requis"),
  password: Yup.string().min(6, 'Minimum 6 caractères').required('Le mot de passe est requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
  adress: Yup.string().required("L'adresse est requise"),
  VATNumber: Yup.string().required('le numéro de TVA  est requise'),
  zipCode: Yup.string().required('Le code postal est requis'),
})
const AddComany = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [image, setImage] = useState<File | any>()
  const [filess, setFiles] = useState<any>()
  const [formDoc, setFormDoc] = useState<any>([])
  const [documents, setDocuments] = useState<any[]>([])

  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }
  //console.log(updateData)

  // get driver when update
  const getDriver = async (id) => {
    try {
      const data = await callApi({
        route: `companies/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
      setFiles(`${process.env.REACT_APP_API_URL}/${data?.photo}`)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  const getForm = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?&model=company&countries=${
          formik.getFieldProps('country').value
        }`,
        method: 'GET',
        jsonLd: true,
      })

      const form = data['hydra:member'][0].formDocuments
      setFormDoc(form) // Clear formDoc by setting it to an empty array
      // Clear documents by setting it to an empty array
      setDocuments([])
      let elements: any = []
      let objects: any = []

      for (let index = 0; index < form.length; index++) {
        const elements2 = form[index].elementFormDocuments
        const title = form[index].title
        elements = []
        for (let secondIndex = 0; secondIndex < elements2.length; secondIndex++) {
          const element = elements2[secondIndex]
          // elements.push(element)
          const obj = {
            label: element.label,
            name: element.id,
          }
          elements.push(obj)
        }
        objects.push({title, state: 'state', elements})
      }
      setDocuments(objects)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    if (id) {
      getDriver(id)
    }
  }, [id])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tel: updateData.tel ? updateData.tel : '',
      type: updateData.type ? updateData.type : '',
      name: updateData.name ? updateData.name : '',
      email: updateData.email ? updateData.email : '',
      password: updateData.password ? updateData.password : '',
      confirmPassword: updateData.password ? updateData.password : '',
      country: updateData?.pay?.id,
      state: updateData?.state?.id,
      city: updateData?.city?.id,
      adress: updateData.adress ? updateData.adress : '',
      zipCode: updateData.zipCode ? updateData.zipCode : '',
      language: updateData?.language,
      currency: '',
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true)
      setSubmitting(true)
      const postData = {
        // company: values.company,
        language: values.language ? `languages/${values.language}` : null,
        zipCode: String(values.zipCode),
        photo: image ? image[0] : '',
        adress: values.adress,
        state: values.state ? `states/${values.state}` : null,
        dispo: false,
        password: values.password,
        tel: values.tel,
        name: values.name,
        type: values.type,
        email: values.email,
        ...documents.reduce((acc, obj) => {
          obj.elements.forEach((element) => {
            const key = element.name
            if (values[key]) {
              acc[element.name] = values[key]
            }
          })
          return acc
        }, {}),
        documents,
      }
      //console.log(postData)

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postDriver = async () => {
          try {
            const data = await callApi({
              route:
                location.pathname == '/add-company' ? 'companies' : `update_company/${id}`,
              method: 'POST',
              body: postData,
              formData: true,
            })
            if (data) {
              successToas('success')
              navigate('/company-management')
            }
          } catch (error: any) {
            setIsLoading(false)

            errorToas(error.response['data'].detail)
          }
        }
        postDriver()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        // setIsLoading(false)
        setSubmitting(false)
      }
    },
  })
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }
  useEffect(() => {
    getForm()
  }, [formik.getFieldProps('country').value])
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      {currentStep === 1 && (
        <Form1
          formik={formik}
          isUserLoading={isUserLoading}
          setImage={setImage}
          image={image}
          setFiles={setFiles}
          filess={filess}
        />
      )}
      {currentStep === 2 && (
        <Form2
          form={formDoc}
          updateData={updateData}
          setIsLoading={setIsLoading}
          uploadedFiles={uploadedFiles}
          handleFileUpload={handleFileUpload}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      )}
      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {currentStep > 1 && (
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={handlePreviousStep}
            disabled={formik.isSubmitting || isUserLoading}
          >
            Previous
          </button>
        )}
        {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleNextStep}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Next
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e: any) => {
                  setIsLoading(true)
                  formik.handleSubmit(e)
                }}
                // disabled={
                //   formik.isSubmitting || isUserLoading || isLoading || !formik.isValid
                //     ? true
                //     : false
                // }
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AddComany
