import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'
import Form1 from './Form1'
const languages = [
  { key: 'en', code: 'english', label: 'Anglais' },
  { key: 'de', code: 'deuatsche', label: 'Deutsche' },
  { key: 'es', code: 'spanish', label: 'Spanish' },
  { key: 'it', code: 'italy', label: 'Italian' },
  
]
const required = 'is a required field'
const editUserSchema = Yup.object().shape({
  // name: Yup.string().required('Le nom est requis'),
  vehiculeCategory: Yup.string().required(`Catégorie de véhicule ${required}`),
  vehiculeCategoryBooking: Yup.string().required(`Vehicle type for Booking.com ${required}`),
  vehicleType: Yup.string().required('Type de véhicule is a required field'),
  ...languages.reduce((schema, {code}) => {
    schema[`description${code}`] = Yup.string().required(`Description ${required}`)
    return schema
  }, {}),
})
const AddPromoCode = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading


  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  // get driver when update
  const getCity = async (id) => {
    try {
      const data = await callApi({
        route: `cities/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getCity(id)
    }
  }, [id])

  useEffect(() => {}, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //console.log(values)
      setSubmitting(true)
      // const postData = {
      //   name: values.name,
      //   state: `states/${values.state}`,
      //   dispo: false,
      //   code: values.code,
      //   pay: `countries/${values.country}`,
      //   status: values.status,
      // }
      //console.log(values)

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      // try {
      //   const postCity = async () => {
      //     setIsLoading(true)
      //     try {
      //       const data = await callApi({
      //         route:
      //           location.pathname === '/city-management/add' ? 'cities' : `cities/${id}`,
      //         method: location.pathname === '/city-management/add' ? 'POST' : `PUT`,
      //         body: postData,
      //       })
      //       if (data) {
      //         setIsLoading(false)
      //         successToas('success')
      //         navigate('/city-management')
      //       }
      //     } catch (error: any) {
      //       setIsLoading(false)
      //       errorToas(error.response['data'].detail)
      //     }
      //   }
      //   postCity()
      //   // //console.log({...values, image})
      //   // Handle form submission here, e.g., call an API to create the user
      // } catch (ex) {
      //   console.error(ex)
      // } finally {
      //   setSubmitting(false)
      // }
    },
  })
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <Form1 formik={formik} isUserLoading={isUserLoading} languages={languages} />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleNextStep}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Submit
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e: any) => formik.handleSubmit(e)}
                disabled={formik.isSubmitting || isUserLoading || !formik.isValid}
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AddPromoCode
