import React from 'react';
import HearthCheckBox from '../../customs/checkBox/hearthCheckBox';

type Props = {
  value?: any;
};

const SimpleInfo: React.FC<Props> = ({ value }) => {
  const customClass = 'w-125px break-words text-start'; 

  return (
    <p className={customClass} >{value}</p>
  );
};

export default SimpleInfo;
