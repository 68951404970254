import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery, useQueryClient} from 'react-query' // Updated import to include useQueryClient
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  stringifyRequestQueryy,
  WithChildren,
} from '../../../../../../../_metronic/helpers'
import {getModels} from './_requests'
import {User} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<User>(initialQueryResponse)

const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state, updateState} = useQueryRequest()

  const [query, setQuery] = useState<any>(stringifyRequestQueryy(state))
  // const [query, setQuery] = useState<any>('drivers?itemsPerPage=10&page=1')

  const updatedQuery = useMemo(() => stringifyRequestQueryy(state), [state])
  const queryClient = useQueryClient() // Get access to the query client

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  // Define your custom query key based on your needs
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    query,
    () => {
      // Fetch your data using your custom query logic
      return getModels(`models?${query}`)
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  // Here, you can also use queryClient to manually trigger refetch or invalidate queries

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (response == undefined) {
    return []
  }
  return response || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()

  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
