import React, {FC, useEffect, useState} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
import {getCountries} from '../../../../_metronic/helpers/Requests/Requests'
import LabelTextArea from '../../../../_metronic/partials/LabelTextArea'
type Props = {
  formik: any
  isUserLoading: any
}
const GeneralSeting: FC<Props> = ({formik, isUserLoading}) => {
  const [countries, setCountries] = useState([])
  useEffect(() => {
  
    getCountries(setCountries)
  }, [])
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <label htmlFor='maintenanceModeForWebsite' className='required form-label'>
            Mode maintenance pour le site Web
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.maintenanceModeForWebsite}
            name={'maintenanceModeForWebsite'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='maintenanceModeForApps' className='required form-label'>
            Mode maintenance pour les applications
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.MaintenanceModeForApps}
            name={'maintenanceModeForApps'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Non-présentation du conducteur - pénalité au conducteur (%) '}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'driverNoShowPenaltyToDriver'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Conducteur en retard - pénalité au conducteur (%)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'driverLatePenaltyToDriver'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Nom du projet'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'projectName'}
          />
        </div>

        <div className=' mb-4'>
          <label htmlFor='enableProviderEmailVerification' className='required form-label'>
            Activer la vérification de l'e-mail du fournisseur{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableProviderEmailVerification}
            name={'enableProviderEmailVerification'}
            formik={formik}
          />
        </div>
        <div className=' mb-4'>
          <label htmlFor='enableUserEmailVerification' className='required form-label'>
            Activer la vérification de l'e-mail de l'utilisateur{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableUserEmailVerification}
            name={'enableUserEmailVerification'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Code ISD du countries de l'administrateur"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'adminCountryIsdCode'}
          />
        </div>
        <div className=' mb-4'>
          <label htmlFor='countryCode' className='required form-label'>
            Code postal{' '}
          </label>
          <SearchSelect
            options={countries}
            defaultValue={formik.values.countryCode}
            name={'countryCode'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='defaultDistanceUnit' className='required form-label'>
            Unité de distance par défaut{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'Kms', value: 'kms'},
              {label: 'Miles', value: 'miles'},
            ]}
            defaultValue={formik.values.defaultDistanceUnit}
            name={'defaultDistanceUnit'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Afficher l'enregistrement par page dans le panneau d'administration"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'displayRecordPerPageInAdminpanel'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Montant du parrainage au crédit du programme de parrainage'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'referralAmountToCreditInReferralScheme'}
          />
        </div>
        <div className='row mb-4'>
          <LabelTextArea
            formik={formik}
            name={'googleAnalyticsCode'}
            label={'Code Google Analytics'}
            isUserLoading={isUserLoading}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='enableReferralSystemInApplication' className='required form-label'>
            Activer le système de référence dans l'application{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableReferralSystemInApplication}
            name={'enableReferralSystemInApplication'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Montant fixe du portefeuille 1'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'walletFixedAmount1'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Montant fixe du portefeuille 2'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'walletFixedAmount2'}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'Montant fixe du portefeuille 3'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'walletFixedAmount3'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Contrainte de temps pour réserver plus tard (heures)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'bookLaterTimeConstraintHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Annuler la contrainte de temps de réservation (heures)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'ridePushBackToMarketConstraintHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Contrainte de distance pour commencer le voyage (mètre)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'distanceConstraintToStartTripMeter'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Contrainte de temps de début de trajet (heures)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'startTripTimeConstraintHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Délai de notification push (minutes)'}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'pushNotificationTimeDelayMinutes'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Délai d'annulation pour l'organisation (heures)"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'cancelTimeForOrganizationHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Reprogrammer l'heure pour l'organisation (heures)"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'rescheduleTimeForOrganizationHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Contrainte de temps pour réserver plus tard (heures)"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'corporateBookLaterTimeConstraintHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Contrainte de temps d’annulation de réservation d’entreprise (heures)"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'corporateCancelBookingTimeConstraintHours'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Booking.Com CLIENT_SECRET"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'bookingClientSecret'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Booking.Com CLIENT_ID"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'bookingClientId'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"URL de l'API des informations d'identification du client Booking.Com"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'bookingClientCredentialsAPIUrl'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"URL de l'API Booking.Com"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'bookingApiUrl'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Temps mort pour la mise sur le marché automatique du trajet Booking.Com (heures)"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={"deadTimeBookingRideGoingMrketAutomaticallyHours"}
          />
        </div>
      </div>
    </div>
  )
}

export default GeneralSeting
