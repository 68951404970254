import {ChangeEvent, FC, useState} from 'react'
import * as Yup from 'yup'
import {Field, useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialDrive, Drive} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {Step1} from '../../../../wizards/components/steps/Step1'
import {Step2} from '../../../../wizards/components/steps/Step2'
import {Step3} from '../../../../wizards/components/steps/Step3'
import {Step4} from '../../../../wizards/components/steps/Step4'
import {Step5} from '../../../../wizards/components/steps/Step5'

type Props = {
  isUserLoading: boolean
  drive: Drive
}

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const DriveEditModalForm: FC<Props> = ({drive, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [userForEdit] = useState<Drive>({
    ...drive,
    avatar: drive.avatar || initialDrive.avatar,
    sexe: drive.sexe || initialDrive.sexe,
    position: drive.position || initialDrive.position,
    name: drive.name || initialDrive.name,
    email: drive.email || initialDrive.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values)
        } else {
          await createUser(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              Photo :
            </label>
            <div className='form-group mb-5  '>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
              >
                <div
                  id='image'
                  className='image-input-wrapper w-125px h-125px'
                  style={{
                    backgroundImage: filess == '' ? "url('')" : 'url(' + filess + ')',
                  }}
                ></div>
                <label
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Change avatar'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // const reader = new FileReader();
                      // reader.onload=function(e){
                      //   setUrl(e.target?.result)
                      // }
                      // reader.readAsDataURL(e.target.files[0])
                      if (e.target.files && e.target.files[0]) {
                        setFiles(URL.createObjectURL(e.target.files[0]))
                        setImage(Array.from(e.target.files))
                      }
                    }}
                    type='file'
                    name='image'
                    accept='.png, .jpg, .jpeg'
                  />
                  <input type='hidden' name='avatar_remove' />
                </label>

                <span
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              </div>
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row row mb-7'>
            <div className='col'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Nom </label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Nom'
                {...formik.getFieldProps('nom')}
                type='text'
                name='nom'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Prénom </label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Prénom'
                {...formik.getFieldProps('prenom')}
                type='text'
                name='prenom'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}
          </div>

          <div className='row'>
            {/* begin::Input group */}
            <div className='col mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Email</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {/* end::Input */}
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Input group */}
            {/* start::input password */}
            <div className='col'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Mots de passe </label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Mots de passe'
                {...formik.getFieldProps('password')}
                type='password'
                name='password'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::input password */}
          </div>

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>Sexe</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='row justify-content-start'>
              <div className='col'>
                {' '}
                <div className='d-flex fv-row'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('sexe')}
                      name='sexe'
                      type='radio'
                      value='f'
                      id='f'
                      checked={formik.values.sexe === 'f'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='f'>
                      <div className='fw-bolder text-gray-800'>Femme</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
              </div>
              <div className='col'>
                {' '}
                <div className='d-flex fv-row'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('sexe')}
                      name='sexe'
                      type='radio'
                      value='h'
                      id='h'
                      checked={formik.values.sexe === 'h'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='h'>
                      <div className='fw-bolder text-gray-800'>Homme</div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
              </div>
            </div>

            {/* end::Input row */}
            {/* <div className='separator separator-dashed my-5'></div> */}
            {/* begin::Input row */}

            {/* ------- */}
            {/* end::Input row 
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row 
            <div className='d-flex fv-row'>
              {/* begin::Radio 
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input 
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Analyst'
                  id='kt_modal_update_role_option_2'
                  checked={formik.values.role === 'Analyst'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                {/* end::Input 
                {/* begin::Label 
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-bolder text-gray-800'>Analyst</div>
                  <div className='text-gray-600'>
                    Best for people who need full access to analytics data, but don't need to update
                    business settings
                  </div>
                </label>
                {/* end::Label 
              </div>
              {/* end::Radio 
            </div>
        
            {/* end::Input row
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row
            <div className='d-flex fv-row'>
              {/* begin::Radio
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  value='Support'
                  id='kt_modal_update_role_option_3'
                  checked={formik.values.role === 'Support'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input
                {/* begin::Label
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_3'>
                  <div className='fw-bolder text-gray-800'>Support</div>
                  <div className='text-gray-600'>
                    Best for employees who regularly refund payments and respond to disputes
                  </div>
                </label>
                {/* end::Label
              </div>
              {/* end::Radio
            </div>
            {/* end::Input row
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row
            <div className='d-flex fv-row'>
              {/* begin::Radio
              <div className='form-check form-check-custom form-check-solid'>
                {/* begin::Input
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('role')}
                  name='role'
                  type='radio'
                  id='kt_modal_update_role_option_4'
                  value='Trial'
                  checked={formik.values.role === 'Trial'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input
                {/* begin::Label
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_4'>
                  <div className='fw-bolder text-gray-800'>Trial</div>
                  <div className='text-gray-600'>
                    Best for people who need to preview content data, but don't need to make any
                    updates
                  </div>
                </label>
                {/* end::Label
              </div>
              {/* end::Radio
            </div>
            {/* end::Input row
            {/* end::Roles
                {/* ---- */}
          </div>
          <div className=' row mb-7'>
            <div className='col mb-4'>
              <label htmlFor='country' className='form-label'>
                Countries
              </label>
              <select name='country' id='country' className='form-select'>
                <option value='france'>France</option>
                <option value='marocs'>Maroc</option>
              </select>
            </div>
            <div className='col mb-4'>
              <label htmlFor='state' className='form-label'>
                Province
              </label>
              <select className='form-select' name='state' id='state'>
                <option value='france'>pr1</option>
                <option value='marocs'>pr2</option>
              </select>
            </div>
            <div className='col'>
              <label htmlFor='city' className='form-label'>
                Ville
              </label>
              <select className='form-select' name='city' id='city'>
                <option value='france'>city1</option>
                <option value='marocs'>city2</option>
              </select>
            </div>
          </div>
          {/* start input adress */}
          <div className='row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Adresse </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Adresse'
              {...formik.getFieldProps('adress')}
              type='text'
              name='adress'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.adress && formik.errors.adress},
                {
                  'is-valid': formik.touched.adress && !formik.errors.adress,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end input adress */}
          {/* start input zip code */}
          <div className='row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'> Code postal </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder=' Code postal '
              {...formik.getFieldProps('zipCode')}
              type='number'
              name='zipCode'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
                {
                  'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end input zip code */}
          {/* start input company */}
          <div className='row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'> Entreprise </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Entreprise'
              {...formik.getFieldProps('company')}
              type='text'
              name='company'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.company && formik.errors.company},
                {
                  'is-valid': formik.touched.company && !formik.errors.company,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='row mb-7'>
            <label htmlFor='language' className='form-label'>
              Langue
            </label>
            <select name='language' id='language' className='form-select'>
              <option value='france'>France</option>
              <option value='arabe'>Arabe</option>
            </select>
          </div>
          <div className='row mb-7'>
            <label htmlFor='currency' className='form-label'>
              Devise
            </label>
            <select name='currency' id='currency' className='form-select'>
              <option value='eur'>EUR</option>
              <option value='mad'>MAD</option>
            </select>
          </div>
          <div className='row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email de paiement</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email de paiement'
              {...formik.getFieldProps('paymentEmail')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.paymentEmail && formik.errors.paymentEmail},
                {
                  'is-valid': formik.touched.paymentEmail && !formik.errors.paymentEmail,
                }
              )}
              type='email'
              name='paymentEmail'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.paymentEmail && formik.errors.paymentEmail && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.paymentEmail}</span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'> Nom du titulaire du compte </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder=' Nom du titulaire du compte '
              {...formik.getFieldProps('AccountHolderName')}
              type='text'
              name='AccountHolderName'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.AccountHolderName && formik.errors.AccountHolderName},
                {
                  'is-valid': formik.touched.AccountHolderName && !formik.errors.AccountHolderName,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.AccountHolderName && formik.errors.AccountHolderName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.AccountHolderName}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>

          {/* end input zip code */}

          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {DriveEditModalForm}
