import {type} from 'os'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {FC} from 'react'
type Props = {
  setHeaderFilter?: any
  headerFilter?: any
  handleDeleteUser: any
}
const VehiculeDriverHeader: FC<Props> = ({setHeaderFilter, headerFilter, handleDeleteUser}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setHeaderFilter={setHeaderFilter} headerFilter={headerFilter} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <UsersListGrouping handleDeleteUser={handleDeleteUser} />
        ) : (
          <UsersListToolbar />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {VehiculeDriverHeader}
