import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'

import QuilleModule from '../../../_metronic/partials/QuilleModule'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelTextArea from '../../../_metronic/partials/LabelTextArea'
import {TranslateText} from '../TranslateText/TranslateText'

type Props = {
  isUserLoading: boolean
  formik: any
  languages: any
}

const Form1: FC<Props> = ({formik, isUserLoading, languages}) => {
  // useEffect(() => {
  //   const translateText = async (key, text) => {
  //     return await TranslateText (key, text)
  //   }

  //   const inputText = formik.getFieldProps(`englishBody`).value

  //   if (inputText && inputText.length > 0) {
  //     languages.forEach(async (item) => {
  //       if (item.key !== 'en'&& item.key!=="fr" ) {
  //         const translatedText = await translateText(item.key, inputText)
  //         formik.setFieldValue(`${item.code}Body`, translatedText)
  //       }
  //     })
  //   }
  // }, [formik.getFieldProps(`englishBody`).value])

  const languageInputs = languages.map((language) => (
    <div key={language.code}>
      <div className={`row mb-4 `}>
        <LabelTextArea
          formik={formik}
          name={`${language.code}Body`}
          label={language.label}
          isUserLoading={isUserLoading}
          placeholder={'Message'}
          Class={language.key == 'ar' ? 'text-end' : ''}
        />
      </div>
    </div>
  ))

  return (
    <div>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={'But'}
          name={'name'}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={'label'}
          name={'code'}
          isUserLoading={isUserLoading}
        />
      </div>
      {languageInputs}
    </div>
  )
}

export default Form1
