import React, {useState} from 'react'
import {Rating} from 'react-simple-star-rating'

export function Reating() {
  const [rating, setRating] = useState(3)

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)
  }

  const handleReset = () => {
    // Set the initial value
    setRating(0)
  }

  return (
    <div className='App'>
      {/* set initial value */}
      <Rating readonly onClick={handleRating} initialValue={rating} />
    </div>
  )
}
