import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import LabelInput from '../../../_metronic/partials/LabelInput'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
  setMyidCountries?: any
}

const Form1: FC<Props> = ({
  formik,
  isUserLoading,
  setImage,
  image,
  setFiles,
  filess,
  setMyidCountries,
}) => {
  const [languages, setLanguages] = useState([])
  const [idCountries, setIdCountries] = useState<any>('null')
  const [idState, setIdState] = useState<any>('null')
  const [countries, setCountries] = useState<any>([])
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)
  const [companies, setCompanies] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const callApi = UseCallApi()

  const getLanguages = async () => {
    try {
      const data = await callApi({
        route: `languages.json`,
        method: 'GET',
      })
      const modifiedData = data.map((item) => ({
        ...item,
        label: item.lang,
        value: item.id,
      }))
      setLanguages(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getCompanies = async () => {
    try {
      const data = await callApi({
        route: `companies.json`,
        method: 'GET',
      })
      const modifiedData = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      setCompanies(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getStates = async (id) => {
    try {
      setIsLoadingStates(true) // Set loading state to true
      const data = await callApi({
        route: `countries/${id}`,
        method: 'GET',
      })

      const modifiedData = data.states.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      // setCities([])
      setStates(modifiedData)
    } catch (error) {
      console.error('Error fetching state data:', error)
    } finally {
      setIsLoadingStates(false) // Set loading state back to false
    }
  }

  const getCities = async (id) => {
    try {
      setIsLoadingCities(true) // Set loading state to true
      const data = await callApi({
        route: `states/${id}`,
        method: 'GET',
      })

      const modifiedData = data.cities.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))

      setCities(modifiedData)
    } catch (error) {
      console.error('Error fetching city data:', error)
    } finally {
      setIsLoadingCities(false) // Set loading state back to false
    }
  }

  useEffect(() => {
    getCompanies()
    getCountries(setCountries)
    getLanguages()
  }, [])
  useEffect(() => {
    if (formik.getFieldProps('country').value) {
      getStates(formik.getFieldProps('country').value)
    } else {
      setStates([])
    }
  }, [idCountries, formik.getFieldProps('country').value])
  useEffect(() => {
    if (formik.getFieldProps('state').value != 'null') {
      getCities(formik.getFieldProps('state').value)
    } else {
      setCities([])
    }
  }, [idState, formik.getFieldProps('state').value])
 
  return (
    <div>
      <div className='row mb-7 d-flex'>
        <div className=' col col-2'>
          {' '}
          <label htmlFor='exampleInputEmail1' className='fw-bold'>
            Photo :
          </label>
          <div className='form-group mb-5  '>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
            >
              <div
                id='image'
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: filess == '' ? "url('')" : 'url(' + filess + ')',
                }}
              ></div>
              <label
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                      setFiles(URL.createObjectURL(e.target.files[0]))
                      setImage(Array.from(e.target.files))
                    }
                  }}
                  type='file'
                  name='photo'
                  accept='.png, .jpg, .jpeg'
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              <span
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
            </div>
          </div>
          {/* end::Image input */}
        </div>
        <div className=' col-sm-12 col-md-8 col-lg-10 row'>
          <div className='col'>
            <LabelInput
              label={'Nom'}
              type={'string'}
              formik={formik}
              isUserLoading={isUserLoading}
              name={'lastname'}
              placeholder='Nom'
              isRequred={true}


            />
          </div>
          <div className='col'>
            <LabelInput
              label={'Prénom'}
              type={'string'}
              formik={formik}
              isUserLoading={isUserLoading}
              name={'firstname'}
              placeholder='Prénom'
              isRequred={true}
            />
          </div>
          <div className='col'>
          <LabelInput
              label={"Code d'invitation"}
              type={'string'}
              formik={formik}
              isUserLoading={isUserLoading}
              name={'invitecode'}
              
            />
          </div>
          <div className='row mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Sexe</label>
            <div className='  d-flex '>
              <div className='me-5'>
                <div className='d-flex fv-row'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('sexe')}
                      name='sexe'
                      type='radio'
                      value='f'
                      id='f'
                      checked={formik.values.sexe === 'f'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className='form-check-label' htmlFor='f'>
                      <div className='fw-bolder text-gray-800'>Femme</div>
                    </label>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='d-flex fv-row'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('sexe')}
                      name='sexe'
                      type='radio'
                      value='h'
                      id='h'
                      checked={formik.values.sexe === 'h'}
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    <label className='form-check-label' htmlFor='h'>
                      <div className='fw-bolder text-gray-800'>Homme</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=' row mb-7'>
        <div className='col mb-4'>
          <label htmlFor='country' className='required form-label'>
            Countries
          </label>
          <SearchSelect
            options={countries}
            defaultValue={formik.values.country}
            name={'country'}
            formik={formik}
          />
        </div>
        <div className='col mb-7'>
          <label htmlFor='state' className='required form-label'>
            Province
          </label>
          <SearchSelect
            options={states}
            defaultValue={formik.values.state}
            name={'state'}
            formik={formik}
          />

          {isLoadingStates && <div>Loading Cities...</div>}
        </div>
        <div className='col'>
          <label htmlFor='city' className='required form-label'>
            Ville
          </label>
          <SearchSelect
            options={cities}
            defaultValue={formik.values.city}
            name={'city'}
            formik={formik}
          />

          {isLoadingCities && <div>Loading Cities...</div>}
        </div>
        {/* start input zip code */}
        <div className='col mb-7'>
          <LabelInput
            name={'zipCode'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            label={'Code postal'}
          />
        </div>
        {/* end input zip code */}
      </div>
      <div className='row'>
        {/* begin::Input group */}
        <div className='col mb-7'>
          <LabelInput
            label={'Téléphone'}
            type={'phone'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'phone'}
            isRequred={true}

            placeholder='Numéro de téléphone'
          />
        </div>
        <div className='col mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Email</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='example@example.com'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {/* end::Input */}
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Input group */}
        {/* start::input password */}
        <div className='col'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Mot de passe </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Mots de passe'
            {...formik.getFieldProps('password')}
            type='password'
            name='password'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='col'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Confirmer mot de passe </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='confirmer mot de passe'
            {...formik.getFieldProps('confirmPassword')}
            type='password'
            name='confirmPassword'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::input password */}
      </div>
      <div className='mb-7'>
        {/* begin::Label */}
        {/* end::Label */}
        {/* begin::Roles */}
        {/* begin::Input row */}
        <div className='row justify-content-start'>
          {/* start input adress */}
          <div className='col '>
            <label className='required fw-bold fs-6 mb-2'>Adresse </label>
            <textarea
              rows={3}
              placeholder='Adresse'
              {...formik.getFieldProps('adress')}
              name='adress'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.adress && formik.errors.adress},
                {
                  'is-valid': formik.touched.adress && !formik.errors.adress,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.adress && formik.errors.adress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.adress}</span>
                </div>
              </div>
            )}
          </div>
          {/* end input adress */}{' '}
        </div>

        {/* ---- */}
      </div>

     
    
      <div className='row'>
        <div className='col mb-7'>
          <label htmlFor='language' className='form-label required'>
            Langue
          </label>
          <SearchSelect
            options={languages}
            defaultValue={formik.values.language}
            name={'language'}
            formik={formik}
          />
        </div>
        <div className='col mb-7'>
          <label htmlFor='currency' className='form-label required'>
            Devise
          </label>
          <SearchSelect
            options={[
              {value: 'eur', label: 'EUR'},
              {value: 'mad', label: 'MAD'},
            ]}
            defaultValue={formik.values.currency}
            name={'currency'}
            formik={formik}
          />
        </div>
      </div>
    
    </div>
  )
}

export default Form1
