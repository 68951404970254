// Acordian component
import React, {ReactNode, useState} from 'react'
import clsx from 'clsx'

type FormDocType = {
  formDocuments: any
}

type Props = {
  children: ReactNode
  titre?: any
  accordionId: any
  readonly?: any
  required?: any
  // onToggle?: any
  // isOpen: boolean // Add isOpen prop
}
const Accordion: React.FC<Props> = ({
  children,
  readonly,
  titre,
  required,
  // isOpen,
  // onToggle,
  accordionId, // Add a prop to specify the unique accordion ID
}) => {
  // const [openAccordion, setOpenAccordion] = React.useState<string | null>(null)
  const [isRequired, setIsRequired] = useState(required)
  const [titreInput, setTitreInput] = React.useState(titre)

  const changeInput = (e) => {
    setTitreInput(e.target.value)
  }

  return (
    <div className='w-100 mb-3'>
      <div className='accordion' id={accordionId}>
        <div className='accordion-item'>
          <h2 className='accordion-header' id={`accordion_header_${accordionId}`}>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#carteGrisee_${accordionId}`} // Use the unique ID here
              aria-expanded='true' // Set aria-expanded to "true" for the default open state
              aria-controls={`carteGrisee_${accordionId}`} // Use the unique ID here
            >
              {readonly ? (
                <>{titre}</>
              ) : (
                <input
                  placeholder='le titre'
                  value={titreInput}
                  onChange={changeInput}
                  readOnly={readonly}
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0 w-50')}
                  type='text'
                  name='title'
                  autoComplete='off'
                />
              )}
            </button>
          </h2>
          <div
            id={`carteGrisee_${accordionId}`} // Use the unique ID here
            className='accordion-collapse collapse container p-3'
            aria-labelledby={`accordion_header_${accordionId}`} // Use the unique ID here
            data-bs-parent={`#${accordionId}`} // Use the unique ID here as data-bs-parent
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion
