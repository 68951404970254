import React from 'react'
import Select from 'react-select'
import {ThemeModeComponent} from '../../../../../_metronic/assets/ts/layout'
import clsx from 'clsx'
import {useThemeMode} from '../../../../../_metronic/partials'
import './_react-select.scss'
type Props = {
  options?: any
  defaultValue?: any
  name: string
  formik?: any
  disabled?: boolean
  isMulti?: boolean
  placeholder?: string
}

const SearchSelect: React.FC<Props> = ({
  options,
  defaultValue,
  name,
  formik,
  isMulti = false,
  disabled = false,
  placeholder = 'select',
}) => {
  const mode = ThemeModeComponent.getActiveColorMode()
  const isLight = mode === 'light'

  const optionsWithNone = [{value: 'null', label: 'None'}, ...options]

  return (
    <div className='react-select-container '>
      <Select
        value={
          isMulti
            ? options.filter((option) => defaultValue.includes(option.value))
            : options.find((option) => defaultValue === option.value)
        }
        options={optionsWithNone}
        isDisabled={disabled}
        isMulti={isMulti}
        placeholder={placeholder}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            name,
            isMulti
              ? selectedOption.map((option) => option.value)
              : selectedOption
              ? selectedOption.value
              : null
          )
        }}
        classNames={{control: (_) => (isLight ? 'light' : 'dark')}}
        classNamePrefix={`react-select-${isLight ? 'light' : 'dark'}`}
        className={clsx(
          `react-select-container`,
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        styles={{
          multiValue: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            backgroundColor:
              state.data.value === 'null' ? 'var(--bs-dark)' : baseStyles.backgroundColor,
          }),
          multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            color: 'dark',
            borderRadius: '50px',
          }),
        }}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchSelect
