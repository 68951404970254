import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DriveEditModal} from './drive-edit-modal/DriveEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
// import {MenuInner} from '../../../../../_metronic/layout/components/header/header-menus'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import {BookingListHeader} from './components/header/BookingListHeader'
import {useState} from 'react'
import {UseCallApi} from '../../../../../_metronic/CallApi'
import { BookingTable } from './table/BookingTable'
// import {HeaderWrapper} from '../../../../../_metronic/layout/components/header'
const Links = [
  {title: 'En cour', link: '/drives-management/in-progress'},
  {title: 'Terminé', link: '/drives-management/finished'},
  {title: 'Validé', link: '/drives-management/valid'},
  {title: 'Annulé', link: '/drives-management/canceled'},
]
const BookingList = () => {
  const callApi = UseCallApi()
  const [data, setData] = useState<any>([])

  const [headerFilter, setHeaderFilter] = useState({
    name: '',
    statu: '',
    countries: '',
  })
  const handleDeleteUser = async (idUser) => {
    deleteDrives(idUser)
    setData((prevData) => prevData.filter((user) => user.id !== idUser))
  }
  const deleteDrives = async (id) => {
    try {
      const data = await callApi({
        route: `trips/${id}`,
        method: 'DELETE',
      })
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <BookingListHeader
          setHeaderFilter={setHeaderFilter}
          headerFilter={headerFilter}
          handleDeleteUser={handleDeleteUser}
        />
        <BookingTable
          setData={setData}
          data={data}
          handleDeleteUser={handleDeleteUser}
          setHeaderFilter={setHeaderFilter}
        />
      </KTCard>
      {itemIdForUpdate !== undefined && <DriveEditModal />}
    </>
  )
}

const BookingListWrapper = () => (
  <QueryRequestProvider>
    <HeaderWrapper linkss={Links} />
    <QueryResponseProvider>
      <ListViewProvider>
        <BookingList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BookingListWrapper}
