import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {VehiculeTypeHeader} from './components/header/VehiculeTypeHeader'
import {VehiculeTypeTable} from './table/vehiculeTypeTable'
import {KTCard} from '../../../../../_metronic/helpers'

// import {MenuInner} from '../../../../../_metronic/layout/components/header/header-menus'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import {useContext, useState} from 'react'
import {AppContext} from '../../../../../AppContext'
import {UseCallApi} from '../../../../../_metronic/CallApi'
// import {HeaderWrapper} from '../../../../../_metronic/layout/components/header'
const Links = [
  {title: 'Liste', link: '/apps/user-management'},
  {title: 'En attente', link: '/en-attente'},
  {title: 'Disponible', link: '/disponible'},
  {title: 'Drives', link: '/drives-management'},
]
const VehiculeType = () => {
  const {deleteAlert} = useContext(AppContext)
  const [data, setData] = useState<any>([])
  const [headerFilter, setHeaderFilter] = useState<any>({
    name: '',
    statu: '',
    countries: '',
  })
  const callApi = UseCallApi()
  const handleDeleteUser = async (idUser) => {
    deleteDrivers(idUser)
    setData((prevData) => prevData.filter((user) => user.id !== idUser))
  }
  const deleteDrivers = async (id) => {
    try {
      const data = await callApi({
        route: `drivers/${id}`,
        method: 'DELETE',
      })
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <VehiculeTypeHeader
          setHeaderFilter={setHeaderFilter}
          headerFilter={headerFilter}
          handleDeleteUser={handleDeleteUser}
        />
        <VehiculeTypeTable
          headerFilter={headerFilter}
          setData={setData}
          data={data}
          handleDeleteUser={handleDeleteUser}
        />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const VehiculeTypeWrapper = () => (
  <QueryRequestProvider>
    <HeaderWrapper linkss={Links} />
    <QueryResponseProvider>
      <ListViewProvider>
        <VehiculeType />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {VehiculeTypeWrapper}
