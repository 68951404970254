import {ChangeEvent, FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import types from "../../../_metronic/assets/json/TypeAcount.json" 

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}

const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])
  const [languages, setLanguages] = useState([])
  const [countries, setCountries] = useState<any>([])
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)

  const [cities, setCities] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const [idCountries, setIdCountries] = useState<any>('null')
  const [idState, setIdState] = useState<any>('null')
  const callApi = UseCallApi()
  

  const getStates = async (id) => {
    try {
      setIsLoadingStates(true) // Set loading state to true
      const data = await callApi({
        route: `countries/${id}`,
        method: 'GET',
      })

      const modifiedData = data.states.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      setCities([])
      setStates(modifiedData)
    } catch (error) {
      console.error('Error fetching state data:', error)
    } finally {
      setIsLoadingStates(false) // Set loading state back to false
    }
  }
  const getCities = async (id) => {
    try {
      setIsLoadingCities(true) // Set loading state to true
      const data = await callApi({
        route: `states/${id}`,
        method: 'GET',
      })

      const modifiedData = data.cities.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))

      setCities(modifiedData)
    } catch (error) {
      console.error('Error fetching city data:', error)
    } finally {
      setIsLoadingCities(false) // Set loading state back to false
    }
  }
  const getLanguages = async () => {
    try {
      const data = await callApi({
        route: `languages.json`,
        method: 'GET',
      })
      setLanguages(data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getCountries(setCountries)
    getLanguages()
  }, [])
  useEffect(() => {
    if (formik.getFieldProps('country').value) {
      getStates(formik.getFieldProps('country').value)
    } else {
      setStates([])
    }
  }, [idCountries, formik.getFieldProps('country').value])
  useEffect(() => {
    if (formik.getFieldProps('state').value != '') {
      getCities(formik.getFieldProps('state').value)
    } else {
      getCities([])
    }
  }, [idState, formik.getFieldProps('state').value])
  return (
    <div>
      <div className='row mb-7 '>
        <div className=' col col-2'>
          {' '}
          {/* begin::Label */}
          <label htmlFor='exampleInputEmail1' className='fw-bold'>
            Photo :
          </label>
          <div className='form-group mb-5  '>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
            >
              <div
                id='image'
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: filess == '' ? "url('')" : 'url(' + filess + ')',
                }}
              ></div>
              <label
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // const reader = new FileReader();
                    // reader.onload=function(e){
                    //   setUrl(e.target?.result)
                    // }
                    // reader.readAsDataURL(e.target.files[0])
                    if (e.target.files && e.target.files[0]) {
                      setFiles(URL.createObjectURL(e.target.files[0]))
                      setImage(Array.from(e.target.files))
                    }
                  }}
                  type='file'
                  name='photo'
                  accept='.png, .jpg, .jpeg'
                />
                <input type='hidden' name='avatar_remove' />
              </label>

              <span
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
            </div>
          </div>
          {/* end::Image input */}
        </div>
        <div className=' col-sm-12 col-md-10 col-lg-10'>
          <div className='row mb-4'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nom </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nom'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='row'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              type='text'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          
        </div>

        {/* begin::Hint */}
        {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
        {/* end::Hint */}
      </div>
      <div className='row mb-7'>
          <label htmlFor='state' className='required form-label'>
            Type
          </label>
          <SearchSelect
            options={types }
            defaultValue={formik.values.type}
            name={'type'}
            formik={formik}
          /> 
        </div>
      <div className='row'>
        {/* begin::Input group */}

        {/* end::Input group */}
        {/* start::input password */}
        <div className='col'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Mot de passe </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Mots de passe'
            {...formik.getFieldProps('password')}
            type='password'
            name='password'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='col'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Confirmer mot de passe </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='confirmer mot de passe'
            {...formik.getFieldProps('confirmPassword')}
            type='password'
            name='confirmPassword'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::input password */}
      </div>
      <div className='mb-7'>
        {/* begin::Label */}
        {/* end::Label */}
        {/* begin::Roles */}
        {/* begin::Input row */}
        <div className='row justify-content-start'>
          {/* start input adress */}
          <div className='col '>
            <label className='required fw-bold fs-6 mb-2'>Adresse </label>
            <textarea
              rows={3}
              placeholder='Adresse'
              {...formik.getFieldProps('adress')}
              name='adress'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.adress && formik.errors.adress},
                {
                  'is-valid': formik.touched.adress && !formik.errors.adress,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.adress && formik.errors.adress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.adress}</span>
                </div>
              </div>
            )}
          </div>
          {/* end input adress */}{' '}
        </div>
        <div className='row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Téléphone</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='tel'
            {...formik.getFieldProps('tel')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.tel && formik.errors.tel},
              {
                'is-valid': formik.touched.tel && !formik.errors.tel,
              }
            )}
            type='tel'
            name='tel'
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {/* end::Input */}
          {formik.touched.tel && formik.errors.tel && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.tel}</span>
            </div>
          )}
        </div>

        {/* end::Input row */}
        {/* <div className='separator separator-dashed my-5'></div> */}
        {/* begin::Input row */}

        {/* ------- */}
        {/* end::Input row 
    <div className='separator separator-dashed my-5'></div>
    {/* begin::Input row 
    <div className='d-flex fv-row'>
      {/* begin::Radio 
      <div className='form-check form-check-custom form-check-solid'>
        {/* begin::Input 
        <input
          className='form-check-input me-3'
          {...formik.getFieldProps('role')}
          name='role'
          type='radio'
          value='Analyst'
          id='kt_modal_update_role_option_2'
          checked={formik.values.role === 'Analyst'}
          disabled={formik.isSubmitting || isUserLoading}
        />

        {/* end::Input 
        {/* begin::Label 
        <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
          <div className='fw-bolder text-gray-800'>Analyst</div>
          <div className='text-gray-600'>
            Best for people who need full access to analytics data, but don't need to update
            business settings
          </div>
        </label>
        {/* end::Label 
      </div>
      {/* end::Radio 
    </div>

    {/* end::Input row
    <div className='separator separator-dashed my-5'></div>
    {/* begin::Input row
    <div className='d-flex fv-row'>
      {/* begin::Radio
      <div className='form-check form-check-custom form-check-solid'>
        {/* begin::Input
        <input
          className='form-check-input me-3'
          {...formik.getFieldProps('role')}
          name='role'
          type='radio'
          value='Support'
          id='kt_modal_update_role_option_3'
          checked={formik.values.role === 'Support'}
          disabled={formik.isSubmitting || isUserLoading}
        />
        {/* end::Input
        {/* begin::Label
        <label className='form-check-label' htmlFor='kt_modal_update_role_option_3'>
          <div className='fw-bolder text-gray-800'>Support</div>
          <div className='text-gray-600'>
            Best for employees who regularly refund payments and respond to disputes
          </div>
        </label>
        {/* end::Label
      </div>
      {/* end::Radio
    </div>
    {/* end::Input row
    <div className='separator separator-dashed my-5'></div>
    {/* begin::Input row
    <div className='d-flex fv-row'>
      {/* begin::Radio
      <div className='form-check form-check-custom form-check-solid'>
        {/* begin::Input
        <input
          className='form-check-input me-3'
          {...formik.getFieldProps('role')}
          name='role'
          type='radio'
          id='kt_modal_update_role_option_4'
          value='Trial'
          checked={formik.values.role === 'Trial'}
          disabled={formik.isSubmitting || isUserLoading}
        />
        {/* end::Input
        {/* begin::Label
        <label className='form-check-label' htmlFor='kt_modal_update_role_option_4'>
          <div className='fw-bolder text-gray-800'>Trial</div>
          <div className='text-gray-600'>
            Best for people who need to preview content data, but don't need to make any
            updates
          </div>
        </label>
        {/* end::Label
      </div>
      {/* end::Radio
    </div>
    {/* end::Input row
    {/* end::Roles
        {/* ---- */}
      </div>
      <div className=' row mb-7'>
        <div className='col mb-4'>
          <label htmlFor='country' className='required form-label'>
            Countries
          </label>
          <SearchSelect
            options={countries}
            defaultValue={formik.values.country}
            name={'country'}
            formik={formik}
          />
         
        </div>
        <div className='col mb-7'>
          <label htmlFor='state' className='required form-label'>
            Province
          </label>
          <SearchSelect
            options={states}
            defaultValue={formik.values.state}
            name={'state'}
            formik={formik}
          />
         
          {isLoadingStates && <div>Loading Cities...</div>}
        </div>
        <div className='col'>
          <label htmlFor='city' className='required form-label'>
            Ville
          </label>
          <SearchSelect
            options={cities}
            defaultValue={formik.values.city}
            name={'city'}
            formik={formik}
          />
        
          {isLoadingCities && <div>Loading Cities...</div>}
        </div>
        {/* start input zip code */}
        <div className='col mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'> Code postal </label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder=' Code postal '
            {...formik.getFieldProps('zipCode')}
            type='number'
            name='zipCode'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
              {
                'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {formik.touched.zipCode && formik.errors.zipCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.zipCode}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end input zip code */}
      </div>

      <div className='row'>
        <div className='col mb-7'>
          <label htmlFor='language' className='form-label'>
            Langue
          </label>
          <select
            name='language'
            id='language'
            className='form-select'
            onChange={(event) => {
              formik.setFieldValue('language', event.target.value)
            }}
          >
            {languages &&
              languages.map((language: any, index: number) => (
                <option value={language.id} key={index}>
                  {language.lang}
                </option>
              ))}
          </select>
        </div>
        <div className='col mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Numéro de TV</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Numéro de TVA'
            {...formik.getFieldProps('VATNumber')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.VATNumber && formik.errors.VATNumber},
              {
                'is-valid': formik.touched.VATNumber && !formik.errors.VATNumber,
              }
            )}
            type='text'
            name='VATNumber'
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {/* end::Input */}
          {formik.touched.VATNumber && formik.errors.VATNumber && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.VATNumber}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Form1
