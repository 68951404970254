import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}

const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])
  const [countries, setCountries] = useState<any>([])
  const callApi = UseCallApi()

  useEffect(() => {
    getCountries(setCountries)
  }, [])
  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='country' className='form-label'>
          Countries
        </label>
        <SearchSelect
          options={countries}
          defaultValue={formik.values.country}
          name={'country'}
          formik={formik}
        />
      </div>
      <div className='row mb-7 '>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Nom </label>
        {/* end::Label */}
        {/* begin::Input */}
        <input
          placeholder='Nom'
          {...formik.getFieldProps('name')}
          type='text'
          name='name'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.name && formik.errors.name},
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='row'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Code</label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='Code'
          {...formik.getFieldProps('code')}
          type='text'
          name='code'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>

      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Status </label>
        {/* end::Label */}

        {/* begin::Input */}
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className={clsx(
              'form-check-input  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.status && formik.errors.status},
              {
                'is-valid': formik.touched.status && !formik.errors.status,
              }
            )}
            {...formik.getFieldProps('status')}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
            type='checkbox'
            name='status'
            id='flexSwitchDefault'
          />
        </div>
        {formik.touched.status && formik.errors.status && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.status}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
    </div>
  )
}

export default Form1
