import {lazy, FC, Suspense, useContext} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AddDriver from '../components/Driver/AddDriver'
import {UsersListWrapper} from '../modules/apps/user-management/users-list/UsersList'
import DriverAcount from '../modules/apps/user-management/accounts/AccountPage'
import DetailDrive from '../components/Drives/DetailDrive'
import {DriversListWrapper} from '../modules/apps/drives-management/drives-list/DrivesList'
import Drives from '../components/Drives/Drives'
import {CompanyListHeader} from '../modules/apps/company-management/company-list/components/header/CompanyListHeader'
import {CompanyListWrapper} from '../modules/apps/company-management/company-list/CompanyList'
import AddComany from '../components/Company/AddComany'
import CompanyPage from '../modules/apps/company-management/CompanyPage'
import ComapnyAcount from '../modules/apps/company-management/accounts/AccountPage'
import {CountryListWrapper} from '../modules/apps/country-management/country-list/CountryList'
import AddCountry from '../components/Country/AddCountry'
import AddDocument from '../components/Country/AddDocument'
import {StateListWrapper} from '../modules/apps/state-management/state-list/StateList'
import AddState from '../components/State/AddState'
import {CityListWrapper} from '../modules/apps/city-management/city-list/CityList'
import AddDrive from '../components/Drives/AddDrive'
import {CarBrandListWrapper} from '../modules/apps/Utility/car-brand/carBrand-list/CarBrandList'
import AddBrand from '../components/CarBrand/AddBrand'
import AddCity from '../components/city/AddCity'
import AddModel from '../components/CarModel/AddModel'
import {VehiculeTypeWrapper} from '../modules/apps/vehicule-type/vehiculeType-list/vehiculeTypeList'
import AddVehiculeType from '../components/vehiculeType/AddVehiculeType'
import {MailListWrapper} from '../modules/apps/seting/mail-management/mail-list/MailList'
import AddMail from '../components/mail/AddMail'
import {AppContext} from '../../AppContext'
import {AdminGroupsListWrapper} from '../modules/apps/Admin/Admin-groups/AdminGroups-list/AdminGroupsList'
import AddAdminGroups from '../components/admin-groups/AddAdminGroups'
import {AdminUsersListWrapper} from '../modules/apps/Admin/Admin-users/adminUsers-list/AdminUsersList'
import AddAdminUsers from '../components/admin-users/AddAdminUsers'
import {MapsViewListWrapper} from '../modules/apps/maps-view/maps-view-list/MapsViewList'
import AddLocation from '../components/location/AddLocation'
import {GeoFenceLocationListWrapper} from '../modules/apps/location-mangement/Geo-Fence-Location-management/Geo-Fence-Location-list/GeoFenceLocationList'
import {RestrictedAreaListWrapper} from '../modules/apps/location-mangement/Restricted-Area-management/Restricted-Area-Location-list/RestrictedAreaList'
import AddRestrictedArea from '../components/Restricted-Area/AddRestrictedArea'
import {LocationwiseFareListWrapper} from '../modules/apps/location-mangement/Locationwise-Fare-management/LocationwiseFare-list/LocationwiseFareList'
import AddLocationWiseFare from '../components/Location-Wise-Fare/AddLocationWiseFare'
import {AirportSurchargeListWrapper} from '../modules/apps/location-mangement/Airport-Surcharge-management/Airport-Surcharge-list/AirportSurchargeList'
import AddAirportSurcharge from '../components/AirportSurcharge/AddAirportSurcharge'
import {VehiculeDriverListWrapper} from '../modules/apps/Vehicule-driver/VehiculeDriver-list/VehiculeDriverList'
import AddVehiculeDriver from '../components/VehiculeDriver/AddVehiculeDriver'
import {CurrencyListWrapper} from '../modules/apps/seting/currency-management/mail-list/CurrencyList'
import {SeoListWrapper} from '../modules/apps/seting/seo-management/mail-list/SeoList'
import AddSeo from '../components/Seo/AddSeo'
import {BookingListWrapper} from '../modules/apps/booking-management/booking-list/BookingList'
import {PromoCodePageListWrapper} from '../modules/apps/Promo-Code/PromoCode-list/PromoCodePageList'
import AddPromoCode from '../components/PromoCode/AddPromoCode'
import AddManualBooking from '../components/Manual-Booking/AddManualBooking'
import {PassagersListWrapper} from '../modules/apps/passager-management/passager-list/PassagersList'
import AddPassager from '../components/Passagers/AddPassager'
import { SmsListWrapper } from '../modules/apps/seting/sms-management/sms-list/SmsList'
import AddSms from '../components/sms/AddSms'
import { TranslateListWrapper } from '../modules/apps/seting/translate-management/translate-list/TranslateList'
import AddTranslate from '../components/translates/AddTranslate'
import { HolidayPriceListWrapper } from '../modules/apps/seting/holiday-price-management/holiday-price-list/HolidayPriceList'
import AddHolidayPrice from '../components/holiday-price/AddHolidayPrice'
import { PartnerListWrapper } from '../modules/apps/seting/partner-management/Partner-list/PartnerList'
import AddPartner from '../components/partner/AddPartner'
import AddGeneral from '../components/general/AddGeneral'
import {Transform} from '../mafonction/Tranfort'
import { PagesListWrapper } from '../modules/apps/Utility/pages-management/pages-list/PagesList'
import AddPages from '../components/pages/AddPages'

import { CarModelListWrapper } from '../modules/apps/Utility/car-model/car-model-list/CarModelList'
import { VisitLocationListWrapper } from '../modules/apps/Utility/visit-location/visit-location-list/VisitLocationList'
import { EditHomeListWrapper } from '../modules/apps/Utility/edit-home-management/edit-home-list/EditHomeList'
import  {Transformt}  from '../mafonction/Tranfort2'

import { MarketPlaceListWrapper } from '../modules/apps/marketPlace-management/marketPlace-list/MarketPlaceList'
import { LaterBookingListWrapper } from '../modules/apps/later-booking-management/later-booking-list/LaterBookingList'
import { KioskRequestListWrapper } from '../modules/apps/request-mangement/kiosk-request-management/kiosk-request-list/KioskRequestList'
import { HotelRequestListWrapper } from '../modules/apps/request-mangement/hotel-request-management/hotel-request-list/HotelRequestList'
import { CorporateRequestListWrapper } from '../modules/apps/request-mangement/corporate-request-management/corporate-request-list/CorporateRequestList'
import AddCorporate from '../components/Corporate/AddCorporate'
import { ReviewListWrapper } from '../modules/apps/review-management/review-list/ReviewList'
import TranslateText from '../components/TranslateText/Translator'
import Translator from '../components/TranslateText/Translator'
import Autocomplete from '../modules/apps/Partial/Autocomplete'
import MapComponentV2 from '../modules/apps/Partial/MapComponent-v2'

const PrivateRoutes = () => {
  const {hasPermission, hasPermissionRoute} = useContext(AppContext)
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<UsersListWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {hasPermissionRoute('create_driver') ? (
          <Route
            path='add-driver/*'
            element={
              <SuspensedView>
                <AddDriver />
              </SuspensedView>
            }
          />
        ) : (
          <></>
        )}
        <Route
            path='add-company/*'
            element={
              <SuspensedView>
                <AddComany />
              </SuspensedView>
            }
          />
        {/* {hasPermissionRoute('create_company') ? (
          <Route
            path='add-company/*'
            element={
              <SuspensedView>
                <AddComany />
              </SuspensedView>
            }
          />
        ) : (
          <></>
        )} */}
        {hasPermissionRoute('edit_driver') ? (
          <Route
            path='update-driver/:id/:idusers'
            element={
              <SuspensedView>
                <AddDriver />
              </SuspensedView>
            }
          />
        ) : (
          <></>
        )}
        <Route
          path='update-company/:id'
          element={
            <SuspensedView>
              <AddComany />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='Disponible/*'
          element={
            <SuspensedView>
              <UsersListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='en-attente/*'
          element={
            <SuspensedView>
              <UsersListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='drives/*'
          element={
            <SuspensedView>
              <UsersListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='drives-management/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        {/* {hasPermissionRoute('create_trip') ? ( */}
        <Route
          path='drives-management/add'
          element={
            <SuspensedView>
              <AddDrive />
            </SuspensedView>
          }
        />
        {/* ) : (
          <></>
        )} */}

        <Route
          path='drives-management/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='company-management/*'
          element={
            <SuspensedView>
              <CompanyListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='company/:id'
          element={
            <SuspensedView>
              <ComapnyAcount />
            </SuspensedView>
          }
        />
        <Route
          path='drives-management/in-progress/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='drives-management/finished/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='drives-management/valid/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='drives-management/canceled/*'
          element={
            <SuspensedView>
              <DriversListWrapper />
            </SuspensedView>
          }
        />
        {/* <Route
          path='detail-drive/*'
          element={
            <SuspensedView>
              <DetailDrive />
            </SuspensedView>
          }
        /> */}
        <Route
          path='detail-drive/:id'
          element={
            <SuspensedView>
              <DetailDrive />
            </SuspensedView>
          }
        />
        <Route
          path='driver/:id'
          element={
            <SuspensedView>
              <DriverAcount />
            </SuspensedView>
          }
        />
        {/* //country */}
        <Route
          path='country-management/*'
          element={
            <SuspensedView>
              <CountryListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='country-management/add'
          element={
            <SuspensedView>
              <AddCountry />
            </SuspensedView>
          }
        />
        <Route
          path='country-management/add-document/:id'
          element={
            <SuspensedView>
              <AddDocument />
            </SuspensedView>
          }
        />
        <Route
          path='add-document/*'
          element={
            <SuspensedView>
              <AddDocument />
            </SuspensedView>
          }
        />
        <Route
          path='country-management/update/:id'
          element={
            <SuspensedView>
              <AddCountry />
            </SuspensedView>
          }
        />
        {/* state */}
        <Route
          path='state-management/*'
          element={
            <SuspensedView>
              <StateListWrapper />
            </SuspensedView>
          }
        />
        {/* add state */}
        <Route
          path='state-management/add'
          element={
            <SuspensedView>
              <AddState />
            </SuspensedView>
          }
        />
        {/* update state */}
        <Route
          path='state-management/update/:id'
          element={
            <SuspensedView>
              <AddState />
            </SuspensedView>
          }
        />
        {/* city */}
        <Route
          path='city-management/*'
          element={
            <SuspensedView>
              <CityListWrapper />
            </SuspensedView>
          }
        />
        {/* add city */}
        <Route
          path='city-management/add'
          element={
            <SuspensedView>
              <AddCity />
            </SuspensedView>
          }
        />
        {/* update state */}
        <Route
          path='city-management/update/:id'
          element={
            <SuspensedView>
              <AddCity />
            </SuspensedView>
          }
        />
        {/* brand */}
        <Route
          path='car-brand/*'
          element={
            <SuspensedView>
              <CarBrandListWrapper />
            </SuspensedView>
          }
        />
        {/* add brand */}
        <Route
          path='car-brand/add'
          element={
            <SuspensedView>
              <AddBrand />
            </SuspensedView>
          }
        />
        {/* add brand */}
        <Route
          path='card-brand/update/:id'
          element={
            <SuspensedView>
              <AddBrand />
            </SuspensedView>
          }
        />
        {/* model */}
        <Route
          path='car-model/*'
          element={
            <SuspensedView>
              <CarModelListWrapper />
            </SuspensedView>
          }
        />
        {/* add model */}
        <Route
          path='car-model/add'
          element={
            <SuspensedView>
              <AddModel />
            </SuspensedView>
          }
        />
        {/* update model */}
        <Route
          path='/car-model/update/:id'
          element={
            <SuspensedView>
              <AddModel />
            </SuspensedView>
          }
        />
        {/* vehicule type */}
        <Route
          path='vehicule-type-management/*'
          element={
            <SuspensedView>
              <VehiculeTypeWrapper />
            </SuspensedView>
          }
        />
        {/* vehicule type add */}
        <Route
          path='vehicule-type-management/add'
          element={
            <SuspensedView>
              <AddVehiculeType />
            </SuspensedView>
          }
        />
        {/* mail template */}
        <Route
          path='mail-template-management/*'
          element={
            <SuspensedView>
              <MailListWrapper />
            </SuspensedView>
          }
        />
        {/* add mail template */}
        <Route
          path='mail-template-management/add'
          element={
            <SuspensedView>
              <AddMail />
            </SuspensedView>
          }
        />
        {/* update mail template */}
        <Route
          path='mail-template-management/update/:id'
          element={
            <SuspensedView>
              <AddMail />
            </SuspensedView>
          }
        />
         {/* sms template */}
         <Route
          path='sms-template-management/*'
          element={
            <SuspensedView>
              <SmsListWrapper />
            </SuspensedView>
          }
        />
                {/* add sms template */}
                <Route
          path='sms-template-management/add'
          element={
            <SuspensedView>
              <AddSms />
            </SuspensedView>
          }
        />
        {/* admin groups */}
        <Route
          path='admin-groups-management/*'
          element={
            <SuspensedView>
              <AdminGroupsListWrapper />
            </SuspensedView>
          }
        />
        {/* admin groups add */}
        <Route
          path='admin-groups-management/add'
          element={
            <SuspensedView>
              <AddAdminGroups />
            </SuspensedView>
          }
        />
        {/* admin groups update */}
        <Route
          path='admin-groups-management/update/:id'
          element={
            <SuspensedView>
              <AddAdminGroups />
            </SuspensedView>
          }
        />
        {/* admin users */}
        <Route
          path='admin-users-management/*'
          element={
            <SuspensedView>
              <AdminUsersListWrapper />
            </SuspensedView>
          }
        />
        {/* admin users add */}
        <Route
          path='admin-users-management/add'
          element={
            <SuspensedView>
              <AddAdminUsers />
            </SuspensedView>
          }
        />
        {/* admin users update */}
        <Route
          path='admin-users-management/:id'
          element={
            <SuspensedView>
              <AddAdminUsers />
            </SuspensedView>
          }
        />
        {/* maps view */}
        <Route
          path='maps-view/*'
          element={
            <SuspensedView>
              <MapsViewListWrapper />
            </SuspensedView>
          }
        />
        {/* review-management */}
        <Route
          path='review-management/*'
          element={
            <SuspensedView>
              <ReviewListWrapper />
            </SuspensedView>
          }
        />
        {/* add location */}
        <Route
          path='add-location/*'
          element={
            <SuspensedView>
              <AddLocation />
            </SuspensedView>
          }
        />
        {/*  location management */}
        <Route
          path='location-management/*'
          element={
            <SuspensedView>
              <GeoFenceLocationListWrapper />
            </SuspensedView>
          }
        />
        {/*  Restricted Area */}
        <Route
          path='restricted-are-management/*'
          element={
            <SuspensedView>
              <RestrictedAreaListWrapper />
            </SuspensedView>
          }
        />
        {/* add  Restricted Area */}
        <Route
          path='restricted-are-management/add'
          element={
            <SuspensedView>
              <AddRestrictedArea />
            </SuspensedView>
          }
        />
        {/*  location fare */}
        <Route
          path='location-wise-fare-management/*'
          element={
            <SuspensedView>
              <LocationwiseFareListWrapper />
            </SuspensedView>
          }
        />
        {/*  location fare add */}
        <Route
          path='location-wise-fare-management/add'
          element={
            <SuspensedView>
              <AddLocationWiseFare />
            </SuspensedView>
          }
        />
        {/*  Airport Surcharge */}
        <Route
          path='Airport-Surcharge-management/*'
          element={
            <SuspensedView>
              <AirportSurchargeListWrapper />
            </SuspensedView>
          }
        />
        {/*  Airport Surcharge add */}
        <Route
          path='Airport-Surcharge-management/add'
          element={
            <SuspensedView>
              <AddAirportSurcharge />
            </SuspensedView>
          }
        />
        {/*  driver-vehihucle-management */}
        <Route
          path='driver-vehihucle-management/*'
          element={
            <SuspensedView>
              <VehiculeDriverListWrapper />
            </SuspensedView>
          }
        />
        {/*  driver-vehihucle-management add */}
        <Route
          path='driver-vehihucle-management/add'
          element={
            <SuspensedView>
              <AddVehiculeDriver />
            </SuspensedView>
          }
        />
        {/*  currency-management */}
        <Route
          path='currency-management/*'
          element={
            <SuspensedView>
              <CurrencyListWrapper />
            </SuspensedView>
          }
        />
        {/*  seo-management */}
        <Route
          path='seo-management/*'
          element={
            <SuspensedView>
              <SeoListWrapper />
            </SuspensedView>
          }
        />
        {/*  seo-management update */}
        <Route
          path='seo-management/update'
          element={
            <SuspensedView>
              <AddSeo />
            </SuspensedView>
          }
        />
        {/*  translate-management */}
        <Route
          path='translate-management/*'
          element={
            <SuspensedView>
              <TranslateListWrapper />
            </SuspensedView>
          }
        />
        {/* update translate-management */}
        <Route
          path='/translate-management/update'
          element={
            <SuspensedView>
              <AddTranslate />
            </SuspensedView>
          }
        />
        {/*  booking-management  */}
        <Route
          path='booking-management/*'
          element={
            <SuspensedView>
              <BookingListWrapper />
            </SuspensedView>
          }
        />
        {/*  bookingPlace-management  */}
        <Route
          path='marketPlace-management/*'
          element={
            <SuspensedView>
              <MarketPlaceListWrapper />
            </SuspensedView>
          }
        />
        {/*  later-booking-management  */}
        <Route
          path='later-booking-management/*'
          element={
            <SuspensedView>
              <LaterBookingListWrapper   />
            </SuspensedView>
          }
        />
        {/*  promo-code-management  */}
        <Route
          path='promo-code-management/*'
          element={
            <SuspensedView>
              <PromoCodePageListWrapper />
            </SuspensedView>
          }
        />
        {/*  promo-code-management/add*/}
        <Route
          path='promo-code-management/add'
          element={
            <SuspensedView>
              <AddPromoCode />
            </SuspensedView>
          }
        />
        {/*  Manual-Booking/add */}
        <Route
          path='Manual-Booking/add'
          element={
            <SuspensedView>
              <AddManualBooking />
            </SuspensedView>
          }
        />
        {/*  Holiday Price  */}
        <Route
          path='holiday-price-management/*'
          element={
            <SuspensedView>
              <HolidayPriceListWrapper />
            </SuspensedView>
          }
        />
        {/*  Holiday Price add */}
        <Route
          path='holiday-price-management/add'
          element={
            <SuspensedView>
              <AddHolidayPrice />
            </SuspensedView>
          }
        />
        {/*  Partner  */}
        <Route
          path='partner-management/*'
          element={
            <SuspensedView>
              <PartnerListWrapper />
            </SuspensedView>
          }
        />
        {/*  Partner */}
        <Route
          path='partner-management/add'
          element={
            <SuspensedView>
              <AddPartner />
            </SuspensedView>
          }
        />
        {/*  Partner  */}
        <Route
          path='general-management/*'
          element={
            <SuspensedView>
              <AddGeneral/>
            </SuspensedView>
          }
        />
        <Route
          path='passagers-management/*'
          element={
            <SuspensedView>
              <PassagersListWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='passagers-management/add'
          element={
            <SuspensedView>
              <AddPassager />
            </SuspensedView>
          }
        />
        {/* Pgaes Management */}
        <Route
          path='pages-management/*'
          element={
            <SuspensedView>
              <PagesListWrapper />
            </SuspensedView>
          }
        />
        {/* Pgaes Management update */}
        <Route
          path='pages-management/update'
          element={
            <SuspensedView>
              <AddPages />
            </SuspensedView>
          }
        />
        {/* Edit home Management  */}
        <Route
          path='edit-home-page-management/*'
          element={
            <SuspensedView>
              <EditHomeListWrapper />
            </SuspensedView>
          }
        />
        {/* Edit home Management  update */}
        <Route
          path='edit-home-page-management/update'
          element={
            <SuspensedView>
              
              {/* <AddEditHomePage /> */}
            </SuspensedView>
          }
        />
        {/* visit location Management   */}
        <Route
          path='visit-location-management/*'
          element={
            <SuspensedView>
              <VisitLocationListWrapper />
            </SuspensedView>
          }
        />
        {/* request management kiosk   */}
        <Route
          path='kiosk-request-management/*'
          element={
            <SuspensedView>
              <KioskRequestListWrapper />
            </SuspensedView>
          }
        />
        {/* request management hotel   */}
        <Route
          path='hotel-request-management/*'
          element={
            <SuspensedView>
              <HotelRequestListWrapper />
            </SuspensedView>
          }
        />
        {/* request management corporate   */}
        <Route
          path='corporate-request-management/*'
          element={
            <SuspensedView>
              <CorporateRequestListWrapper   />
            </SuspensedView>
          }
        />
        {/* request management corporate  add  */}
        <Route
          path='corporate-request-management/add'
          element={
            <SuspensedView>
              <AddCorporate   />
            </SuspensedView>
          }
        />



        {/* test routes */}
        <Route
          path='transfort'
          element={
            <SuspensedView>
              <Transform />
            </SuspensedView>
          }
        />
        <Route
          path='transfortt'
          element={
            <SuspensedView>
              <Transformt />
            </SuspensedView>
          }
        />
     
        <Route
          path='TranslateText'
          element={
            <SuspensedView>
              <Translator />
            </SuspensedView>
          }
        />
       
        <Route
          path='mapComponent'
          element={
            <SuspensedView>
              <MapComponentV2 />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
