/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {
  ChartsWidget1,
  ListsWidget5,
  TablesWidget1,
  TablesWidget5,
} from '../../../../../../_metronic/partials/widgets'
type Props = {
  company?: any
}
const Overview: React.FC<Props> = ({company}) => {
  return (
    <>
      <div className=''>
        <div className=' card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Details</h3>
            </div>

            <Link
              to={`/update-company/${company?.id}`}
              className='btn btn-primary align-self-center'
            >
              Modifier
            </Link>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7 '>
              <label className='col-lg-4 fw-bold text-muted'>Nom & Prenom</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{company?.name}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Entreprise</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>Keenthemes</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Téléphone
                <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Phone number must be active'
                ></i>
              </label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>044 3276 454 935</span>

                <span className='badge badge-success'>Verified</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Email</label>

              <div className='col-lg-8'>
                <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  smith@kpmg.com
                </a>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Countries
                {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>Allemagne</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Ville
                {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>hamnourge</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>
                Date d'inscreption{' '}
                {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
              </label>
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>10 Nov 2022, 9:23 pm</span>
              </div>
            </div>

            {/* <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Communication</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
                </div>
              </div>

              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Yes</span>
                </div>
              </div> */}
          </div>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>
    </>
  )
}
export default Overview
