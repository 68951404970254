import React, {useEffect} from 'react'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import {useFormik} from 'formik'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {AppContext} from '../../../AppContext'
import Form2 from './Form2'
import Spinner from '../../../_metronic/partials/spinner/Spinner'
import {PageTitle} from '../../../_metronic/layout/core'
const AddDocument = () => {
  const [updateData, setUpdateData] = React.useState<any>({})
  const [isUserLoading, setIsUserLoading] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(1)
  const [isLoading, setIsloading] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const {errorToas, successToas} = React.useContext(AppContext)
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }
  const {id} = useParams()
  const callApi = UseCallApi()
  const nameValidation = /^[A-Za-z\s]+$/
  const editUserSchema = Yup.object().shape({
    code: Yup.string().required('Le code est requis'),
    name: Yup.string()
      .matches(nameValidation, 'Le nom ne peut contenir que des lettres')
      .required('Le nom est requis'),

    phoneCode: Yup.string().required('Le code de téléphone est requis'),

    unit: Yup.string().required("L'unite est requise"),
    tax: Yup.string().required('le numéro de TVA  est requise'),
    commissionTax: Yup.string().required('Le code postal est requis'),
    taxInclusive: Yup.string().required('Le code postal est requis'),
    pay: Yup.string().required('Le code postal est requis'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: updateData.code ? updateData.code : '',
      pay: updateData.pay ? updateData.pay.id : '',
      name: updateData.pay ? updateData.pay : '',
      phoneCode: updateData.phoneCode ? updateData.phoneCode : '',
      unit: updateData.unit ? updateData.unit : '',
      commissionTax: updateData.commissionTax ? updateData.commissionTax : '',
      taxInclusive: updateData.taxInclusive ? updateData.taxInclusive : '',
      status: updateData.status ? updateData.status : false,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //console.log(values)

      setSubmitting(true)
      const postData = {
        commissionTax: String(values.commissionTax),
        taxInclusive: String(values.taxInclusive),
        unit: values.unit,
        dispo: false,
        code: values.code,
        pay: values.name,
        phoneCode: values.phoneCode,
        status: values.status,
      }

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postDriver = async () => {
          try {
            const data = await callApi({
              route:
                location.pathname == '/country-management/add'
                  ? 'countries'
                  : `countries/${formik.values.pay}`,
              method: 'POST',
              body: postData,
            })
            if (data) {
              successToas('success')
              navigate('/country-management')
            }
          } catch (error: any) {
            errorToas(error.response['data'].detail)
          }
        }
        postDriver()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  const [formDoc, setFormDoc] = React.useState<any>([
    {
      model: 'company',
      countries: `countries/`,
      formDocuments: [],
    },
    {
      model: 'chauffeur',
      countries: `countries/`,
      formDocuments: [],
    },
    {
      model: 'passager',
      countries: `countries/`,
      formDocuments: [],
    },
  ])
  // const loog=()=>{
  //   //console.log("tetstst");

  // }
  // loog()

  //   useEffect(()=>{
  //     setFormDoc(prevFormDoc => {
  //       const updatedFormDoc = prevFormDoc.map(item => {
  //         return {
  //           ...item,
  //           countries: `countries/${formik.values.pay}`,
  //         };
  //       });
  //       return updatedFormDoc;
  //     });
  // },[formik.values.pay])

  //console.log(formDoc[2])

  const clicksubmit = async () => {
    setIsloading(true)

    try {
      for (let index = 0; index < formDoc.length; index++) {
        //console.log(formDoc[index]?.id)
        if (formDoc[index].id &&formDoc[index].id > 0) {
          await senTdocument(formDoc[index], 'PUT', `form_model_documents/${formDoc[index].id}`)
        } else {
          await senTdocument(formDoc[index], 'POST', `form_model_documents`)
        }
      }
      successToas('Success')
    } catch (error) {
      //console.log(error)
      // If there's an error in the loop, show an error toast
    } finally {
      setIsloading(false)
    }
  }

  const senTdocument = async (element, method, url) => {
    try {
      await callApi({
        route: url,
        method: method,
        body: element,
        formData: false,
      })
    } catch (error: any) {
      errorToas(error?.response['data']?.detail)
    }
  }

  return (
    <>
      <HeaderWrapper linkss={Links} />
      <PageTitle>Gérer les documents</PageTitle>
      <Form2
        formik={formik}
        formDoc={formDoc}
        setFormDoc={setFormDoc}
        isUserLoading={isUserLoading}
        id={formik.values.pay}
        senTdocument={senTdocument}
      />
      {/* Step navigation */}
      <div className='text-center pt-15 mb-7 d-flex justify-content-center text-white'>
        {isLoading ? (
          <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
            <Spinner></Spinner>
          </button>
        ) : (
          <button type='button' className='btn btn-primary' onClick={clicksubmit}>
            Sauvegarder
          </button>
        )}

        {/* {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleNextStep}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Submit
          </button>
        )}
        {currentStep === 2 && (
          <button
            type='submit'
            className='btn btn-primary'
            onClick={(e: any) => formik.handleSubmit(e)}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Submit
          </button>
        )} */}
      </div>
    </>
  )
}

export default AddDocument
