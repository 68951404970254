import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelInput from '../../../_metronic/partials/LabelInput'
import {Link} from 'react-router-dom'
import LabelRadio from '../../../_metronic/partials/labelRadio'
import LabelSwitch from '../../../_metronic/partials/LabelSwitch'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}

const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])
  const handleLocationSelect = (location: google.maps.LatLngLiteral) => {
    // Handle the selected location here
    //console.log('Selected Location:', location)
  }
  const [countries, setCountries] = useState<any>([])
  const [origin, setOrigin] = useState({lat: 48.864716, lng: 2.349014})
  const [states, setState] = useState<any>([
    {value: 'Sélectionnez un emplacement pour', label: 'Sélectionnez un emplacement pour'},
    {value: 'Zone réglementée', label: 'Zone réglementée'},
    {value: 'Type de véhicule', label: 'Type de véhicule'},
    {
      value: "Tarif judicieux en matière d'emplacement",
      label: "Tarif judicieux en matière d'emplacement",
    },
    {value: 'Supplément aéroportuaire', label: 'Supplément aéroportuaire'},
    {value: 'Délai de mise en œuvre', label: 'Délai de mise en œuvre'},
  ])
  const selectLocation = [
    {
      value: 'all',
      label: 'all',
    },
    {
      value: 'BordeauxFrance',
      label: 'Bordeaux - France',
    },
    {
      value: 'emiratsArabesUnis',
      label: 'Emirats Arabes Unis',
    },
    {
      value: 'france',
      label: 'France',
    },
    {
      value: 'ileMaurice',
      label: 'ile-Maurice',
    },
    {
      value: 'inde',
      label: 'Inde',
    },
    {
      value: 'LyonFrance',
      label: 'Lyon - France',
    },
  ]
  const callApi = UseCallApi()

  useEffect(() => {
    getCountries(setCountries)
  }, [])

  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='selectLocation' className='form-label required'>
          Sélectionnez un emplacement
        </label>
        <div className='row'>
          <div className='col-7'>
            <SearchSelect
              options={selectLocation}
              defaultValue={formik.values.selectLocation}
              name={'selectLocation'}
              formik={formik}
            />
          </div>
          <div className='col-3'>
            {' '}
            <Link to={'/add-location'} target='_blank' className='btn btn-primary'>
              Ajouter un nouvel emplacement
            </Link>
          </div>
        </div>
      </div>
      <div className='row mb-4 border p-3 '>
        <label htmlFor="" className='form-label required'>
        Zone restreinte
        </label>
      <div className="">
        <LabelRadio type={'radio'} label={"Tous"} val="Tous" formik={formik} name={"restreinteZone"} isUserLoading={isUserLoading} />
        <LabelRadio type={'radio'} label={"Ramasser"} val="Ramasser"  formik={formik} name={"restreinteZone"} isUserLoading={isUserLoading}/>
        <LabelRadio type={'radio'} label={"Déposer"}  val="Déposer" formik={formik} name={"restreinteZone"} isUserLoading={isUserLoading}/>
      </div>

      </div>
      <div className='row mb-4 border p-3'>
        <label htmlFor="" className='form-label required'>
        Restreindre le type 
        </label>
      <div className="row">
        <LabelRadio type={'radio'} val="Refusé"  label={"Refusé"}  formik={formik} name={"restreinteType"} isUserLoading={isUserLoading}/>
        <LabelRadio type={'radio'} val="Autorisé" label={"Autorisé"}  formik={formik} name={"restreinteType"} isUserLoading={isUserLoading}/>
      </div>

      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Date de début Heure'}
          type={'datetime-local'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'dateD'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Date de fin Heure'}
          type={'datetime-local'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'dateF'}
        />
      </div>
   <div className="row">
    <LabelSwitch type={'checkbox'} label={"Status"}  formik={formik} name={"status"} isUserLoading={isUserLoading}/>
   </div>
    </div>
  )
}

export default Form1
