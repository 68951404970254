import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'
import Form1 from './Form1'
import {PageTitle} from '../../../_metronic/layout/core'

// const nameValidation = Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Name is required')
const nameValidation = /^[A-Za-z\s]+$/
const editUserSchema = Yup.object().shape({
  name: Yup.string().required('Le nom est requis'),
})
const AddAdminGroups = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const {errorToas, successToas} = useContext(AppContext)

  // //console.log(checkboxChecked);

  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading

  const callApi = UseCallApi()

  // get driver when update
  const getrole = async (id) => {
    try {
      const data = await callApi({
        route: `roles/${id}`,
        method: 'GET',
      })
      setUpdateData(data)

      //     data.routes.map((route) => {
      //       const checked = checkboxChecked.find((item:any) => item == route.id)?true:false;
      //       if(!checked){
      // setCheckeboxChecked((oldChecked:any) => [...oldChecked, route.id]);
      //       }
      //     });
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }

  useEffect(() => {
    if (id) {
      getrole(id)
    }
  }, [id])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      routes: updateData && updateData.routes ? updateData.routes.map((value) => value.id) : [],
      name: updateData && updateData.name ? updateData.name : '',
    },

    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const routes = values.routes.map((value) => `routes/${value}`)

      const postData = {
        name: values.name,

        state: 'string',
        routes,
        createdAt: '2023-10-19T18:32:36+02:00',
      }
      //console.log(postData)

      // ...checkboxChecked.reduce((acc, obj) => {
      //   obj.elementsPapers.forEach((element) => {
      //     const key = element.name
      //     if (values[key]) {
      //       acc[element.name] = values[key]
      //     }
      //   })
      //   return acc
      // }, {}),

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postCity = async () => {
          setIsLoading(true)
          try {
            const data = await callApi({
              route:
                location.pathname === '/admin-groups-management/add'
                  ? 'roles'
                  : `roles/${id}`,
              method: location.pathname === '/admin-groups-management/add' ? 'POST' : `PUT`,
              body: postData,
            })
            if (data) {
              setIsLoading(false)
              successToas('success')
              navigate('/admin-groups-management')
            }
          } catch (error: any) {
            setIsLoading(false)
            errorToas(error.response['data'].detail)
          }
        }
        postCity()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const Links = [
    {title: 'Liste', link: '/admin-groups-management'},
    // {title: 'En attente', link: '/en-attente'},
    // {title: 'Disponible', link: '/disponible'},
    // {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <div>
        <PageTitle>
          {location.pathname === '/admin-groups-management/add'
            ? 'Ajouter'
            : ` Modifier : ${updateData && updateData.name && updateData.name}`}
        </PageTitle>
      </div>

      <Form1
        formik={formik}
        isUserLoading={isUserLoading}
        initialData={updateData.routes} // Pass the initial route data
      />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {isLoading ? (
          <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
            <Spinner />
          </button>
        ) : (
          <button
            type='submit'
            className='btn btn-primary'
            onClick={(e: any) => formik.handleSubmit(e)}
          >
            Sauvegarder
          </button>
        )}
      </div>
    </>
  )
}

export default AddAdminGroups
