// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import Info from './Info'
import SimpleInfo from '../../../../../tables/colmuns/SimpleInfo'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Nom' className='min-w-125px' />,
    id: 'firstname',
    Cell: ({...props}) => {
      const {data, row} = props
      const lastName = data[row.index]?.lastname
      const firstName = data[row.index]?.firstname

      const user = `${lastName ? ` ${lastName}` : ''}${firstName ? ` ${firstName}` : ''}`

      return <Info user={user} />
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.email} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='roles' className='min-w-125px' />
    ),
    id: 'typeAccount',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.typeAccount} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        setFilter={props.setFilter}
        id={props.data[props.row.index].id}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
