import React from 'react'
import Overview from './components/Overview'
import {AccountHeader} from './AccountHeader'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import DocumentDriver from './DocumentDriver'
import {useParams} from 'react-router-dom'
import {UseCallApi} from '../../../../../_metronic/CallApi'

const DriverAcount: React.FC = () => {
  const [driver, setDriver] = React.useState()

  const {id} = useParams()
  const getDriver = async () => {
    try {
      const data = await UseCallApi()({
        route: `drivers/${id}`,
        method: 'GET',
      })
      setDriver(data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  React.useEffect(() => {
    getDriver()
  }, [])
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <div>
      <HeaderWrapper linkss={Links} />
      <AccountHeader driver={driver} />
      <DocumentDriver driver={driver} />
      <Overview driver={driver} />
    </div>
  )
}

export default DriverAcount
