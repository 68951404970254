import React, {FC, useEffect, useState} from 'react'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {UserInfoCell} from './UserInfoCell'
import MapComponent from '../../../Partial/MapComponent'
import {UsersListSearchComponent} from '../components/header/UsersListSearchComponent'
import MapDriver from '../../../../../components/maps/MapDriver'
import clsx from 'clsx'
import {AiOutlineUser} from 'react-icons/ai'
import {UserStatu} from '../../../tables/colmuns/UserStatu'

type Props = {
  setData: any
  data: any
}

const UsersListeMap: FC<Props> = ({data, setData}) => {
  const [origin, setOrigin] = useState({
    lat: 35.866667,
    lng: 2.333333,
  })
  const [myUser,setMyUser]=useState()
  const [isActive, setIsActive] = useState()
  const users = useQueryResponseData()['hydra:member']

  // const data = useMemo(() => users, [users])
  useEffect(() => {
    if (users != undefined) {
      setData(users)
    } else {
      setData([])
    }
  }, [users])
  //console.log(data)

  const isLoading = useQueryResponseLoading()

  return (
    <KTCardBody className='py-4'>
      <div className='row'>
        <div className='col-lg-6 overflow-auto col-sm-12' style={{maxHeight: '50vh'}}>
          {users &&
            users.map((user: any, index: number) => (
              <div
                key={user.id}
                className={`border mb-4 p-2  ${user.id == isActive ? 'bg-secondary' : ''}`}
              >
                <button
                  style={{all: 'unset', cursor: 'pointer'}}
                  className='d-flex align-items-center position-relative  w-100  '
                  onClick={() => {
                    setOrigin({
                      lat: Number(user?.latitude),
                      lng: Number(user?.longtitude),
                    })
                    setIsActive(user.id)
                    setMyUser(user)
                  }}
                >
                  <div className='symbol symbol-circle symbol-50px me-3'>
                    {user && user.user && user.user.photo ? (
                      <div className='symbol-label'>
                        {/* <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' /> */}
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${user?.user?.photo}`}
                          alt={user.name}
                          className='w-100'
                        />
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          'symbol-label fs-3',
                          `bg-light-${user.initials?.state}`,
                          `text-${user.initials?.state}`
                        )}
                      >
                        <AiOutlineUser />
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column'>
                    {user.name}
                    <span>{user.email}</span>
                  </div>
                </button>
                {/* <div style={{position: 'absolute', top: '-15px', left: '-12px'}}>
                  <UserStatu statu={user.status} />
                </div> */}
              </div>
            ))}
        </div>
        <div className='col-lg-6 overflow-auto col-sm-12'>
         {users&& <MapDriver origin={origin} data={data}  user={myUser}  />}
        </div>
      </div>
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export default UsersListeMap
