import React, {FC, useState} from 'react'
import {UseCallApi} from '../../../../../_metronic/CallApi'
import {AppContext} from '../../../../../AppContext'
import Spinner from '../../../../../_metronic/partials/spinner/Spinner'

type Props = {
  checked?: boolean // Use the correct type for checked
  id?: any
  query: string // Use the correct type for query
}

export const Switch: FC<Props> = ({checked, query, id}) => {
  const [isLoading, setIsloading] = React.useState(false)
  const {successToas} = React.useContext(AppContext)
  const [isChecked, setIsChecked] = useState(checked)
  const updateStatusDrivers = async () => {
    try {
      const data = await UseCallApi()({
        route: `${query}/${id}`,
        body: {status: !isChecked}, // Use isChecked to toggle the status
        method: 'PUT',
      })
      // Assuming data contains the updated status information
      // You can update the UI here with the new status if needed
      successToas('success')
    } catch (error) {
      console.error('Error updating status:', error)
    } finally {
      setIsloading(false)
    }
  }

  const handleSwitchChange = () => {
    setIsloading(true)
    setIsChecked(!isChecked) // Toggle the isChecked state
    updateStatusDrivers() // Send a PUT request to update the status
  }

  return (
    <div>
      <div className='form-check form-check-custom form-check-solid form-switch form-switch-sm mb-2 d-flex'>
        <input
          className='form-check-input'
          type='checkbox'
          disabled={isLoading}
          name='model.app.header.default.fixed.desktop'
          checked={isChecked}
          onChange={handleSwitchChange} // Call the function when the switch changes
        />
        {isLoading ? (
          <div className='spinner-border spinner-border-sm ms-2' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
