/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, SetStateAction, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {BsFillPencilFill, BsFillTrash3Fill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import { AppContext } from '../../../../../../../../AppContext'

type Props = {
  setFilter?: any
  id: ID
  editUser?: any
  handleDeleteUser?: any
  seTfilterSatuts?: any
  updateUserStatus?: any
}
const UserActionsCell: FC<Props> = ({
  id,

  handleDeleteUser,

}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

const {deleteAlert} =useContext(AppContext)
  return (
    <div className='d-flex  flex-center '>
      <div className='me-3'>
        <Link
          to={`/admin-groups-management/update/${id}`}
          className='btn btn-icon btn-light-warning btn-sm '
        >
          <i className='bi bi-pen-fill fs-4'></i>
        </Link>
      </div>
      <div className='me-3'>
        {/* <Link to={`/product/update-product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'> */}

        <a
          className='btn btn-icon btn-light-danger btn-sm '
          data-kt-users-table-filter='delete_row'
          onClick={async () => {
            const confirmation = await deleteAlert()
            if (confirmation) {
              handleDeleteUser(id)
            }
          }}
        >
          <i className='bi bi-trash-fill fs-4'></i>
        </a>
      </div>

      {/* <div className='d-flex'>
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm d-flex align-items-center'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Satatu
          <KTIcon iconName='down' className='fs-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* Menu items 
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => updateUserStatus(id, '1')}>
              Valide
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => updateUserStatus(id, '2')}>
              En attente
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => updateUserStatus(id, '0')}>
              Bloquer
            </a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => updateUserStatus(id, '3')}>
              suspendu
            </a>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export {UserActionsCell}
