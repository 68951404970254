// Acordian component
import React, {ReactNode} from 'react'
import clsx from 'clsx'

type FormDocType = {
  formDocuments: any
}

type Props = {
  acordianIndex?: any
  onToggle?: any
  model?: any
  formDocuments?: any
  setFormDoc?: any
  formDoc?: any
  isOpen?: any
  children: ReactNode
  titre?: any
  formik?: any
  isUserLoading?: any
  isSelect?: any
  accordionId?: any
  readonly?: any
}
const Acordian: React.FC<Props> = ({
  onToggle,
  acordianIndex,
  setFormDoc,
  model,
  formDoc,
  formik,
  titre,
  children,
  isSelect,
  readonly,
  isUserLoading,
  isOpen,

  accordionId, // Add a prop to specify the unique accordion ID
}) => {
 
  const [titreInput, setTitreInput] = React.useState(titre)
  const changeInput = (event) => {
    const newTitle = event.target.value
    setTitreInput(newTitle)
    if (model >= 0 && acordianIndex >= 0 && formDoc) {
      // Find the index of the element you want to update
      const elementIndex = formDoc[model].formDocuments.findIndex(
        (Element, index) => index === acordianIndex
      )

      if (elementIndex !== -1) {
        const updatedFormDoc = [...formDoc]
        const updatedFormDocuments = [...updatedFormDoc[model].formDocuments]
        updatedFormDocuments[elementIndex].title = newTitle
        updatedFormDoc[model].formDocuments = updatedFormDocuments
        setFormDoc(updatedFormDoc)
      }
    }
  }
 

  return (
    <div className='w-100 mb-3'>
      <div className='accordion' id={accordionId}>
        <div className='accordion-item'>
          <h2 className='accordion-header' id={`accordion_header_${accordionId}`}>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#carteGrisee_${accordionId}`} // Use the unique ID here
              aria-expanded='true'
              aria-controls={`carteGrisee_${accordionId}`} // Use the unique ID here
            >
              <input
                placeholder='Titre'
                value={titreInput}
                onChange={changeInput}
                readOnly={readonly}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0 w-50')}
                type='text'
                name='title'
                autoComplete='off'
                disabled={formik.isSubmitting || isUserLoading}
              />
            </button>
          </h2>
          <div
            id={`carteGrisee_${accordionId}`} // Use the unique ID here
            className='accordion-collapse collapse container p-3'
            aria-labelledby={`accordion_header_${accordionId}`} // Use the unique ID here
            data-bs-parent={`#${accordionId}`} // Use the unique ID here as data-bs-parent
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Acordian
