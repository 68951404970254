import React, {useState, useRef, useContext, useEffect} from 'react'
import {GoogleMap, LoadScript, DirectionsService, DirectionsRenderer} from '@react-google-maps/api'
import Spinner from '../../../../_metronic/partials/spinner/Spinner'
import {AppContext} from '../../../../AppContext'

const mapContainerStyle = {
  width: '100%',
  height: '400px',
}

const center = {
  lat: 48.866667,
  lng: 2.333333,
}
type Props = {
  destination?: any
  setDestination?: any
  origin?: any
  readOnly?: any
  setOrigin?: any
  destinationLatLong?: any
  setDestinationLatLong?: any
  originLatLong?: any
  setOriginLatLong?: any
  setDuration?: any
  duration?: any
  setDistance?: any
  distance?: any
}
const MapComponent: React.FC<Props> = ({
  destination,
  readOnly,
  setDestination,
  origin,
  setOrigin,
  setDuration,
  duration,
  setDistance,
  distance,
  destinationLatLong,
  setDestinationLatLong,
  originLatLong,
  setOriginLatLong,
}) => {
  const [directions, setDirections] = useState<any | null>(null)
  const [originSuggestions, setOriginSuggestions] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [destinationSuggestions, setDestinationSuggestions] = useState<string[]>([])

  const originAutocompleteRef = useRef<HTMLInputElement>(null)
  const destinationAutocompleteRef = useRef<HTMLInputElement>(null)
  const {errorToas} = useContext(AppContext)
  //console.log({origin, destination})

  const onDirectionsLoad = (directionsResult: any) => {
    if (directionsResult) {
      setDirections(directionsResult)
      const originLocation = directionsResult.routes[0].legs[0].start_location
      const destinationLocation = directionsResult.routes[0].legs[0].end_location
      if (!readOnly) {
        setOriginLatLong({lat: originLocation.lat(), lng: originLocation.lng()})
        setDestinationLatLong({lat: destinationLocation.lat(), lng: destinationLocation.lng()})
      }
      const route = directionsResult.routes[0]
      const leg = route.legs[0]
      const distanceInKm = leg.distance.text
      const durationInHours = Math.floor(leg.duration.value / 3600)
      const durationInMinutes = Math.floor((leg.duration.value % 3600) / 60)

      setDistance(distanceInKm)
      setDuration(`${durationInHours} hours ${durationInMinutes} minutes`)
    }
  }

  const calculateDirections = () => {
    if (origin && destination) {
      setIsLoading(true)
      const directionsService = new window.google.maps.DirectionsService()
      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === 'OK') {
            onDirectionsLoad(result)
          } else {
            // errorToas(status)
            console.error(`Directions request failed with status: ${status}`)
          }
          setIsLoading(false)
        }
      )
    }
  }
  useEffect(() => {
    calculateDirections()
  }, [origin, destination])
  const handleOriginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setOrigin(value)
    getSuggestions(value, setOriginSuggestions)
  }

  const handleDestinationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setDestination(value)
    getSuggestions(value, setDestinationSuggestions)
  }

  const onOriginSelect = (suggestion: string) => {
    setOrigin(suggestion)
    setOriginSuggestions([])
    // Set the value of the origin input field
    if (originAutocompleteRef.current) {
      originAutocompleteRef.current.value = suggestion
    }
  }

  const onDestinationSelect = (suggestion: string) => {
    setDestination(suggestion)
    setDestinationSuggestions([])
    // Set the value of the destination input field
    if (destinationAutocompleteRef.current) {
      destinationAutocompleteRef.current.value = suggestion
    }
  }

  const getSuggestions = (query: string, setSuggestions: (suggestions: string[]) => void) => {
    if (query.trim() === '') {
      setSuggestions([]);
      return;
    }
  
    if (!window.google || !window.google.maps) {
      console.error('Google Maps library not loaded.');
      setSuggestions([]);
      return;
    }
  
    const autocompleteService = new
   
  window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input: query }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (predictions) {
          const suggestions = predictions.map((prediction) => prediction.description);
          setSuggestions(suggestions);
        } else {
          console.error('No predictions available.');
          setSuggestions([]);
        }
      } else {
        console.error('Autocomplete failed with status: ' + status);
        setSuggestions([]);
      }
    });
  };

  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_KEY_GOOGLE_MAP}`}>
      <div>
        <div className={`d-flex mb-3 align-items-start ${readOnly == true ? 'd-none' : ''}`}>
          <div className='me-3'>
            <input
              ref={originAutocompleteRef}
              type='text'
              className='form-control'
              placeholder='Origin'
              value={origin}
              onChange={handleOriginChange}
            />
            {originSuggestions.length > 0 && (
              <ul className='list-group'>
                {originSuggestions.map((suggestion, index) => (
                  <li
                    style={{cursor: 'pointer'}}
                    key={index}
                    onClick={() => onOriginSelect(suggestion)}
                    className='list-group-item'
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className='me-3'>
            <input
              ref={destinationAutocompleteRef}
              type='text'
              placeholder='Destination'
              className='form-control'
              value={destination}
              onChange={handleDestinationChange}
            />
            {destinationSuggestions.length > 0 && (
              <ul className='list-group'>
                {destinationSuggestions.map((suggestion, index) => (
                  <li
                    style={{cursor: 'pointer'}}
                    key={index}
                    onClick={() => onDestinationSelect(suggestion)}
                    className='list-group-item'
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* // disabled button for test handl change */}
          <div className='d-none'>
            {isLoading ? (
              <button className='btn btn-primary' disabled={isLoading}>
                <Spinner />
              </button>
            ) : (
              <button onClick={calculateDirections} className='btn btn-primary'>
                Calculate Directions
              </button>
            )}
          </div>
        </div>
        <div>
          {directions && !readOnly && (
            <div>
              <p>Distance: {distance}</p>
              <p>Duration: {duration}</p>
            </div>
          )}
        </div>
        <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={8}>
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      </div>
    </LoadScript>
  )
}

export default MapComponent
