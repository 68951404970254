import React, { useState, useRef } from 'react';

export function Transformt() {
  const [htmlInputs, setHtmlInputs] = useState(['', '']);
  const [htmlOutputs, setHtmlOutputs] = useState(['', '']);
  const transformedJsxRef = useRef<any>(null);

  const parseHtmlToReactComponents = (htmlString) => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      const form = doc.querySelector('form');

      if (!form) {
        console.error('Form element not found in HTML:', htmlString);
        return [];
      }

      const formGroups = Array.from(form.querySelectorAll('.form-group'));

      const jsxCode = formGroups.map((formGroup, index) => {
        const labelElement:any = formGroup.querySelector('label');
        const input = formGroup.querySelector('input');
        const select = formGroup.querySelector('select');
        const textarea = formGroup.querySelector('textarea');
        const checkbox = formGroup.querySelector('input[type="checkbox"]');

        if (!labelElement || (!input && !select && !textarea && !checkbox)) {
          console.error('Missing label, input, select, textarea, or checkbox in form group:', formGroup);
          return null;
        }

        const label = labelElement.textContent.trim();

        if (input) {
          const inputType = input.getAttribute('type');
          const inputName = input.getAttribute('name');
          const inputValue = input.getAttribute('value');

          return `
            <div className='row mb-4'>
              <LabelInput label={'${label}'} type={'${inputType}'} name={'${inputName}'} formik={formik} isUserLoading={isUserLoading} />
            </div>
          `;
        } else if (select) {
          // ... (rest of the code remains unchanged)
        } else if (textarea) {
          // ... (rest of the code remains unchanged)
        } else if (checkbox) {
          // ... (rest of the code remains unchanged)
        }

        return null;
      });

      return jsxCode;
    } catch (error) {
      console.error('Error parsing HTML:', error);
      return [];
    }
  };

  const handleTransformClick = () => {
    const transformedJsxCodes = htmlInputs.map(parseHtmlToReactComponents);
    const transformedJsxStrings = transformedJsxCodes.map((code) => code.join('\n'));
    setHtmlOutputs(transformedJsxStrings);
  };

  const handleCopyClick = () => {
    if (transformedJsxRef.current) {
      transformedJsxRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div className='mb-4'>
      {htmlInputs.map((htmlInput, index) => (
        <div key={index} className='w-100'>
          <label htmlFor={`htmlInput${index + 1}`} className='form-label'>
            HTML Input {index + 1}
          </label>
          <textarea
            className='w-100 form-control'
            name={`htmlInput${index + 1}`}
            id={`htmlInput${index + 1}`}
            rows={5}
            value={htmlInput}
            onChange={(e) => {
              const newHtmlInputs = [...htmlInputs];
              newHtmlInputs[index] = e.target.value;
              setHtmlInputs(newHtmlInputs);
            }}
          ></textarea>
        </div>
      ))}
      <div className='mt-4'>
        <label htmlFor='htmlOutputs' className='form-label'>
          JSX Output
        </label>
        <div className='d-flex'>
          <textarea
            className='w-100 form-control'
            name='htmlOutputs'
            id='htmlOutputs'
            rows={5}
            value={htmlOutputs.join('\n\n')}
            readOnly
            ref={transformedJsxRef}
          ></textarea>
        </div>
      </div>
      <div className='mt-2'>
        <button className='btn btn-success' onClick={handleTransformClick}>
          Transform
        </button>
        <button className='btn btn-primary m-2' onClick={handleCopyClick}>
          Copy
        </button>
      </div>
    </div>
  );
}
