// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import SimpleInfo from '../../../../tables/colmuns/SimpleInfo'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Numéro de trajet ' className='min-w-125px' />
    ),
    id: 'id',
    Cell: ({...props}) => <SimpleInfo value="1" />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Nom du conducteur ' className='text-start min-w-50px' />
    ),
    id: 'driver',
    Cell: ({...props}) => <SimpleInfo value="Nom" />,

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Nom du passager ' className='text-start min-w-50px' />
    ),
    id: 'passager',
    Cell: ({...props}) => <SimpleInfo value="Nom" />,

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Taux ' className='text-start min-w-50px' />
    ),
    id: 'taux',
    Cell: ({...props}) => <SimpleInfo value="Taux" />,

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Date ' className='text-start min-w-50px' />
    ),
    id: 'date',
    Cell: ({...props}) => <SimpleInfo value="../../.." />,

  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Commentaires ' className='text-start min-w-50px' />
    ),
    id: 'message',
    Cell: ({...props}) => <SimpleInfo value="lorem" />,

  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        setFilter={props.setFilter}
        id={props.data[props.row.index]?.id}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
