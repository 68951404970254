import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const InstallationSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'Identifiant du projet Google'}
            type={'text'}
            name={'Data[GOOGLE_SENDER_ID]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé API Google Server pour la notification push'}
            type={'text'}
            name={'Data[GOOGLE_SEVER_GCM_API_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              'PHRASE DE PASSAGE pour les deux fichiers PEM : doit être la même pour les deux applications'
            }
            type={'number'}
            name={'Data[IPHONE_PEM_FILE_PASSPHRASE]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label
            htmlFor={'Data[ENABLE_DIRECTION_SOURCE_DESTINATION_DRIVER_APP]'}
            className='required form-label'
          >
            Activer la direction Google pour l'application pilote
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_DIRECTION_SOURCE_DESTINATION_DRIVER_APP]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label
            htmlFor={'Data[ENABLE_DIRECTION_SOURCE_DESTINATION_USER_APP]'}
            className='required form-label'
          >
            Activer la direction Google pour l'application utilisateur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_DIRECTION_SOURCE_DESTINATION_USER_APP]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé de serveur Google (application Passager IOS)'}
            type={'text'}
            name={'Data[GOOGLE_SERVER_IOS_PASSENGER_APP_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé d'application Google (application Passager IOS)"}
            type={'text'}
            name={'Data[GOOGLE_IOS_PASSENGER_APP_GEO_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé de serveur Google (application Driver IOS)'}
            type={'text'}
            name={'Data[GOOGLE_SERVER_IOS_DRIVER_APP_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé d'application Google (application pilote IOS)"}
            type={'text'}
            name={'Data[GOOGLE_IOS_DRIVER_APP_GEO_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'Clé de serveur Google (application Android passager)'}
            type={'text'}
            name={'Data[GOOGLE_SERVER_ANDROID_PASSENGER_APP_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé de serveur Google (application Driver Android)'}
            type={'text'}
            name={'Data[GOOGLE_SERVER_ANDROID_DRIVER_APP_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Caractère minimum requis pour la saisie semi-automatique'}
            type={'number'}
            name={'Data[MIN_CHAR_REQ_GOOGLE_AUTO_COMPLETE]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé API Google Server pour le panneau Web'}
            type={'text'}
            name={'Data[GOOGLE_SEVER_API_KEY_WEB]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Clé API de matrice de distance pour le panneau Web'}
            type={'text'}
            name={'Data[DISTANCE_MATRIX_API_KEY_WEB]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_TOLL_COST]'} className='required form-label'>
            Développeur ici - Calcul du coût des péages
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_TOLL_COST]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Développeur ici - Calcul du coût des péages - AppID'}
            type={'text'}
            name={'Data[TOLL_COST_APP_ID]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Développeur ici - Calcul du coût des péages - Code d'application"}
            type={'text'}
            name={'Data[TOLL_COST_APP_CODE]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default InstallationSeting
