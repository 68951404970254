/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import HearthCheckBox from '../../../../customs/checkBox/hearthCheckBox'
import {Link} from 'react-router-dom'
import {UserStatu} from '../../../../tables/colmuns/UserStatu'
import {BsFillBuildingsFill} from "react-icons/bs"

type Props = {
  user: User
  pr?: any
}

const UserInfoCell: FC<Props> = ({user, pr}) => {
  const [isValidImage, setIsValidImage] = useState (true);

  return (
    <div className='d-flex align-items-center position-relative'>
      {/* begin:: Avatar */}
      <div style={{position: 'absolute', top: '-12px', right: '-10px'}}>
        <HearthCheckBox query='companies' id={user.id} checked={user.favorie} />
      </div>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Link to={`/company/${user.id}`}>
          {user.photo &&isValidImage ? (
            <div className='symbol-label'>
              {/* <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' /> */}
              <img
                src={`${process.env.REACT_APP_API_URL}/${user.photo}`}
                alt={user.name}
                onError={()=>setIsValidImage(false)}
                className='w-100'
              />
            </div>
          ) : (
            <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
         
            <BsFillBuildingsFill/>
          </div>
          )}
        </Link>
      </div>
      <div className='d-flex flex-column'>
        <Link to={`/company/${user.id}`} className='text-gray-800 text-hover-primary mb-1'>
          {user.name}
        </Link>
        <span>{user.email}</span>
      </div>
      <div style={{position: 'absolute', top: '-15px', left: '-12px'}}>
        <UserStatu statu={user.status} />
      </div>
    </div>
  )
}
export {UserInfoCell}
