import React, {useEffect, useState} from 'react'
import Overview from './components/Overview'
import {AccountHeader} from './AccountHeader'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import DocumentDriver from './DocumentDriver'
import {UseCallApi} from '../../../../../_metronic/CallApi'
import {useParams} from 'react-router-dom'
import ListChauffeur from './ListChauffeur'

const ComapnyAcount: React.FC = () => {
  const [company, setCompany] = useState<any>()
  //console.log(company)

  const {id} = useParams()
  const getCompanys = async () => {
    try {
      const data = await UseCallApi()({
        route: `companies/${id}`,
        method: 'GET',
      })
      setCompany(data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getCompanys()
  }, [])
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <div>
      <HeaderWrapper linkss={Links} />
      <AccountHeader company={company} />
      <ListChauffeur data={company?.drivers} />
      <DocumentDriver />
      <Overview company={company} />
    </div>
  )
}

export default ComapnyAcount
