import React, {useState} from 'react'
import axios, {InternalAxiosRequestConfig} from 'axios'
import {config} from 'process'
import {interceptors} from '../../modules/auth'


const Translator = () => {

  const [inputText, setInputText] = useState('')
  const [translatedText, setTranslatedText] = useState('')

  const TranslateText = async (targetLanguage) => {
    const apiKey = 'AIzaSyBJcX-nbwLgOR_ATqhdzz_Q7wF9RSXXsak'
    const apiUrl = 'https://translation.googleapis.com/language/translate/v2'
    const headers = {
      'Content-Type': 'application/json',
    }
    const requestBody = {
      q: inputText,
      source: 'en',
      target: targetLanguage,
    }
    axios.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
      axios.interceptors.request.eject(interceptors['helper'])
      delete config.headers.Authorization
      return config
    })
    try {
      const response = await axios.post(apiUrl, requestBody, {
        params: {
          key: apiKey,
        },
        headers: {
          ...headers,
          Authorization: null,
        },
      })

      //console.log(response.data)
      //console.log(response);
      
      const translatedText = response.data.data.translations[0].translatedText
      setTranslatedText(translatedText)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <div>
      <h1>Google Cloud Translation API Demo</h1>
      <input
        className='form-control mb-4'
        type='text'
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button onClick={() => TranslateText('fr')} className='btn btn-primary mb-4'>
        Translate
      </button>
      <textarea value={translatedText} readOnly className='form-control' />
    </div>
  )
}

export default Translator
