import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {UseCallApi} from '../../../../../../_metronic/CallApi'
import {number} from 'yup'

const API_URL = process.env.REACT_APP_THEME_API_URL
const MY_USER = `${process.env.REACT_APP_API_URL}/drivers`
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

// const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${MY_USER}/${id}`))
//   return axios.all(requests).then(() => {})
// }
const deleteSelectedUsers = async (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map(async (id) => {
    try {
      await axios.delete(`${MY_USER}/${id}`)
      return id
    } catch (error) {
      console.error(`Error deleting user with ID ${id}:`, error)
      return null
    }
  })

  const results = await Promise.all(requests)
  const successfulDeletions = results.filter((id) => id !== null)

  //console.log(`Successfully deleted users with IDs: ${successfulDeletions.join(', ')}`)
}

const getPassagers = async (query) => {
  try {
    const data = await UseCallApi()({
      route: query,
      method: 'GET',
      jsonLd: true,
    })

    return data
  } catch (error) {
    console.error('Error fetching product data:', error)
  }
}
export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getPassagers}
