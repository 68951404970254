/* eslint-disable react/jsx-no-target-blank */
import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {BiMailSend} from 'react-icons/bi'
import {FaMapLocation, FaUsers} from 'react-icons/fa6'
import {LiaUsersCogSolid} from 'react-icons/lia'
import {FcSelfServiceKiosk} from 'react-icons/fc'

import {AppContext} from '../../../../../AppContext'
import {TbBrandBooking, TbLocation, TbSteeringWheel} from 'react-icons/tb'
import {MdCorporateFare, MdHotel, MdOutlineWatchLater, MdRateReview} from 'react-icons/md'

const SidebarMenuMain = () => {
  const {hasPermissionRoute} = useContext(AppContext)
  const intl = useIntl()

  return (
    <>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub to='#' title='Administration' icon='people' fontIcon='bi-person'>
        <SidebarMenuItem
          to='/admin-users-management'
          icon='focus'
          title='Liste administration'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/admin-groups-management'
          fontIcon='bi-person'
          icon='focus'
          title='Gestion des permissions'
        />
        {/* <SidebarMenuItemWithSub to='#' title='Localisation' icon='map' fontIcon='bi-person'>
          <SidebarMenuItem to='/country-management' icon='focus' title='Countries' fontIcon='bi-person' />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='Gestion Chauffeur'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem to='/drives-management' icon='route' title='Tripes' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/promo-code-management'
        icon='disconnect'
        title='Promo Code'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/company-management'
        icon='cheque'
        title='Company'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem icon='car' to='/driver-vehihucle-management' title='Véhicules conducteurs' />
      <SidebarMenuItem
        avatar={<TbSteeringWheel className=' fs-3' />}
        to='/vehicule-type-management'
        title='Type de véhicule'
      />
      <SidebarMenuItem
        avatar={<FaUsers className=' fs-3' />}
        to='/passagers-management'
        title='Passagers'
      />
      <SidebarMenuItem icon='shop' to='/marketPlace-management' title='MarketPlace' />
      <SidebarMenuItem
        avatar={<TbBrandBooking className=' fs-3' />}
        to='/booking-management'
        title='Booking'
      />
      <SidebarMenuItem
        avatar={<TbLocation className=' fs-3' />}
        to='/Manual-Booking/add'
        title='Manual Booking'
      />
      <SidebarMenuItem
        avatar={<MdOutlineWatchLater className=' fs-3' />}
        to='/later-booking-management'
        title='Later Booking'
      />
      <SidebarMenuItem to='/maps-view' title='Map View' avatar={<FaMapLocation />} />
      <SidebarMenuItem to='/review-management' title='Commentaires ' avatar={<MdRateReview />} />

      <SidebarMenuItemWithSub to='#' title='Utilitaire' icon='wrench' fontIcon='bi-person'>
        <SidebarMenuItemWithSub to='#' title='Localisation' icon='map' fontIcon='bi-person'>
          <SidebarMenuItem
            to='/country-management'
            icon='focus'
            title='Countries'
            fontIcon='bi-person'
          />
          <SidebarMenuItem to='/state-management' icon='focus' title='État' fontIcon='bi-person' />
          <SidebarMenuItem to='/city-management' icon='focus' title='Ville' fontIcon='bi-person' />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/pages-management'
          icon='update-file          '
          title='Pages'
          fontIcon='text-white '
        />
        {/* <SidebarMenuItem
          to='/edit-home-page-management'
          icon='update-file          '
          title="Modifier la page d'accueil"
          fontIcon='text-white '
        /> */}
        <SidebarMenuItem
          to='/car-brand'
          icon='car-2'
          title='Marque de voiture'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/car-model'
          icon='car-3'
          title='Modèle de voiture'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/visit-location-management'
          icon='geolocation'
          title="Visitez l'emplacement"
          fontIcon='bi-person'
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='#' title='Paramètres' icon='gear' fontIcon='bi-person'>
        <SidebarMenuItem
          to='/general-management'
          icon='gear'
          title='Général'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/add-document'
          icon='document'
          title='Gérer les documents'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/mail-template-management'
          avatar={<BiMailSend className='fs-2' />}
          title="Medèles D'e-mails"
        />
        <SidebarMenuItem to='/sms-template-management' icon='messages' title='Medèles De sms' />
        <SidebarMenuItem to='/currency-management' icon='dollar' title='Devise' />
        <SidebarMenuItem
          to='/seo-management'
          icon='information'
          title='Paramètres de référencement'
        />
        <SidebarMenuItem
          to='/translate-management'
          icon='information'
          title='Étiquette de langues'
        />
        <SidebarMenuItem
          to='/holiday-price-management'
          icon='information'
          title='Tarifs Vacances'
        />
        <SidebarMenuItem to='/partner-management' icon='information' title='Partenaires' />
        {/* <SidebarMenuItemWithSub to='#' title='Localisation' icon='map' fontIcon='bi-person'>
          <SidebarMenuItem to='/country-management' icon='focus' title='Countries' fontIcon='bi-person' />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title='Gérer les emplacements'
        icon='geolocation'
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to='/location-management'
          icon='geolocation'
          title='Emplacement de la clôture géographique'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/restricted-are-management'
          icon='pointers'
          title='Zone réglementée'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/location-wise-fare-management'
          icon='pointers'
          title='tarif géolocalisé'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/Airport-Surcharge-management'
          icon='pointers'
          title='Supplément aéroportuaire'
          fontIcon='bi-person'
        />

        {/* <SidebarMenuItemWithSub to='#' title='Localisation' icon='map' fontIcon='bi-person'>
          <SidebarMenuItem to='/country-management' icon='focus' title='Countries' fontIcon='bi-person' />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='#'
        title='Gérer les demandes'
        avatar={<LiaUsersCogSolid className='fs-3' />}
        fontIcon='bi-person'
      >
        <SidebarMenuItem
          to='/kiosk-request-management'
          avatar={<FcSelfServiceKiosk className='fs-3 ' />}
          title='kiosk-request-management'
          fontIcon='bi-person'
        />

        <SidebarMenuItem
          to='/hotel-request-management'
          avatar={<MdHotel className='fs-3 ' />}
          title='hotel-request-management'
          fontIcon='bi-person'
        />
        <SidebarMenuItem
          to='/corporate-request-management'
          avatar={<MdCorporateFare className='fs-3 ' />}
          title='corporate-request-management'
          fontIcon='bi-person'
        />

        {/* <SidebarMenuItemWithSub to='#' title='Localisation' icon='map' fontIcon='bi-person'>
          <SidebarMenuItem to='/country-management' icon='focus' title='Countries' fontIcon='bi-person' />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
