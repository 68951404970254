import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const AppSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={
              "Intervalle de temps d'acceptation de la réservation pour l'état en attente (en minutes)"
            }
            type={'number'}
            name={'Data[PROVIDER_BOOKING_ACCEPT_TIME_INTERVAL]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé secrète de l'application Sinch"}
            type={'text'}
            name={'Data[SINCH_APP_SECRET_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Hôte de l’environnement d’application Sinch'}
            type={'text'}
            name={'Data[SINCH_APP_ENVIRONMENT_HOST]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"La réservation du kiosque confirme le temps d'affichage du message en secondes"}
            type={'number'}
            name={'Data[KIOSK_BOOKING_CONFIRM_TIME_IN_SECONDS]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[RIDE_DRIVER_CALLING_METHOD]'} className='required form-label'>
            Méthode d'appel
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Normale', value: 'Normal'},
              {label: 'VoIP', value: 'Voip'},
            ]}
            name={'Data[RIDE_DRIVER_CALLING_METHOD]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé d'application Sinch"}
            type={'text'}
            name={'Data[SINCH_APP_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[RIDE_LATER_BOOKING_ENABLED]'} className='required form-label'>
            Activer la réservation de trajet plus tard
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[RIDE_LATER_BOOKING_ENABLED]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_EDIT_DRIVER_VEHICLE]'} className='required form-label'>
            Autoriser le fournisseur à modifier les détails du véhicule ?
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_EDIT_DRIVER_VEHICLE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Réservation plus tard, accepter intervalle de temps après heure (en minutes)'}
            type={'number'}
            name={'Data[BOOKING_LATER_ACCEPT_AFTER_INTERVAL]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Délai de renvoi du code de vérification en secondes'}
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_TIME_IN_SECONDS]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[APPLY_SURGE_ON_FLAT_FARE]'} className='required form-label'>
            Vous souhaitez appliquer des frais supplémentaires sur un tarif forfaitaire ?
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[APPLY_SURGE_ON_FLAT_FARE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Nombre de renvois de code de vérification'}
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_COUNT]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Restriction du nombre de renvois du code de vérification en quelques minutes'}
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_COUNT_RESTRICTION]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Restriction du nombre de renvois du code de vérification en quelques minutes pour les contacts d'urgence"
            }
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_COUNT_RESTRICTION_EMERGENCY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Nombre de renvois de code de vérification pour les contacts d'urgence"}
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_COUNT_EMERGENCY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Délai de renvoi du code de vérification en secondes pour les contacts d'urgence"
            }
            type={'number'}
            name={'Data[VERIFICATION_CODE_RESEND_TIME_IN_SECONDS_EMERGENCY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[POOL_ENABLE]'} className='required form-label'>
            Activer le pool
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[POOL_ENABLE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Rayon kilométrique autour de l'utilisateur pour afficher les fournisseurs en ligne"
            }
            type={'number'}
            name={'Data[RESTRICTION_KM_NEAREST_TAXI]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_CORPORATE_PROFILE]'} className='required form-label'>
            Activer le profil d'entreprise
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_CORPORATE_PROFILE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Rayon kilométrique autour de l'utilisateur pour afficher les fournisseurs en ligne pour la piscine"
            }
            type={'number'}
            name={'Data[RESTRICTION_KM_NEAREST_TAXI_POOL]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Identifiant de l'application du taux de mise à jour des devises"}
            type={'text'}
            name={'Data[EXCHANGE_CURRENCY_RATES_APP_ID]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Numéro de licence de chat en direct'}
            type={'text'}
            name={'Data[LIVE_CHAT_LICENCE_NUMBER]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Afficher le nombre de contacts sélectionnés En choisissant le contact'}
            type={'number'}
            name={'Data[BOOK_FOR_ELSE_SHOW_NO_CONTACT]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Côté utilisateur/fournisseur : intervalle de temps de mise à jour de l'emplacement de destination en minutes"
            }
            type={'number'}
            name={'Data[DESTINATION_UPDATE_TIME_INTERVAL]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Nom du fichier PEM (pour le mode développement)'}
            type={'text'}
            name={'Data[COMPANY_APP_IPHONE_PEM_FILE_NAME]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Nom du fichier PEM de l'application kiosque pour la notification push IOS - développement"
            }
            type={'text'}
            name={'Data[KISOK_APP_IPHONE_PEM_FILE_NAME]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_TIP_MODULE]'} className='required form-label'>
            Activer la fonctionnalité de pourboire
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_TIP_MODULE]'}
            formik={formik}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={
              "Nom du fichier PEM de l'application Kiosk pour la notification push IOS - production"
            }
            type={'text'}
            name={'Data[PRO_KISOK_APP_IPHONE_PEM_FILE_NAME]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Nom du fichier PEM (pour le mode production)'}
            type={'text'}
            name={'Data[PRO_COMPANY_APP_IPHONE_PEM_FILE_NAME]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Temps approximatif nécessaire au fournisseur pour atteindre l'utilisateur par km (en minute/s)"
            }
            type={'number'}
            name={'Data[DRIVER_ARRIVED_MIN_TIME_PER_MINUTE]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              'Fournisseur en ligne Limite de liste par distance - Plage de villes (valeur entière)'
            }
            type={'number'}
            name={'Data[LIST_DRIVER_LIMIT_BY_DISTANCE]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[DRIVER_REQUEST_METHOD]'} className='required form-label'>
            Algorithme de demande du fournisseur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Tous (ALGORITHME CONCURRENTIEL)', value: 'All'},
              {label: 'Distance (1er algorithme le plus proche)', value: 'Distance'},
              {label: 'Temps (algorithme FIFO)', value: 'Time'},
            ]}
            name={'Data[DRIVER_REQUEST_METHOD]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Expiration du délai du fournisseur'}
            type={'number'}
            name={'Data[RIDER_REQUEST_ACCEPT_TIME]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_SURGE_CHARGE_RENTAL]'} className='required form-label'>
            Activer les frais supplémentaires sur la location
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_SURGE_CHARGE_RENTAL]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_WAITING_CHARGE_RENTAL]'} className='required form-label'>
            Activer les frais d'attente sur la location
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_WAITING_CHARGE_RENTAL]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_WAITING_CHARGE_FLAT_TRIP]'} className='required form-label'>
            Activer les frais d'attente sur le tarif forfaitaire
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_WAITING_CHARGE_FLAT_TRIP]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[HANDICAP_ACCESSIBILITY_OPTION]'} className='required form-label'>
            Activer l'option d'accessibilité HandiCap
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[HANDICAP_ACCESSIBILITY_OPTION]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[FEMALE_RIDE_REQ_ENABLE]'} className='required form-label'>
            Activer l'option de demande/réception de trajet basée sur le sexe (uniquement pour les
            femmes)
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[FEMALE_RIDE_REQ_ENABLE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Rayon kilométrique autour de l'utilisateur pour afficher les fournisseurs en ligne Destination de la piscine (en kilomètres)"
            }
            type={'text'}
            name={'Data[RESTRICTION_KM_NEAREST_DESTINATION_POOL]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_HAIL_RIDES]'} className='required form-label'>
            Option de grêle de taxi
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_HAIL_RIDES]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_LIVE_CHAT]'} className='required form-label'>
            Activer le chat en direct
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_LIVE_CHAT]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[CHILD_SEAT_ACCESSIBILITY_OPTION]'} className='required form-label'>
            Activer l'option d'accessibilité du siège enfant
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[CHILD_SEAT_ACCESSIBILITY_OPTION]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[BOOK_FOR_ELSE_ENABLE]'} className='required form-label'>
            Livre pour quelqu'un
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[BOOK_FOR_ELSE_ENABLE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label
            htmlFor={'Data[ENABLE_RIDER_ADVERTISEMENT_BANNER]'}
            className='required form-label'
          >
            Bannière publicitaire pour cavalier
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_RIDER_ADVERTISEMENT_BANNER]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label
            htmlFor={'Data[ENABLE_DRIVER_ADVERTISEMENT_BANNER]'}
            className='required form-label'
          >
            Bannière publicitaire pour le fournisseur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_DRIVER_ADVERTISEMENT_BANNER]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Fréquence des publicités (en minutes)'}
            type={'number'}
            name={'Data[SHOW_ADVERTISE_AFTER_MINUTES]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_NEWS_SECTION]'} className='required form-label'>
            Activer la fonctionnalité Actualités
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_NEWS_SECTION]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_AIRPORT_SURCHARGE_SECTION]'} className='required form-label'>
            Supplément aéroportuaire
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_AIRPORT_SURCHARGE_SECTION]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[ENABLE_INTRANSIT_SHOPPING_SYSTEM]'} className='required form-label'>
            Système d'achats en transit
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_INTRANSIT_SHOPPING_SYSTEM]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label
            htmlFor={'Data[ENABLE_NEWSLETTERS_SUBSCRIPTION_SECTION]'}
            className='required form-label'
          >
            Inscription à la Newsletter
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[ENABLE_NEWSLETTERS_SUBSCRIPTION_SECTION]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[WAYBILL_ENABLE]'} className='required form-label'>
            Configuration de la lettre de transport
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[WAYBILL_ENABLE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Cavaliers et chauffeurs de drapeau (en heures)'}
            type={'number'}
            name={'Data[CANCEL_DECLINE_TRIPS_IN_HOURS]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={
              "Rayon kilométrique autour de l'utilisateur pour obtenir des emplois sur le marché"
            }
            type={'number'}
            name={'Data[RESTRICTION_KM_MARKET_JOBS]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default AppSeting
