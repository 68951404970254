import React, { useState, useRef } from 'react';
import { DrawingManager, GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

type Props = {
  origin?: google.maps.LatLngLiteral;
};

const MapLocation: React.FC<Props> = ({ origin }) => {
  const [drawnPolygon, setDrawnPolygon] = useState<google.maps.Polygon | null>(null);
  const [latAndLon, setLatandLon] = useState<any>([]);
  const [editable, setEditable] = useState(false);
  const [drawingActive, setDrawingActive] = useState(true);

  //console.log(latAndLon);

  const removePolygon = () => {
    if (drawnPolygon) {
      setLatandLon([]);
      drawnPolygon.setMap(null);
      setDrawnPolygon(null);
      setDrawingActive(true);
    }
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const path = polygon.getPath().getArray();
    const polygonCoordinates = path.map((latLng) => ({
      lat: latLng.lat(),
      lng: latLng.lng(),
    }));
    setDrawnPolygon(polygon);
    setLatandLon(polygonCoordinates);
    setEditable(true);
    setDrawingActive(false);
  };
const onEdit=(path:any)=>{

  const polygonCoordinates = path.map((latLng) => ({
    lat: latLng.lat(),
    lng: latLng.lng(),
  }));
//console.log(polygonCoordinates);

  
}
  const onPolygonEdit = () => {
    
    setEditable(true);
  };

  return (
    <div>
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_KEY_GOOGLE_MAP}`}
        libraries={['drawing']}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={origin}
          zoom={8}
        >
          {drawingActive && (
            <DrawingManager
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  drawingModes: ['polygon',] as any,
                },
              }}
              onPolygonComplete={onPolygonComplete}
            />
          )}
          {drawnPolygon && (
            <Polygon
              path={drawnPolygon.getPath()}
              editable={editable}
              onMouseUp={()=>onEdit(drawnPolygon.getPath().getArray())}

              onClick={onPolygonEdit}
            />
          )}
        </GoogleMap>
        <button onClick={removePolygon} className='btn btn-danger w-100'>Remove</button>
      </LoadScript>
    </div>
  );
};

export default MapLocation;
