import {FC, useState, useEffect} from 'react'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelFilePreview from '../../../_metronic/partials/LabelFilePerview'

type Props = {
  isUserLoading: boolean
  formik?: any
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div>
      <div className='row mb-7 '>
        <LabelInput
          type='string'
          formik={formik}
          label='Nom'
          placeholder='partenaire'
          name='name'
          isRequred={true}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
      <LabelFilePreview
          name='logo'
          formik={formik}
          label={'logo'}
          isUserLoading={isUserLoading}
        />
      </div>
  
  

    </div>
  )
}

export default Form1
