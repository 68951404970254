import {UseCallApi} from '../../CallApi'

export const getCountries = async (set) => {
  try {
    const data = await UseCallApi()({
      route: 'countries.json',
      method: 'GET',
    })
    const modifiedData = data.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
    }))

    set(modifiedData)
  } catch (error) {
    console.error('Error fetching product data:', error)
    set([])
  }
}
export const getDrivers = async (set) => {
  try {
    const data = await UseCallApi()({
      route: `drivers.json`,
      method: 'GET',
    })

    const modifiedData = data.map((item) => ({
      ...item,
      label: item.name,
      value: item.name
      // value: item.user.id,
    }))

    set(modifiedData)
  } catch (error) {
    console.error('Error fetching product data:', error)
  }
}
export const getCompanies = async (set) => {
  try {
    const data = await UseCallApi()({
      route: `companies.json`,
      method: 'GET',
    })

    const modifiedData = data.map((item) => ({
      ...item,
      label: item.name,
      value: item.name,
      // value: item?.user?.id,
    }))

    set(modifiedData)
  } catch (error) {
    console.error('Error fetching product data:', error)
  }
}

// Usage example:
// getCountries().then((result) => {
//   //console.log(result); // Handle the result here
// });
