import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {confirmPassword} from '../core/_requests' // Assuming you have a function for confirming the password
import LabelInput from '../../../../_metronic/partials/LabelInput'

const initialValues = {
  newPassword: '', // Add a field for the new password
}

const confirmPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 symbols for the new password')
    .max(50, 'Maximum 50 symbols for the new password')
    .required('New password is required'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
})

export function ConfirmPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: confirmPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        confirmPassword(values.newPassword)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The confirmation details are incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_confirm_password_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Confirm Password </h1>
       
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        {' '}
        <LabelInput
          label={'Password'}
          type={'password'}
          formik={formik}
          isUserLoading={loading}
          name={'password'}
        />
      </div>
      <div className='fv-row mb-8'>
        {' '}
        <LabelInput
          label={'Confirm Password'}
          type={'password'}
          formik={formik}
          isUserLoading={loading}
          name={'confirmPassword'}
        />
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Annuler
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
