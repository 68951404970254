import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import LabelInput from '../../../_metronic/partials/LabelInput'

type Props = {
  isUserLoading: boolean
  formik?: any
 
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  // const [image, setImage] = useState<File | any>([])



  return (
    <div>
       <div className='row mb-4'>
            <LabelInput
              type={'text'}
              formik={formik}
              label={'Nom de la page'}
              name={'name'}
              isUserLoading={isUserLoading}
            />
          </div>
       <div className='row mb-4'>
            <LabelInput
              type={'text'}
              formik={formik}
              label={'Nom de la pageTitre de la page              '}
              name={'title'}
              isUserLoading={isUserLoading}
            />
          </div>
       <div className='row mb-4'>
            <LabelInput
              type={'text'}
              formik={formik}
              label={'Méta-mot-clé              '}
              name={'keysword'}
              isUserLoading={isUserLoading}
            />
          </div>
       <div className='row mb-4'>
            <LabelInput
              type={'text'}
              formik={formik}
              label={'Meta Description              '}
              name={'descreption'}
              isUserLoading={isUserLoading}
            />
          </div>
    </div>
  )
}

export default Form1
