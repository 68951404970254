import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'

import QuilleModule from '../../../_metronic/partials/QuilleModule'
import LabelInput from '../../../_metronic/partials/LabelInput'

type Props = {
  isUserLoading: boolean
  formik: any
  languages: any
}

const Form1: FC<Props> = ({formik, isUserLoading, languages}) => {
  const QuillModules = {
    toolbar: [
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      [{font: []}],
      [{size: []}],
      [{color: []}, {background: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        // { List: "unordered " },
        // { List: "ordered" },
        {list: 'bullet'},
        {indent: '-1'},
        {indent: '+1'},
      ],
      ['link', 'image', 'video'],
    ],
  }

  const languageInputs = languages.map((language) => (
    <div key={language.code}>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={`${language.label} Sujet`}
          name={`${language.code}Subject`}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='state' className='form-label required'>
          {language.label} Body
        </label>
        <QuilleModule formik={formik} name={`${language.code}Body`} modules={QuillModules} />
      </div>
    </div>
  ))

  return (
    <div>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={'But'}
          name={'name'}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={'label'}
          name={'code'}
          isUserLoading={isUserLoading}
        />
      </div>
      {languageInputs}
    </div>
  )
}

export default Form1
