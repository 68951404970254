/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {
  ChartsWidget1,
  ListsWidget5,
  TablesWidget1,
  TablesWidget5,
} from '../../../../../../_metronic/partials/widgets'
import DirectMaps from '../../../customs/maps/DirectMaps'
import SimpleMaps from '../../../customs/maps/SimpleMaps'
import {FormatDate} from '../../../../../../_metronic/helpers/functions/FormatDate'
type Props = {
  driver?: any
}
const Overview: React.FC<Props> = ({driver}) => {
  const [origin, setOrigin] = React.useState<any>({lat: 1, lng: 1})

  React.useEffect(() => {
    if (driver?.latitude != undefined && driver?.longtitude != undefined) {
      setOrigin({lat: Number(`${driver?.latitude}`), lng: Number(`${driver?.longtitude}`)})
    }
  }, [driver?.latitude, driver?.longtitude])
  return (
    <>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-sm-12 col-md-6 col-lg-6  mb-5'>
          <div className=' card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Profile Details</h3>
              </div>

              <Link
                to={`/update-driver/${driver?.id}/${driver?.user.id}`}
                className='btn btn-primary align-self-center'
              >
                Modifier
              </Link>
            </div>

            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Nom & Prenom</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{driver?.name}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Entreprise</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{driver?.company?.name}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Téléphone
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Phone number must be active'
                  ></i>
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{driver?.tel}</span>

                  <span className='badge badge-success'>Verified</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>

                <div className='col-lg-8'>
                  <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                    {driver?.email}
                  </a>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Countries
                  {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
                </label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{driver?.countries?.name}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Ville
                  {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{driver?.city?.name}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Date d'inscreption{' '}
                  {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {driver && FormatDate(driver?.created_at)}
                  </span>
                </div>
              </div>

              {/* <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Communication</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
                </div>
              </div>

              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Yes</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 card p-3 mb-5'>
          {driver && driver.longtitude != undefined && driver.latitude != undefined ? (
            <SimpleMaps origin={origin} />
          ) : (
            <></>
          )}
          {/* <iframe
            className='card-body p-3'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.8996825673603!2d-1.9028514253648232!3d34.65723632574627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd787cbafd17aac1%3A0x1761774638ec2fd6!2sFCPO%3A%20Agence%20web%20et%20de%20marketing%20digital%20%C3%A0%20Oujda!5e0!3m2!1sfr!2sma!4v1691688048770!5m2!1sfr!2sma'
            height='450'
            style={{border: '0'}}
            allowFullScreen
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='Google Map'
          ></iframe> */}
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>

        <div className='col-xl-6'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>
    </>
  )
}
export default Overview
