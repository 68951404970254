import React, {FC} from 'react'
import '../../../../../_metronic/assets/css/barcss.css'

type Props = {
  statu: any
}

const UserStatu: FC<Props> = ({statu}) => {
  return (
    <>
      <h1
        className='barStatu shadow-sm '
        style={{
          borderWidth: '2px',
          borderStyle: 'solid', // Changed 'border' to 'borderStyle'
          borderColor:
            statu == 0
              ? 'red'
              : statu == 1
              ? 'green'
              : statu == 2
              ? 'orange'
              : statu == 3
              ? 'blue'
              : 'yellow',
        }}
      >
        {/* Add the ::before pseudo-element */}
        {/* <span
          className='barStatu:before'
          style={{
            content: "''",
            top: '-4px', // Adjust the positioning as needed
            left: '-4px', // Adjust the positioning as needed
            width: '8px', // Adjust the width as needed
            height: 'calc(100% + 8px)', // Adjust the height as needed
            border: 'inherit',
          }}
        /> */}
      </h1>
    </>
  )
}

export {UserStatu}
