import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../../_metronic/CallApi'
import DirectMaps from './DirectMaps'
import MapComponent from '../../../modules/apps/Partial/MapComponent'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
import {getDrivers, getCountries} from '../../../../_metronic/helpers/Requests/Requests'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import LabelSwitch from '../../../../_metronic/partials/LabelSwitch'
import LabelTextArea from '../../../../_metronic/partials/LabelTextArea'

type Props = {
  isUserLoading: boolean
  formik?: any

  destination?: any
  setDestination?: any
  origin?: any
  setOrigin?: any
  destinationLatLong?: any
  setDestinationLatLong?: any
  originLatLong?: any
  setOriginLatLong?: any
}

const Form1: FC<Props> = ({
  formik,
  isUserLoading,

  destination,
  setDestination,
  origin,
  setOrigin,
  destinationLatLong,
  setDestinationLatLong,
  originLatLong,
  setOriginLatLong,
}) => {
  // const [image, setImage] = useState<File | any>([])
  const [passagers, setPassagers] = useState<any>([])
  const [drivers, setDrivers] = useState<any>([])
  const [distance, setDistance] = useState<any>()
  const [duration, setDuration] = useState<any>()
  const [countries, setCountries] = useState<any>([])
  const [idCountries, setIdCountries] = useState<any>('null')
  const callApi = UseCallApi()
  const getPassager = async () => {
    try {
      const data = await callApi({
        route: `passagers.json`,
        method: 'GET',
      })

      const modifiedData = data.map((item) => ({
        ...item,
        label: item?.firtname + ' ' + item?.lastname,
        value: item.user.id,
      }))

      setPassagers(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  // const getDrivers = async () => {
  //   try {
  //     const data = await callApi({
  //       route: `drivers`,
  //       method: 'GET',
  //     })

  //     const modifiedData = data.map((item) => ({
  //       ...item,
  //       label: item.name,
  //       value: item.id,
  //     }))

  //     setDrivers(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching product data:', error)
  //   }
  // }
  // const getStates = async (id) => {
  //   try {
  //     const data = await callApi({
  //       route: `countries/${id}`,
  //       method: 'GET',
  //     })

  //     const modifiedData = data.states.map((item) => ({
  //       ...item,
  //       label: item.name,
  //       value: item.id,
  //     }))

  //     setState(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching product data:', error)
  //   }
  // }
  // useEffect(() => {
  //   if (formik.getFieldProps('country').value) {
  //     getStates(formik.getFieldProps('country').value)
  //   } else {
  //     setState([])
  //   }
  // }, [idCountries, formik.getFieldProps('country').value])
  useEffect(() => {
    getCountries(setCountries)
    getDrivers(setDrivers)
    getPassager()
  }, [])
  //console.log(formik.getFieldProps('oder').value)

  return (
    <div className='row border p-3'>
      <div className='row align-items-center  mb-4 align-items-stretch justify-content-between'>
        <div className='col'>
          <SearchSelect
            options={countries}
            defaultValue={formik.values.country}
            name={'country'}
            formik={formik}
          />
        </div>

        <div className='col'>
          <LabelInput
            type={'number'}
            ReadOnly={true}
            formik={formik}
            name={'indicatif'}
            isUserLoading={isUserLoading}
            placeholder={33}
          />
        </div>
        <div className='col'>
          <LabelInput
            type={'phone'}
            formik={formik}
            name={'phone'}
            isUserLoading={isUserLoading}
            placeholder={'Entrez le numéro de mobile.'}
          />
        </div>

        <div className='col'>
          <LabelInput
            type={'name'}
            formik={formik}
            name={'firstName'}
            isUserLoading={isUserLoading}
            placeholder={'Prénom'}
          />
        </div>

        <div className='col'>
          <LabelInput
            type={'name'}
            formik={formik}
            name={'LasttName'}
            isUserLoading={isUserLoading}
            placeholder={'Nom'}
          />
        </div>

        <div className='col'>
          <LabelInput
            type={'email'}
            formik={formik}
            name={'email'}
            isUserLoading={isUserLoading}
            placeholder={'E-mail'}
          />
        </div>
      </div>
      <div className='row align-items-center  mb-4 align-items-stretch justify-content-between'>
        <div className='col'>
          <LabelSwitch
            type={'checkbox'}
            label={'Pour les autres'}
            formik={formik}
            name={'oder'}
            isUserLoading={isUserLoading}
          />
        </div>
        {formik.getFieldProps('oder').value && (
          <>
            <div className='col'>
              <LabelInput
                type={'number'}
                ReadOnly={true}
                formik={formik}
                name={'indicatif'}
                isUserLoading={isUserLoading}
                placeholder={33}
              />
            </div>
            <div className='col'>
              <LabelInput
                type={'phone'}
                formik={formik}
                name={'phoneOder'}
                isUserLoading={isUserLoading}
                placeholder={'Entrez le numéro de mobile.'}
              />
            </div>
            <div className='col'>
              <LabelInput
                type={'name'}
                formik={formik}
                name={'firstNameOder'}
                isUserLoading={isUserLoading}
                placeholder={'Prénom'}
              />
            </div>
            <div className='col'>
              <LabelInput
                type={'name'}
                formik={formik}
                name={'LasttNameOder'}
                isUserLoading={isUserLoading}
                placeholder={'Nom'}
              />
            </div>
            <div className='col'>
              <LabelInput
                type={'email'}
                formik={formik}
                name={'emailOder'}
                isUserLoading={isUserLoading}
                placeholder={'E-mail'}
              />
            </div>{' '}
          </>
        )}
      </div>

      <div className='col-sm-12 col-lg-6 border p-3'>
        <div className='row mb-4'>
          <LabelSwitch
            type={'checkbox'}
            label={'Envoyer des SMS'}
            formik={formik}
            name={'sms'}
            isUserLoading={isUserLoading}
          />
        </div>
        <div className='row mb-4'>
          <div className='col-lg-6 col-sm-12'>
            <LabelInput
              type={'text'}
              formik={formik}
              name={'numberPartner'}
              label={'Numéro de réservation du partenaire'}
              isUserLoading={isUserLoading}
            />
          </div>
          <div className='col-lg-6 col-sm-12'>
            <LabelInput
              type={'text'}
              formik={formik}
              name={'namePartner'}
              label={'Nom du partenaire'}
              isUserLoading={isUserLoading}
            />
          </div>
        </div>
        <div className='row mb-4'>
          <LabelInput
            type={'datetime-local'}
            formik={formik}
            name={'date'}
            label={'Sélectionnez Date / Heure'}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor='typeVehivule' className='form-label'>
            Sélectionnez le type de véhicule
          </label>
          <SearchSelect
            options={[]}
            defaultValue={formik.values.typeVehivule}
            name={'typeVehivule'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='typeVehivule' className='form-label'>
            Sélectionnez le mode de paiement
          </label>
          <SearchSelect
            options={[
              {value: 'reçu', label: 'Paiement reçu (hors ligne)'},
              {value: 'especes', label: 'Paiement en espèces'},
            ]}
            defaultValue={formik.values.typeVehivule}
            name={'typeVehivule'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='typeVehivule' className='form-label'>
            Type de paiement{' '}
          </label>
          <SearchSelect
            options={[
              {value: 'variable', label: 'Variable'},
              {value: 'fixe', label: 'Fixé'},
            ]}
            defaultValue={formik.values.typeVehivule}
            name={'typeVehivule'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <LabelTextArea
            formik={formik}
            name={'message'}
            label={'Message au conducteur'}
            isUserLoading={isUserLoading}
            placeholder={'Message au conducteur'}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='trajet' className='form-label'>
            Attribuer un trajet à l'entreprise{' '}
          </label>
          <SearchSelect
            options={[]}
            defaultValue={formik.values.trajet}
            name={'trajet'}
            formik={formik}
          />
        </div>
        <div className='row'>
          <label className='mb-4'>Préférences</label>
          <div className='row mb-4'>
            <LabelSwitch
              type={'checkbox'}
              flex={true}
              label={'Une balade réservée aux femmes ?'}
              formik={formik}
              name={'balade'}
              isUserLoading={isUserLoading}
            />
            <LabelSwitch
              type={'checkbox'}
              flex={true}
              label={'Siège enfant disponible ?'}
              formik={formik}
              name={'enfantDisponible'}
              isUserLoading={isUserLoading}
            />
            <LabelSwitch
              type={'checkbox'}
              flex={true}
              label={'Attribuer automatiquement un pilote'}
              formik={formik}
              name={'automatiquementDriver'}
              isUserLoading={isUserLoading}
            />
          </div>
        </div>

        {/* <div className='row mb-4'>
          <label htmlFor='driver' className='form-label'>
            Chauffeurs
          </label>
          <SearchSelect
            options={drivers}
            defaultValue={formik.values.driver}
            name={'driver'}
            formik={formik}
          />
        </div> */}
      </div>
      <div className='col-sm-12 col-lg-6 border p-3'>
        <div className='row mb-4'>
          <MapComponent
            setDuration={setDuration}
            duration={duration}
            destination={destination}
            setDistance={setDistance}
            distance={distance}
            setDestination={setDestination}
            origin={origin}
            setOrigin={setOrigin}
            destinationLatLong={destinationLatLong}
            setDestinationLatLong={setDestinationLatLong}
            originLatLong={originLatLong}
            setOriginLatLong={setOriginLatLong}
          />
        </div>
        <div className='row mb-4'>
          <label className='form-label border-bottom border-1 '> Estimation des tarifs</label>
          <div className='row border-bottom border-1 border-bottom border-1 mt-5'>
            <div className='col    d-flex justify-content-between '>
              <label className='form-label'>Tarif minimum</label>
              <label className='form-label'>0€</label>
            </div>
            <div className='col   d-flex justify-content-between '>
              <label className='form-label'>Randonnée de vacances</label>
              <label className='form-label'>0€</label>
            </div>
          </div>
          <div className='row border-bottom border-1 border-bottom border-1 mt-5'>
            <div className='col    d-flex justify-content-between '>
              <label className='form-label'>Tarif de base</label>
              <label className='form-label'>0€</label>
            </div>
            <div className='col   d-flex justify-content-between '>
              <label className='form-label'>Distance ( 0 KM )</label>
              <label className='form-label'>0€</label>
            </div>
          </div>
          <div className='row border-bottom border-1 border-bottom border-1 mt-5'>
            <div className='border-bottom border-1 d-flex pb-1 d-flex justify-content-between mt-2'>
              <label className='form-label'> Temps ()</label>
              <label className='form-label '>0€</label>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end p-3 rounded '>
          <label className='form-label fs-1 me-2 '>Prix  ​total:</label>
          <label className='form-label fs-1 text-info'>0 €</label>
        </div>
        </div>
    </div>
  )
}

export default Form1
