/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import CustomSelectUsers from './CustomSelectUsers'
import {UseCallApi} from '../../../../../../../_metronic/CallApi'

type Props = {
  setHeaderFilter?: any
  headerFilter?: any
}
const UsersListSearchComponent: FC<Props> = ({setHeaderFilter, headerFilter}) => {
  const {updateState, state} = useQueryRequest()
  const [countries, setCountries] = useState([])
  const callApi = UseCallApi()
  const getCountries = async () => {
    try {
      const data = await callApi({
        route: `countries.json`,
        method: 'GET',
      })

      const modifiedData = data.map((item) => ({
        ...item,
        label: item.pay,
        value: item.id,
      }))

      setCountries(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getCountries()
  }, [])
  // const filterData = () => {
  //   updateState({
  //     filter: {status, last_login: lastLogin},
  //     ...initialQueryState,
  //   })
  // }
  const status = [
    {value: '3', label: 'Suspendu'},
    {value: '1', label: 'Valide'},
    {value: '2', label: 'En attente'},
    {value: '0', label: 'Bloqué'},
  ]

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )
  const recherChauffeur = (e: any) => {
    setSearchTerm(e.target.value)
    const newHeaderFilter = {
      ...headerFilter,
      name: e.target.value,
    }

    // Call the setHeaderFilter function with the updated headerFilter
    setHeaderFilter(newHeaderFilter)
  }
  return (
    <div className='card-title d-flex flex-wrap'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Recherche '
          value={searchTerm}
          onChange={(e) => recherChauffeur(e)}
        />
      </div>
      {/* status */}
      {/* <div className='d-flex align-items-center position-relative my-1'>
        <CustomSelectUsers
          options={status}
          placeholder={'Status'}
          setHeaderFilter={setHeaderFilter}
          filterBy={'status'} // Use property value directly
          headerFilter={headerFilter}
        />
      </div> */}
      {/* status */}
      {/* <div className='d-flex align-items-center position-relative my-1'>
        <CustomSelectUsers
          options={countries}
          placeholder={'Countries'}
          setHeaderFilter={setHeaderFilter}
          filterBy={'countries'} // Use property value directly
          headerFilter={headerFilter}
        />
      </div> */}
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
