import {FC, useState, useEffect} from 'react'
import LabelInput from '../../../_metronic/partials/LabelInput'

type Props = {
  isUserLoading: boolean
  formik?: any
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div>
      <div className='row mb-7 '>
        <LabelInput
          type='string'
          formik={formik}
          label='titre'
          placeholder='titre'
          name='name'
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
        <LabelInput
          type='datetime-local'
          formik={formik}
          label='Date de début'
          name='dateDebut'
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
        <LabelInput
          type='datetime-local'
          formik={formik}
        label='Date de fin'
          name='dateFin'
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
        <LabelInput
          type='number'
          formik={formik}
          label='Randonnée en pourcentage'
          name='randonne'
          isUserLoading={isUserLoading}
        />
      </div>
    </div>
  )
}

export default Form1
