// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Nom du Conducteur' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="Nom de l'entreprise" className='min-w-125px' />
    ),
    id: 'company',
    Cell: ({...props}) => <UserTelCell id={props.data[props.row.index]?.company?.name} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title="Nom de l'entreprise" className='min-w-125px' />
  //   ),
  //   id: 'company',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].company} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='taxieur' className='min-w-125px' />
    ),
    id: 'task',
    Cell: ({...props}) => <UserTelCell id={props.data[props.row.index].task} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title="Date d'inscription" className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Mobile' className='text-center min-w-50px' />
    ),
    accessor: 'tel',

    // id: 'Mobile',
    // Cell: ({...props}) => (
    //   <UserTelCell id={props.data[props.row.index].tel} className='min-w-125px' />
    // ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Solde du portefeuille'
        className='text-center min-w-50px'
      />
    ),
    accessor: 'walet',

    // id: 'Mobile',
    // Cell: ({...props}) => (
    //   <UserTelCell id={props.data[props.row.index].tel} className='min-w-125px' />
    // ),
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Afficher/Modifier le(s) document(s)'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'document',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Marquer
  //     comme favori'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'Marquer_comme_favori',
  //   Cell: ({...props}) => (
  //     <UserStatusCell
  //       id={props.data[props.row.index].favori}
  //       checked={props.data[props.row.index].favori}
  //     />
  //   ),
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Statu' className='text-start min-w-100px' />
  //   ),
  //   id: 'statu',
  //   Cell: ({...props}) => <UserStatu statu={props.data[props.row.index].statu} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        setFilter={props.setFilter}
        id={props.data[props.row.index]?.id}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
