import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import Form1 from './partials/Form1'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'

const editUserSchema = Yup.object().shape({
  passager: Yup.string().required('Le code est requis'),
  driver: Yup.string().required('Le nom est requis'),
  numVol: Yup.string(),
  // numVol: Yup.string().required('Le code de téléphone est requis'),
})
const AddDrive = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [image, setImage] = useState<File | any>()
  const [filess, setFiles] = useState<any>()
  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})

  const [originLatLong, setOriginLatLong] = useState<{lat: number; lng: number} | any>(null)
  const [destinationLatLong, setDestinationLatLong] = useState<{lat: number; lng: number} | any>(
    null
  )

  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  // get driver when update
  const getCity = async (id) => {
    try {
      const data = await callApi({
        route: `cities/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
      setFiles(`${process.env.REACT_APP_API_URL}/${data?.photo}`)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getCity(id)
    }
  }, [id])

  useEffect(() => {}, [])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      driver: updateData?.driver?.id,
      country: updateData?.pay?.id,
      state: updateData.state ? updateData.state.id : '',
      numVol: updateData?.numVol,
      name: updateData?.name,
      passager: updateData?.passager?.id,
      dateTrip: updateData?.dateTrip,
      status: updateData.status ? updateData.status : false,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //console.log(values)
      setSubmitting(true)
      const postData = {
        driver: `users/${values.driver}`,
        typeTrip: 'Ride',
        rider: `users/${values.passager}`,
        bookedBy: 'admin',
        numVol: values.numVol,
        destination,
        origin,
        dateTrip: values.dateTrip,
        destinationLatLong: `{"lat":${destinationLatLong.lat},"lng":${destinationLatLong.lng}}`,
        originLatlong: `{"lat":${originLatLong.lat},"lng":${originLatLong.lng}}`,
      }

      // const formdata = new FormData()
      // // formdata.append('data')
      //console.log(postData)

      try {
        const postDrive = async () => {
          try {
            const data = await callApi({
              route:
                location.pathname === '/drives-management/add' ? 'trips' : `trips/${id}`,
              method: location.pathname === '/drives-management/add' ? 'POST' : `PUT`,
              body: postData,
            })
            if (data) {
              setIsLoading(false)
              successToas('success')
              navigate('/drives-management')
            }
          } catch (error: any) {
            setIsLoading(false)
            errorToas(error.response['data'].detail)
          }
        }
        postDrive()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <Form1
        destination={destination}
        setDestination={setDestination}
        origin={origin}
        setOrigin={setOrigin}
        destinationLatLong={destinationLatLong}
        setDestinationLatLong={setDestinationLatLong}
        originLatLong={originLatLong}
        setOriginLatLong={setOriginLatLong}
        formik={formik}
        isUserLoading={isUserLoading}
        setImage={setImage}
        image={image}
        setFiles={setFiles}
        filess={filess}
      />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {isLoading ? (
          <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
            <Spinner />
          </button>
        ) : (
          <button
            type='submit'
            className='btn btn-primary'
            onClick={(e: any) => formik.handleSubmit(e)}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid}
          >
            Submit
          </button>
        )}
      </div>
    </>
  )
}

export default AddDrive
