import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Drive, DrivesQueryResponse} from './_models'
import {UseCallApi} from '../../../../../../_metronic/CallApi'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const getUsers = (query: string): Promise<DrivesQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<DrivesQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Drive | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Drive>>) => response.data)
    .then((response: Response<Drive>) => response.data)
}
const getBookingData = async (query) => {
  try {
    const data = await UseCallApi()({
      route: query,
      method: 'GET',
      jsonLd: true,
    })

    return data
  } catch (error) {
    console.error('Error fetching product data:', error)
  }
}

const createUser = (user: Drive): Promise<Drive | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<Drive>>) => response.data)
    .then((response: Response<Drive>) => response.data)
}

const updateUser = (user: Drive): Promise<Drive | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Drive>>) => response.data)
    .then((response: Response<Drive>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  getIduser(userId)
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}
const getIduser = (userId: ID) => {
  return userId
}
const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getIduser,
  getBookingData,
}
