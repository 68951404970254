import React from 'react'
import {ErrorsPage} from '../../errors/ErrorsPage'
import {Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../../../App'
import {Logout} from '../Logout'
import {useAuth} from '../core/Auth'
import {PrivateRoutes} from '../../../routing/PrivateRoutes'
import {DefaultForm} from './DefaultForm'
import DriverForm from './DriverForm'

const Registration = () => {
  const {currentUser} = useAuth()
  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/dashboard' />} />
          </>
        ) : (
          <>
            <Route path='user' element={<DefaultForm />} />
            <Route path='driver' element={<DriverForm />} />
            <Route path='*' element={<Navigate to='user' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export default Registration
