/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Drive} from '../../core/_models'
// import HearthCheckBox from '../../../../customs/checkBox/hearthCheckBox'
import {Link} from 'react-router-dom'
import {UserStatu} from '../../../../tables/colmuns/UserStatu'

type Props = {
  user: Drive
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center position-relative'>
    {/* begin:: Avatar */}
    {/* <div style={{position: 'absolute', top: '-12px', right: '-10px'}}>
      <HearthCheckBox />
    </div> */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <Link to={'/driver'}>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-${user.initials?.state}`,
              `text-${user.initials?.state}`
            )}
          >
            {user.initials?.label}
          </div>
        )}
      </Link>
    </div>
    <div className='d-flex flex-column'>
      <Link to={'/driver'} className='text-gray-800 text-hover-primary mb-1'>
        {user.name}
      </Link>
      <span>{user.email}</span>
    </div>
    <div style={{position: 'absolute', top: '-15px', left: '-12px'}}>
      <UserStatu statu={user.statu} />
    </div>
  </div>
)
export {UserInfoCell}
