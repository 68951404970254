import {type} from 'os'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {FC, useContext} from 'react'
import {AppContext} from '../../../../../../../AppContext'
import {ClassNames} from '@emotion/react'
type Props = {
  setHeaderFilter?: any
  headerFilter?: any
  handleDeleteUser: any
}
const PassagersListHeader: FC<Props> = ({setHeaderFilter, headerFilter, handleDeleteUser}) => {
  const {selected} = useListView()
  const {hasPermissionRoute} = useContext(AppContext)
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setHeaderFilter={setHeaderFilter} headerFilter={headerFilter} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 && hasPermissionRoute('delete_driver') ? (
          <UsersListGrouping handleDeleteUser={handleDeleteUser} />
        ) : hasPermissionRoute('create_driver') ? (
          <UsersListToolbar />
        ) : (
          <></>
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PassagersListHeader}
