import {ChangeEvent, FC, useState} from 'react'
import clsx from 'clsx'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}

const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])

  return (
    <div>
      <div className='row mb-7 '>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Nom </label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='Nom'
          {...formik.getFieldProps('name')}
          type='text'
          name='name'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.name && formik.errors.name},
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='row'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Country Code</label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='Country Code'
          {...formik.getFieldProps('code')}
          type='text'
          name='code'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
        {/* end::Input */}

        {/* begin::Hint */}
        {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
        {/* end::Hint */}
      </div>

      <div className='fv-row row mb-7'>{/* end::Input group */}</div>

      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Country Phone Code </label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='Country Phone Code '
          {...formik.getFieldProps('phoneCode')}
          type='tel'
          name='phoneCode'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.phoneCode && formik.errors.phoneCode},
            {
              'is-valid': formik.touched.phoneCode && !formik.errors.phoneCode,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.phoneCode && formik.errors.phoneCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phoneCode}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Unit</label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='Unit'
          {...formik.getFieldProps('unit')}
          type='password'
          name='unit'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.unit && formik.errors.unit},
            {
              'is-valid': formik.touched.unit && !formik.errors.unit,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.unit && formik.errors.unit && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.unit}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='mb-7'>
        <div className='row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Tax (%)</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Tax'
            {...formik.getFieldProps('tax')}
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              {'is-invalid': formik.touched.tax && formik.errors.tax},
              {
                'is-valid': formik.touched.tax && !formik.errors.tax,
              }
            )}
            type='tax'
            name='tax'
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
          />
          {/* end::Input */}
          {formik.touched.tax && formik.errors.tax && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.tax}</span>
            </div>
          )}
        </div>
      </div>
      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'> Tax Inclusive (%) </label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder=' Tax Inclusive (%) '
          {...formik.getFieldProps('taxInclusive')}
          type='number'
          name='taxInclusive'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.taxInclusive && formik.errors.taxInclusive},
            {
              'is-valid': formik.touched.taxInclusive && !formik.errors.taxInclusive,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.taxInclusive && formik.errors.taxInclusive && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.taxInclusive}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'> Commission Tax (%) </label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder=' Commission Tax (%) '
          {...formik.getFieldProps('commissionTax')}
          type='number'
          name='commissionTax'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.commissionTax && formik.errors.commissionTax},
            {
              'is-valid': formik.touched.commissionTax && !formik.errors.commissionTax,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.commissionTax && formik.errors.commissionTax && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.commissionTax}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
      <div className='row mb-7'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>Status </label>
        {/* end::Label */}

        {/* begin::Input */}
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className={clsx(
              'form-check-input  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.status && formik.errors.status},
              {
                'is-valid': formik.touched.status && !formik.errors.status,
              }
            )}
            {...formik.getFieldProps('status')}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
            type='checkbox'
            name='status'
            id='flexSwitchDefault'
          />
        </div>
        {formik.touched.status && formik.errors.status && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.status}</span>
            </div>
          </div>
        )}
        {/* end::Input */}
      </div>
    </div>
  )
}

export default Form1
