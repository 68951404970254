import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {User} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import UsersListPagination from '../components/pagination/UsersListPagination'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {object} from 'yup'
import {UseCallApi} from '../../../../../../../_metronic/CallApi'
import {AppContext} from '../../../../../../../AppContext'
type Props = {
  handleDeleteUser: any
  setData: any
  data: any
  headerFilter: any
}
const SmsTable: FC<Props> = ({headerFilter, setData, data, handleDeleteUser}) => {
  const {name, statu, countries} = headerFilter
  const callApi = UseCallApi()
  const [idUser, seIdUser] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(10)
  const [filterSatuts, seTfilterSatuts] = useState<any>(0)
  const [setFilter, filter] = useState('')
  const [filterData, setFilterData] = useState<User[]>([])
  const location = useLocation()
  const path = location.pathname
  const {myFilter, deleteAlert} = useContext(AppContext)
  const UpdateStatusCountry = async (id, status) => {
    try {
      const data = await UseCallApi()({
        route: `countries/${id}`,
        body: {status: Number(status)},
        method: 'PUT',
      })
      return data
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  // const rechercheDrive = () => {
  //   setData((prevData) => prevData.filter((user) => user.statu == statu))
  // }
  // useEffect(() => {
  //   rechercheDrive()
  // }, [statu, countries, name])

  const updateUserStatus = (id: any, newStatus: any) => {
    UpdateStatusCountry(id, newStatus)
    setData((data) => data.map((user) => (user.id == id ? {...user, status: newStatus} : user)))
  }
  const responseData = useQueryResponseData()['hydra:totalItems']
  useEffect(() => {
    setTotalItems(responseData)
  }, [responseData])

  // useEffect(() => {
  //   handleDeleteUser(idUser)
  // }, [idUser])
  // useEffect(()=>{updateUserStatus(idUser,filterSatuts)},[filterSatuts])

  // const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  // useMemo(() => setData(users['hydra:member']), [])
  const users = useQueryResponseData()['hydra:member']

  // const data = useMemo(() => users, [users])
  useEffect(() => {
    if (users != undefined) {
      setData(users)
    } else {
      setData([])
    }
  }, [users])
  // //console.log(data)

  // useMemo(() => {
  //   // //console.log(users['hydra:member'])
  //   if (users != undefined) {
  //     setData(users['hydra:member'])
  //   }
  // }, [users])
  // fetch data

  const columns = useMemo(() => {
    return usersColumns
  }, [path, page])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-start text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return (
                  <CustomRow
                    handleDeleteUser={handleDeleteUser}
                    seTfilterSatuts={seTfilterSatuts}
                    row={row}
                    updateUserStatus={updateUserStatus}
                    setFilter={setFilter}
                    path={path}
                    key={`row-${i}-${row.id}`}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination setPage={setPage} totalItems={totalItems} />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {SmsTable}
