// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {UserDispoCell} from './UserDispoCell'
import {UserStatusCell} from './UserStatusCell'
import {UserStatu} from './UserStatu'
import SimpleInfo from '../../../../tables/colmuns/SimpleInfo'
import {FormatDate} from '../../../../../../../_metronic/helpers/functions/FormatDate'
import ModalDrivers from '../../../../Modal/ModalDrivers'
import {useQueryResponse} from '../../core/QueryResponseProvider'
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Réservé par' className='min-w-125px' />
    ),
    id: 'bookedBy',
    Cell: ({...props}) => (
      <SimpleInfo
        value={"Booking.com"}
        // value={props.data[props.row.index]?.bookedBy}
        // Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Réservation ajoutée' className='min-w-125px' />
    ),
    id: 'bookingAdd',
    Cell: ({...props}) => (
      <SimpleInfo
        value={"name"}
        // value={props.data[props.row.index]?.nameBooked}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Numéro de réservation' className='min-w-125px' />
    ),
    id: 'id',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='date' className='min-w-125px' />
    ),
    id: 'dateD',
    Cell: ({...props}) => (
      <SimpleInfo
        value={"date"}
        // value={props.data[props.row.index]?.nameBooked}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Received Date ' className='min-w-125px' />
    ),
    id: 'dateF',
    Cell: ({...props}) => (
      <SimpleInfo
        value={"date"}
        // value={props.data[props.row.index]?.nameBooked}
      />
    ),
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title="Nom de l'entreprise" className='min-w-125px' />
  //   ),
  //   id: 'company',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].company} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='origin'  />
    ),
    id: 'origin',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.origin} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='destination'  />
    ),
    id: 'destination',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.destination} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title="Date d'inscription" className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='date' className='min-w-125px' />,
  //   id: 'dateTrip',
  //   Cell: ({...props}) => (
  //     <SimpleInfo
  //       value={props.data[props.row.index] && FormatDate(props.data[props.row.index]?.dateTrip)}
  //     />
  //   ),
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='driver' className='min-w-125px' />
    ),
    id: 'driver',
    Cell: ({...props}) => (
      <SimpleInfo value={props.data[props.row.index]?.driver?.driver?.name} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='passager' className='min-w-125px' />
  //   ),
  //   id: 'rider',
  //   Cell: ({...props}) => (
  //     <SimpleInfo
  //       value={
  //         props.data[props.row.index]?.rider &&
  //         `${props.data[props.row.index]?.rider?.passager?.firtname} ${
  //           props.data[props.row.index]?.rider?.passager?.lastname
  //         }`
  //       }
  //     />
  //   ),
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='prix' className='text-start min-w-50px' />
    ),
    id: 'prix',
    Cell: ({...props}) => (
      <SimpleInfo value={"12 €"} />
    ),
    // id: 'Mobile',
    // Cell: ({...props}) => (
    //   <UserTelCell id={props.data[props.row.index].tel} className='min-w-125px' />
    // ),
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Afficher/Modifier le(s) document(s)'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'document',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Marquer
  //     comme favori'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'Marquer_comme_favori',
  //   Cell: ({...props}) => (
  //     <UserStatusCell
  //       id={props.data[props.row.index].favori}
  //       checked={props.data[props.row.index].favori}
  //     />
  //   ),
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Statu' className='text-start min-w-100px' />
  //   ),
  //   id: 'statu',
  //   Cell: ({...props}) => <UserStatu statu={props.data[props.row.index].statu} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='affecter' className='text-center min-w-50px' />
  //   ),
  //   id: 'affecter',
  //   Cell: ({...props}) => <ModalDrivers id={props.data[props.row.index].id} query={'/trips'} />,
  //   // Cell: ({...props}) => <ModalDrivers id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        id={props.data[props.row.index].id}
        seIdUser={props.row.original.id} // Pass the seIdUser here
        myprops={props}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
