import React, {FC} from 'react'

type Props = {
  data?: any
}
const DocumentDriver: FC<Props> = () => {
  return (
    <div className='mb-5'>
      <div className='accordion' id='kt_accordion_1'>
        <div className='accordion-item'>
          <h2 className='accordion-header' id='kt_accordion_1_header_1'>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#carteGrise'
              aria-expanded='false'
              aria-controls='carteGrise'
            >
              Documents
            </button>
          </h2>
          <div
            id='carteGrise'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#kt_accordion_1'
          >
            <div className='accordion-body row '>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <label htmlFor='carteGriseRecto' className='form-label me-3'>
                  Carte grise Recto :
                </label>
                <div>
                  {'application/pdf' === 'application/pdf' ? (
                    <div style={{marginTop: '20px', overflow: 'hidden'}}>
                      <iframe
                        title='Embedded PDF'
                        src={'https://www.orimi.com/pdf-test.pdf'}
                        style={{
                          width: '100%',
                          height: '380px', // Adjust the height as needed
                          border: 'none',
                          overflow: 'hidden',
                        }}
                        frameBorder={0}
                        scrolling='no' // Add scrolling="no" to prevent iframe scrolling
                      />
                    </div>
                  ) : (
                    <img
                      alt='Embedded Image'
                      src={
                        'https://upload.wikimedia.org/wikipedia/en/thumb/e/e2/IMG_Academy_Logo.svg/1200px-IMG_Academy_Logo.svg.png'
                      }
                      style={{
                        width: '200px',
                        height: 'auto',
                        border: 'none',
                        overflow: 'hidden',
                      }}
                    />
                  )}
                </div>{' '}
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12'>
                <label htmlFor='carteGriseRecto' className='form-label me-3'>
                  Carte grise Recto :
                </label>
                <div>
                  {'application/pdf' === 'application/pdf' ? (
                    <div style={{marginTop: '20px', overflow: 'hidden'}}>
                      <iframe
                        title='Embedded PDF'
                        src={'https://www.orimi.com/pdf-test.pdf'}
                        style={{
                          width: '100%',
                          height: '380px', // Adjust the height as needed
                          border: 'none',
                          overflow: 'hidden',
                        }}
                        frameBorder={0}
                        scrolling='no' // Add scrolling="no" to prevent iframe scrolling
                      />
                    </div>
                  ) : (
                    <img
                      alt='Embedded Image'
                      src={
                        'https://upload.wikimedia.org/wikipedia/en/thumb/e/e2/IMG_Academy_Logo.svg/1200px-IMG_Academy_Logo.svg.png'
                      }
                      style={{
                        width: '200px',
                        height: 'auto',
                        border: 'none',
                        overflow: 'hidden',
                      }}
                    />
                  )}
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentDriver
