import {ListViewProvider, useListView} from './core/ListViewProvider'
import * as React from 'react'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CompanyTable} from './table/CompanyTable'
import {KTCard} from '../../../../../_metronic/helpers'
// import {MenuInner} from '../../../../../_metronic/layout/components/header/header-menus'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import {useState} from 'react'
import {CompanyListHeader} from './components/header/CompanyListHeader'
import {UseCallApi} from '../../../../../_metronic/CallApi'
import {AppContext} from '../../../../../AppContext'
// import {HeaderWrapper} from '../../../../../_metronic/layout/components/header'
const Links = [
  {title: 'Liste', link: '/apps/user-management'},
  {title: 'En attente', link: '/en-attente'},
  {title: 'Disponible', link: '/disponible'},
  {title: 'Drives', link: '/drives'},
]

const CompanyList = () => {
  const callApi = UseCallApi()
  const {deleteAlert, errorToas} = React.useContext(AppContext)

  const [data, setData] = useState<any>([])

  const handleDeleteUser = async (idUser) => {
    const dataa = await deleteDrivers(idUser)
    if (dataa && dataa?.response['data']?.detail) {
      errorToas(dataa?.response['data']?.detail)
    } else {
      setData((prevData) => prevData.filter((user) => user.id !== idUser))
    }
  }
  const deleteDrivers = async (id) => {
    try {
      const data = await callApi({
        route: `companies/${id}`,
        method: 'DELETE',
      })
      return data
    } catch (error) {
      return error
    }
  }
  const [headerFilter, setHeaderFilter] = useState({
    name: '',
    statu: '',
    countries: '',
  })

  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CompanyListHeader
          setHeaderFilter={setHeaderFilter}
          headerFilter={headerFilter}
          handleDeleteUser={handleDeleteUser}
        />
        <CompanyTable
          headerFilter={headerFilter}
          setData={setData}
          data={data}
          handleDeleteUser={handleDeleteUser}
        />
      </KTCard>
    </>
  )
}

const CompanyListWrapper = () => (
  <QueryRequestProvider>
    <HeaderWrapper linkss={Links} />
    <QueryResponseProvider>
      <ListViewProvider>
        <CompanyList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CompanyListWrapper}
