import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'

const Tab = () => {
  const [activeTab, setActiveTab] = useState('active') // Initialize the active tab

  const handleTabClick = (tabName) => {
    setActiveTab(tabName)
  }

  return (
    <div className='mb-5'>
      <ul className='nav nav-tabs  '>
        <li className={`nav-item rounded-start  bg-secondary w-50`}>
          <NavLink
            to='/auth/registration/user'
            className='nav-link '
            // onClick={() => handleTabClick('active')}
          >Admin
          </NavLink>
        </li>
        <li className={`nav-item w-50 rounded-end bg-secondary `}>
          <NavLink
            to='/auth/registration/driver'
            className='nav-link'
            // onClick={() => handleTabClick('link')}
          >
            Driver
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default Tab
