// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import Info from './Info'
import SimpleInfo from '../../../../../tables/colmuns/SimpleInfo'
import ListePermissin from './ListePermissin'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Roles' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <ListePermissin user={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Routes' className='min-w-125px' />
  //   ),
  //   id: 'routes',
  //   Cell: ({...props}) => <ListePermissin array={props.data[props.row.index]?.routes} />,
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        setFilter={props.setFilter}
        id={props.data[props.row.index].id}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
