import React from 'react'
type Props = {
  user: any
}
const ListePermissin: React.FC<Props> = ({user}) => {
  return (
    <div>
<div className='mb-2'>
<span >{user.name}</span>

</div>
      {user && user.routes &&
        user.routes.map((item: any, index: number) => (
          <span key={index}  className='me-2 badge badge-secondary fs-5  px-2 py-1'>
            {item.name}
          </span>
        ))}
    </div>
  )
}

export default ListePermissin
