import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'
import Form1 from './Form1'
import { PageTitle } from '../../../_metronic/layout/core'

// const nameValidation = Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Name is required')
const message = 'message est requis'

const languages = [
  {code: 'french', label: 'Français'},
  {code: 'english', label: 'Anglais'},
  {code: 'deuatsche', label: 'Deutsche'},
  {code: 'arabic', label: 'Arabic'},
  {code: 'spanish', label: 'Spanish'},
  {code: 'chinese', label: 'Chinese'},
  {code: 'russian', label: 'Russian'},
  {code: 'india', label: 'Indian'},
  {code: 'nedrlands', label: 'Nedrlands'},
  {code: 'polish', label: 'Polish'},
  {code: 'italy', label: 'Italian'},
]

const editUserSchema = Yup.object().shape({
  name: Yup.string().required("'Le but est requis"),
  ...languages.reduce((schema, {code}) => {
   
    schema[`${code}Body`] = Yup.string().required(message)
    return schema
  }, {}),
})
const AddPages = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const callApi = UseCallApi()

  // get driver when update
  const getMail = async (id) => {
    try {
      const data = await callApi({
        route: `email_templates/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getMail(id)
    }
  }, [id])

  useEffect(() => {}, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: updateData?.name,
      code: updateData?.code,
      frenchBody: updateData?.frenchBody,
      englishBody: updateData?.englishBody,
      deuatscheBody: updateData?.deuatscheBody,
      arabicBody: updateData?.arabicBody,
      spanishBody: updateData?.spanishBody,
      chineseBody: updateData?.chineseBody,
      russianBody: updateData?.russianBody,
      indiaBody: updateData?.indiaBody,
      nedrlandsBody: updateData?.nedrlandsBody,
      polishBody: updateData?.polishBody,
      italyBody: updateData?.italyBody,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const postData = {
        name: values.name,
        code:values?.code,
        frenchBody: values.frenchBody,
        englishBody: values.englishBody,
        deuatscheBody: values.deuatscheBody,
        arabicBody: values.arabicBody,
        spanishBody: values.spanishBody,
        chineseBody: values.chineseBody,
        russianBody: values.russianBody,
        indiaBody: values.indiaBody,
        nedrlandsBody: values.nedrlandsBody,
        polishBody: values.polishBody,
        italyBody: values.italyBody,
      }

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postCity = async () => {
          setIsLoading(true)
          try {
            const data = await callApi({
              route:
                location.pathname === '/mail-template-management/add'
                  ? 'email_templates'
                  : `email_templates/${id}`,
              method: location.pathname === '/mail-template-management/add' ? 'POST' : `PUT`,
              body: postData,
            })
            if (data) {
              setIsLoading(false)
              successToas('success')
              navigate('/mail-template-management')
            }
          } catch (error: any) {
            setIsLoading(false)
            errorToas(error.response['data'].detail)
          }
        }
        postCity()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <PageTitle>
        Pages
      </PageTitle>
      <Form1 formik={formik} isUserLoading={isUserLoading} languages={languages} />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        <>
          {isLoading ? (
            <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
              <Spinner />
            </button>
          ) : (
            <button
              type='submit'
              className='btn btn-primary'
              onClick={(e: any) => formik.handleSubmit(e)}
            >
              Submit
            </button>
          )}
        </>
      </div>
    </>
  )
}

export default AddPages
