import {FC} from 'react'

type Props = {
  two_steps?: boolean
  id?: any
}

const UserTelCell: FC<Props> = ({id}) => <> {id && <div className='badge  fw-bolder'>{id}</div>}</>

export {UserTelCell}
