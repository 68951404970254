import React, {useEffect, useState} from 'react'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {Link, useParams} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Reating} from '../../../_metronic/layout/Reating'
import DirectMaps from '../../modules/apps/customs/maps/DirectMaps'
import MapComponent from '../../modules/apps/Partial/MapComponent'
import {UseCallApi} from '../../../_metronic/CallApi'
import {FormatDate} from '../../../_metronic/helpers/functions/FormatDate'

const DetailDrive = () => {
  const [destination, setDestination] = React.useState()
  const [duration, setDuration] = React.useState<any>()
  const [distance, setDistance] = React.useState<any>()
  const [origin, setOrigin] = React.useState<any>()
  const [data, setData] = useState<any>({})
  const callApi = UseCallApi()
  const {id} = useParams()

  const getDrive = async (id) => {
    try {
      const data = await callApi({
        route: `trips/${id}`,
        method: 'GET',
      })
      // //console.log(JSON.parse(data.originLatlong))
      setDestination(JSON.parse(data.destinationLatLong))
      setOrigin(JSON.parse(data.originLatlong))

      // data.originLatlong = JSON.parse(data.originLatlong)
      // data.destinationLatLong = JSON.parse(data.destinationLatLong)

      // Now you can access the latitude and longitude values
      // const originLat = data.originLatlong.lat
      // const originLng = data.originLatlong.lng
      // const destinationLat = data.destinationLatLong.lat
      // const destinationLng = data.destinationLatLong.lng

      // //console.log('Origin Latitude:', originLat)
      // //console.log('Origin Longitude:', originLng)
      // //console.log('Destination Latitude:', destinationLat)
      // //console.log('Destination Longitude:', destinationLng)

      setData(data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getDrive(id)
  }, [id])
  //console.log(origin)

  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <div>
      <HeaderWrapper linkss={Links} />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6  mb-6'>
          <div className=' card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer p-3 px-5 '>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Mercedes benz classe</h3>
              </div>

              <div className='symbol symbol-100px symbol-lg-100px '>
                <img
                  src={
                    'https://w7.pngwing.com/pngs/612/696/png-transparent-2018-mercedes-benz-gle-class-mercedes-benz-m-class-mercedes-benz-glk-class-car-mercedes-a180-compact-car-sedan-car.png'
                  }
                  alt='Metronic'
                />
              </div>
            </div>

            <div className='card-body p-9'>
              <div className='row  mb-7'>
                <label className='col-lg-4 fw-bold text-muted mb-2'>Detail vol</label>
                <div className='ps-7 d-flex'>
                  <label className='col-lg-4 fw-bold me-2 mb-2 '>Numéro de vol:</label>
                  <span className='fw-bolder fs-6 text-dark'>TP442</span>
                </div>
                <div className='ps-7 d-flex'>
                  <label className='col-lg-4 fw-bold me-2 mb-2 '>Origine</label>
                  <span className='fw-bolder fs-6 text-dark'></span>
                </div>
                <div className='ps-7 d-flex'>
                  <label className='col-lg-4 fw-bold me-2 mb-2 '>Terminal d'arrivée</label>
                  <span className='fw-bolder fs-6 text-dark'></span>
                </div>
                <div className='ps-7 d-flex'>
                  <label className='col-lg-4 fw-bold me-2 mb-2 '>Heure d'arrivée</label>
                  <span className='fw-bolder fs-6 text-dark'></span>
                </div>
                <div className='ps-7 d-flex'>
                  <label className='col-lg-4 fw-bold me-2 mb-2 '>L'état du vol</label>
                  <span className='fw-bolder fs-6 text-dark'></span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Chauffeur</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.driver?.driver?.fulname}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Passagier</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>
                    {data?.rider?.lastname} {data?.rider?.firstname}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Origin</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{data?.origin}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Destination</label>

                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>{data?.destination}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Distance (Km)</label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{distance && distance} Km</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>temps </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{duration && duration} </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Pourboire</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>5€</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Remarque
                  {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus aliquid
                    suscipit laboriosam quaerat quos nam voluptatem dolorum, quas itaque beatae
                    facilis exercitationem repudiandae cum. Neque accusamus placeat reprehenderit
                    deleniti tempore!
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Avis{' '}
                  {/* <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Country of origination'
                  ></i> */}
                </label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    <Reating />{' '}
                  </span>
                </div>
              </div>

              {/* <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Communication</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
                </div>
              </div>

              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Yes</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className=' col-sm-12 col-md-6 col-lg-6 card  mb-6 '>
          <div className='card-header  d-flex align-items-center justify-conetnt-between'>
            <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
              <label className='me-3'>{FormatDate(data?.dateTrip)} </label>
            </a>

            <a
              href='#'
              className='btn btn-sm btn-light-success fw-bolder ms-2 fs-1 py-1 px-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_upgrade_plan'
            >
              30€
            </a>
          </div>
          <div className='card-body w-100'>
            <MapComponent
              setDistance={setDistance}
              distance={distance}
              setDuration={setDuration}
              duration={duration}
              destination={destination}
              setDestination={setDestination}
              origin={origin}
              setOrigin={setOrigin}
              readOnly={true}
            />
            {/* <DirectMaps
              origin={origin}
              destination={destination}
              setDistance={setDistance}
              setTravelTime={setTravelTime}
            /> */}

            {/* <DirectMaps origin={origin} destination={destination} /> */}
            {/* <iframe
            className='card-body p-3'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.8996825673603!2d-1.9028514253648232!3d34.65723632574627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd787cbafd17aac1%3A0x1761774638ec2fd6!2sFCPO%3A%20Agence%20web%20et%20de%20marketing%20digital%20%C3%A0%20Oujda!5e0!3m2!1sfr!2sma!4v1691688048770!5m2!1sfr!2sma'
            height='350'
            style={{border: '0', width: '100%'}}
            allowFullScreen
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='Google Map'
          ></iframe> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailDrive
