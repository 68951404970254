// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTelCell} from './UserTelCell'
// import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {UserDispoCell} from './UserDispoCell'
import {ActionCelellDisponible} from './ActionCelellDrives'
import { UserCustomHeader } from './UserCustomHeader'
// import {UserStatusCell} from './UserStatusCell'

const usersColumnsDrives: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Nom du Conducteur' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Nom du passagier' className='min-w-125px' />
    ),
    id: 'passagier',
    Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].passagier} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='départ' className='min-w-100px' />
    ),
    id: 'départ',
    Cell: ({...props}) => <UserLastLoginCell last_login={`${props.data[props.row.index].city} `} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='arrivée' className='min-w-100px' />
    ),
    id: 'arrivée',
    Cell: ({...props}) => <UserLastLoginCell last_login={`${props.data[props.row.index].city} `} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Distances(Km)' className='min-w-125px' />
    ),
    id: 'tel',
    Cell: ({...props}) => (
      <UserLastLoginCell last_login={`${props.data[props.row.index].distance} Km`} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title="Date d'inscription" className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Mobile' className='text-center min-w-50px' />
  //   ),
  //   id: 'Mobile',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Solde du portefeuille'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'Solde_du_portefeuille',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Afficher/Modifier le(s) document(s)'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'document',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title='Marquer
  //     comme favori'
  //       className='text-center min-w-50px'
  //     />
  //   ),
  //   id: 'Marquer_comme_favori',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Statu' className='text-center min-w-50px' />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => <UserStatusCell id={props.data[props.row.index].status} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-start min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionCelellDisponible className='text-start' id={props.data[props.row.index].id} />
    ),
  },
]

export {usersColumnsDrives}
