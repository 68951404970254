import React, { FC } from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
type Props={
  formik:any
  isUserLoading:any
}
const AppearanceSeting:FC<Props> = ({formik,isUserLoading}) => {
  return (
    <div className='row'>
    <div className='col'>
      <div className='row mb-4'>
        <LabelInput
          label={"Nom de l'entreprise dans l'e-mail"}
          type={'string'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'companyNameInEmail'}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={"Texte de copyright à afficher dans le pied de page d'administration "}
          type={'string'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'copyrightTextToShowInAdminFooter'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Lien vers l'application du pilote (Android)"}
          type={'url'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'driverAppLinkAndriod'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Lien vers l'application du pilote (Ios)"}
          type={'url'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'driverAppLinkIos'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Texte de copyright à afficher dans le pied de page du site Web '}
          type={'string'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'copyRightTextToShowInWebSiteFooter'}
        />
      </div>
    </div>
    <div className='col'>
      <div className='row mb-4'>
        <LabelInput
          label={"E-mail d'assistance"}
          type={'email'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'supportEmail'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Lien vers l'application Android dans le pied de page du site Web"}
          type={'url'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'androidAppLinkInWebsiteFooter'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Lien vers l'application iPhone dans le pied de page du site Web"}
          type={'url'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'iphoneAppLinkInWebSiteFooter'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Adresse de la société"}
          type={'url'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'companyAddress'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={"Téléphone d'assistance"}
          type={'phone'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'supportPhone'}
        />
      </div>
    </div>
  </div>
  )
}

export default AppearanceSeting
