import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'

import QuilleModule from '../../../_metronic/partials/QuilleModule'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelTextArea from '../../../_metronic/partials/LabelTextArea'
import {TranslateText} from '../TranslateText/TranslateText'

type Props = {
  isUserLoading: boolean
  formik: any
  languages: any
}

const Form1: FC<Props> = ({formik, isUserLoading, languages}) => {
  const translateText = async (key, text) => {
    return await TranslateText(key, text)
  }
  const translateAll = () => {
    const inputText = formik.getFieldProps(`englishBody`).value

    if (inputText && inputText.length > 0) {
      languages.forEach(async (item) => {
        if (item.key !== 'en' && item.key !== 'fr') {
          const translatedText = await translateText(item.key, inputText)
          formik.setFieldValue(`${item.code}Body`, translatedText)
        }
      })
    }
  }
  const copyEnglishToAll = () => {
    const inputText = formik.getFieldProps(`englishBody`).value
    if (inputText && inputText.length > 0) {
      languages.forEach(async (item) => {
        if (item.key !== 'en' && item.key !== 'fr') {
          formik.setFieldValue(`${item.code}Body`, inputText)
        }
      })
    }
  }

  const languageInputs = languages.map((language) => (
    <div key={language.code}>
      <div className={`row mb-4 align-items-end`}>
        <div className={`${language.code === 'english' ? ' col-6' : ''}`}>
          <LabelInput
            formik={formik}
            type={'text'}
            name={`${language.code}Body`}
            label={language.label}
            isUserLoading={isUserLoading}
            placeholder={'Value'}
          />
        </div>
        {language.code === 'english' && (
          <div className='col-6 row'>
            <div className='col-6 '>
              <button className='btn btn-primary w-100' onClick={translateAll}>
                Convertir dans toutes les langues
              </button>
            </div>
            <div className='col-6 '>
              <button className='btn btn-primary w-100'onClick={copyEnglishToAll} >Copier english À tous</button>
            </div>
          </div>
        )}
      </div>
    </div>
  ))

  return (
    <div>
      <div className='row mb-4'>
        <LabelInput
          type={'text'}
          formik={formik}
          label={'Étiquette de langue          '}
          name={'code'}
          isUserLoading={isUserLoading}
        />
      </div>

      {languageInputs}
    </div>
  )
}

export default Form1
