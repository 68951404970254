import React, { useState, useEffect } from 'react'
import { InfoWindow, GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { AiOutlineUser } from 'react-icons/ai'
import clsx from 'clsx'
import { BiSolidBabyCarriage } from 'react-icons/bi'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const mapContainerStyle = {
  width: '100%',
  height: '400px',
}

type Props = {
  origin?: google.maps.LatLngLiteral
  user: any
  data: any
}

const MapDriver: React.FC<Props> = ({ origin, user, data }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null)

  const onLoad = (map: google.maps.Map) => {
    setMap(map)
  }

  const onMarkerClick = (item: any) => {
    setSelectedMarker(item)
  }

  const closeInfoWindow = () => {
    setSelectedMarker(null)
  }
useEffect(()=>{
  if(user){
    onMarkerClick(user)
  }
},[user])
  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_KEY_GOOGLE_MAP}`}>
      <GoogleMap mapContainerStyle={mapContainerStyle} center={origin} zoom={8} onLoad={onLoad}>
        {data &&
          data.map((item: any, index: number) => (
            <div key={item.id}>
              {typeof window.google !== 'undefined' && origin && (
                <Marker
                  position={{ lat: Number(item?.latitude), lng: Number(item?.longtitude) }}
                  title='test'
                  onClick={() => onMarkerClick(item)}
                  icon={{
                    url: toAbsoluteUrl('/media/icons/car-top.png'),
                    scaledSize: new window.google.maps.Size(50, 50) as any,
                  }}
                />
              )}
              {item && origin && selectedMarker === item && (
                <InfoWindow position={{ lat: Number(item?.latitude), lng: Number(item?.longtitude) }} onCloseClick={closeInfoWindow}>
                  {item ? (
                    <div className='border mb-4 p-2 d-flex flex-center'>
                      <div className='symbol symbol-circle symbol-50px me-3'>
                        {item.user && item.user.photo ? (
                          <div className='symbol-label'>
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${item?.user?.photo}`}
                              alt={item.name}
                              className='w-100'
                            />
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              'symbol-label fs-3',
                              `bg-light-${item.initials?.state}`,
                              `text-${item.initials?.state}`
                            )}
                          >
                            <AiOutlineUser />
                          </div>
                        )}
                      </div>
                      <div className='d-flex flex-column'>
                        <div>{item.name}</div>
                        <div>{item.email}</div>
                      </div>
                    </div>
                  ) : (
                    <div>No user information available</div>
                  )}
                </InfoWindow>
              )}
            </div>
          ))}
      </GoogleMap>
    </LoadScript>
  )
}

export default MapDriver
