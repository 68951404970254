import React, {FC, ChangeEvent, useState} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {initialQueryState} from '../../../../../../../_metronic/helpers'

type Option = {
  label: string
  value: any
}

type Props = {
  options?: Option[]
  placeholder?: string
  setHeaderFilter?: any
  headerFilter?: any
  filterBy?: any
}

const CustomSelectUsers: FC<Props> = ({
  options = [],
  placeholder,
  setHeaderFilter,
  headerFilter,
  filterBy,
}) => {
  const {updateState, state} = useQueryRequest()
  const [selected, setSelected] = useState()
  const handleChange = (event: any) => {
    const selectedValue = event.target.value
    setSelected(selectedValue)
    let myfilter
    if (filterBy == 'countries') {
      //console.log('test')
      myfilter = {...state.filter, countries: selectedValue}
    } else if (filterBy == 'favorie') {
      myfilter = {...state.filter, favorie: selectedValue}
    } else {
      myfilter = {...state.filter, status: selectedValue}
    }
    updateState({
      // filter: {[filterBy]: selectedValue, ...state.filter},
      filter: myfilter,
      ...initialQueryState,
    })

    // Create a new headerFilter object with updated status value
    // const newHeaderFilter = {
    //   ...headerFilter,
    //   [filterBy]: selectedValue,
    // }
    // // Call the setHeaderFilter function with the updated headerFilter
    // setHeaderFilter(newHeaderFilter)
  }

  return (
    <select
      onChange={(e) => handleChange(e)}
      value={selected} // Set the selected value
      placeholder={placeholder}
      className='ms-3 form-select form-select-solid fw-bolder'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      data-kt-user-table-filter='two-step'
      data-hide-search='true'
    >
      <option value=''>{filterBy}</option>
      {options &&
        options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  )
}

export default CustomSelectUsers
