import React, {FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import LabelInput from '../../../_metronic/partials/LabelInput'
import {log} from 'console'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import { UseCallApi } from '../../../_metronic/CallApi'

type Props = {
  isUserLoading: boolean
  formik?: any


  initialData: any[] // Add the initialData prop
}

const Form1: FC<Props> = ({
  formik,
  isUserLoading,

}) => {


  const [routes,setRoutes]=useState<any>([])

 
   const getRoutes = async () => {
    try {
      const data = await UseCallApi ()({
        route: `routes.json`,
        method: 'GET',
      })
  
      const modifiedData = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id
        // value: item.user.id,
      }))
  
      setRoutes(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(()=>{
    getRoutes()

  },[])
  return (
    <div>
  
      <div className='row mb-4'>
        <LabelInput
          isUserLoading={isUserLoading}
          formik={formik}
          name='name'
          type={'text'}
          label={'Nom'}
        />
      </div>
      <div className="row mb-4">
      <label htmlFor='state' className='form-label'>
          Routes
        </label>
        <SearchSelect
          options={routes}
          defaultValue={formik.values.routes}
          name={'routes'}
          formik={formik}
          placeholder='route'
          isMulti={true}
          
        />
       
      </div>
      {/* <div className='border'>
        <div className='d-flex border justify-content-between align-items-center bg-light-primary p-3'>
          <div>
            <label>Group Permission</label>
          </div>
          <div className='d-flex justify-content-between gap-2'>
            <input
              type='text'
              className='form-control'
              name='searchPermission'
              value={searchPermission}
              onChange={(e) => setSearchPermission(e.target.value)}
            />
            <button className='btn btn-info btn-sm p-1 text-nowrap' onClick={handleSelectAllChebox}>
              Tout sélectionner
            </button>
            <button
              className='btn btn-danger btn-sm p-1 text-nowrap'
              onClick={removeAllSelectedChecbox}
            >
              Enlever tout
            </button>
          </div>
        </div>

        <div className='  '>
          {filteredPermissions.map((item, index) => (
            <div className='mb-4 border p-5' key={`row${item?.id}`}>
              <div className='d-flex border justify-content-between align-items-center bg-light-primary p-3'>
                <div>
                  <label>{item.name}</label>
                </div>
                <div className='d-flex justify-content-between gap-2'>
                  <input
                    type='text'
                    className='form-control'
                    name=''
                    id=''
                    value={routeSearchValues[item.id] || ''}
                    onChange={(e) => handleRouteSearchChange(item.id, e.target.value)}
                  />
                  <button
                    className='btn btn-info btn-sm p-1 text-nowrap'
                    onClick={() => handleSelectAll(item.id)}
                  >
                    Tout sélectionner
                  </button>
                  <button
                    className='btn btn-danger btn-sm p-1 text-nowrap'
                    onClick={() => handelRemoveSelect(item.id)}
                  >
                    Enlever tout
                  </button>
                </div>
              </div>
              <div className='d-flex justify-content-between border p-5'>
                {item.routes
                  .filter((route) =>
                    route.name.toLowerCase().includes(routeSearchValues[item.id] || '')
                  )
                  .map((route) => (
                    <div key={`route${route.id}`} className='d-flex'>
                      <input
                        className='form-check-input me-2'
                        type='checkbox'
                        checked={checkedRoutes[item.id]?.[route.id] || false}
                        onChange={(e) => handleCheckboxChange(item.id, route.id, e.target.checked)}
                        id={`router-${route.id}-${item.id}`}
                      />
                      <label htmlFor={`router-${route.id}-${item.id}`} className='form-check-label'>
                        {route.name}
                      </label>
                    </div>
                  ))}
               
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  )
}

export default Form1
