// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserTelCell} from './UserTelCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import Info from './Info'
import SimpleInfo from '../../../../../tables/colmuns/SimpleInfo'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Countries' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <Info user={props.data[props.row.index]?.name} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Code' className='min-w-125px' />,
    id: 'code',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.code} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='unite' className='min-w-125px' />
    ),
    id: 'unite',
    Cell: ({...props}) => <SimpleInfo value={props.data[props.row.index]?.unité} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        setFilter={props.setFilter}
        id={props.data[props.row.index].id}
        editUser={props.data[props.row.index]}
      />
    ),
  },
]

export {usersColumns}
