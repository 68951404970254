import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelInput from '../../../_metronic/partials/LabelInput'
import {Link} from 'react-router-dom'
import LabelRadio from '../../../_metronic/partials/labelRadio'
import LabelSwitch from '../../../_metronic/partials/LabelSwitch'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}

const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])
  const handleLocationSelect = (location: google.maps.LatLngLiteral) => {
    // Handle the selected location here
    //console.log('Selected Location:', location)
  }
  const [countries, setCountries] = useState<any>([])
  const [origin, setOrigin] = useState({lat: 48.864716, lng: 2.349014})
  const [states, setState] = useState<any>([
    {value: 'Sélectionnez un emplacement pour', label: 'Sélectionnez un emplacement pour'},
    {value: 'Zone réglementée', label: 'Zone réglementée'},
    {value: 'Type de véhicule', label: 'Type de véhicule'},
    {
      value: "Tarif judicieux en matière d'emplacement",
      label: "Tarif judicieux en matière d'emplacement",
    },
    {value: 'Supplément aéroportuaire', label: 'Supplément aéroportuaire'},
    {value: 'Délai de mise en œuvre', label: 'Délai de mise en œuvre'},
  ])
  const selectLocation = [
    {
      value: 'all',
      label: 'all',
    },
    {
      value: 'BordeauxFrance',
      label: 'Bordeaux - France',
    },
    {
      value: 'emiratsArabesUnis',
      label: 'Emirats Arabes Unis',
    },
    {
      value: 'france',
      label: 'France',
    },
    {
      value: 'ileMaurice',
      label: 'ile-Maurice',
    },
    {
      value: 'inde',
      label: 'Inde',
    },
    {
      value: 'LyonFrance',
      label: 'Lyon - France',
    },
  ]
  const callApi = UseCallApi()

  useEffect(() => {
    getCountries(setCountries)
  }, [])

  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='selectLocation' className='form-label required'>
        Nom de l'emplacement source
        </label>
        <div className='row'>
          <div className='col-7'>
            <SearchSelect
              options={selectLocation}
              defaultValue={formik.values.selectLocation}
              name={'source'}
              formik={formik}
            />
          </div>
          <div className='col-3'>
            {' '}
            <Link to={'/add-location'} target='_blank' className='btn btn-primary'>
              Ajouter un nouvel emplacement
            </Link>
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <label htmlFor='selectLocation' className='form-label required'>
        Nom du lieu de destination 
        </label>
          <div >
            <SearchSelect
              options={selectLocation}
              defaultValue={formik.values.selectLocation}
              name={'destination'}
              formik={formik}
            />
        </div>
      </div>
      <div className='row mb-4'>
        <label htmlFor='selectLocation' className='form-label required'>
        Type de véhicule
        </label>
          <div >
            <SearchSelect
              options={[]}
              defaultValue={formik.values.selectLocation}
              name={'Type'}
              formik={formik}
            />
        </div>
      </div>
      <div className='row mb-4'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Entrez le tarif forfaitaire (prix en EUR)"}
              name={'tarifForfaitaire'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
      <div className='row mb-4'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Entrez le tarif Booking.com (prix en EUR)"}
              name={'tarifBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
   
  
  
  
  
    </div>
  )
}

export default Form1
