import {FC, useContext} from 'react'
import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'
import {each} from 'chart.js/dist/helpers/helpers.core'
import {AppContext} from '../../../../../../../AppContext'
type Props = {
  handleDeleteUser?: any
}
const UsersListGrouping: FC<Props> = ({handleDeleteUser}) => {
  const {deleteAlert} = useContext(AppContext)

  const {selected, clearSelected} = useListView()
  //console.log(selected)

  const queryClient = useQueryClient()

  const {query} = useQueryResponse()
  const deleteState = async () => {
    const confirmation = await deleteAlert()

    if (confirmation) {
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index]

        handleDeleteUser(element)
      }
      clearSelected()
    }
  }
  // const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     clearSelected()
  //   },
  // })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button type='button' className='btn btn-danger' onClick={deleteState}>
        Delete Selected
      </button>
      {/* <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button> */}
    </div>
  )
}

export {UsersListGrouping}
