import axios, {InternalAxiosRequestConfig} from 'axios'
import {interceptors} from '../../modules/auth'
// import { translateTextv2 } from './TranslateTextv2';
export const TranslateText = async (targetLanguage, inputText) => {
  const apiKey = 'AIzaSyBJcX-nbwLgOR_ATqhdzz_Q7wF9RSXXsak';
  const apiUrl = 'https://translation.googleapis.com/language/translate/v2';
  const headers = {
    'Content-Type': 'application/json',
  };
  // translateTextv2 ('Hello, world!', 'en', 'es');
  const requestBody = {
    q: inputText,
    source: 'en',
    target: targetLanguage,
  };
  axios .interceptors.request.use((config) => {
    axios.interceptors.request.eject(interceptors['helper']);
    delete config.headers.Authorization;
    return config;
  });

  try {
    const response = await axios.post(apiUrl, requestBody, {
      params: {
        key: apiKey,
      },
      headers: {
        ...headers,
        Authorization: null,
      },
    });

    const translatedText = response.data.data.translations[0].translatedText;
    return translatedText;
  } catch (error) {
    console.error('Error:', error);
    throw error; // rethrow the error to propagate it to the caller
  }
};
