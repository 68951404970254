// MapComponentV2.js

import React, { useEffect, useState } from 'react';
import Autocomplete from './Autocomplete';
import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { ApiTaxBooking } from '../../../../_metronic/ApiTax.booking';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 48.866667,
  lng: 2.333333,
};

const MapComponentV2 = () => {
  const [origin, setOrigin] = useState<any>({ lat: 48.8566, lng: 2.3522 });
  const [destination, setDestination] = useState<any>({ lat: null, lng: null });
  const [directions, setDirections] = useState<any | null>(null);
  const [distance, setDistance] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const { placesService } = usePlacesService({
    apiKey: process.env.REACT_APP_KEY_GOOGLE_MAP,
  });
  const [retry, setRetry] = useState(0);
  useEffect(() => {
    let to: any = 0;
    if (!placesService) {
      to = setTimeout(() => {
        setRetry(retry + 1);
      }, 300);
    }

    return () => clearTimeout(to);
  }, [placesService, retry]);

  useEffect(() => {
    if (origin.lat && origin.lng && destination.lat && destination.lng) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: new window.google.maps.LatLng(origin.lat, origin.lng),
          destination: new window.google.maps.LatLng(
            destination.lat,
            destination.lng
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result:any, status:any) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);

            // Extract and display distance and duration
            const route = result.routes[0].legs[0];
            setDistance(route.distance.text);
            setDuration(route.duration.text);
          } else {
            console.error('Error fetching directions:', status);
          }
        }
      );
    }
  }, [origin, destination]);

  if (!placesService) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <button className='btn btn-primary'onClick={(e)=> ApiTaxBooking("2023-12-13T00%3A00%3A00") } >Click</button> */}
      <div className='row position-relative'>
        <div className='col'>
          <Autocomplete setres={setOrigin} placeHolder='Origin' />
        </div>
        <div className='col'>
          <Autocomplete setres={setDestination} placeHolder='Destination' />
        </div>
      </div>

      <div className='d-flex mb-3'></div>
      <div>
      <div>
          {directions  && (
            <div>
              <p>Distance: {distance}</p>
              <p>Duration: {duration}</p>
            </div>
          )}
        </div>
        <GoogleMap mapContainerStyle={mapContainerStyle} center={origin} zoom={8}>
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapComponentV2;
