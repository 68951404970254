import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../../_metronic/CallApi'
import DirectMaps from './DirectMaps'
import MapComponent from '../../../modules/apps/Partial/MapComponent'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
import {getDrivers} from '../../../../_metronic/helpers/Requests/Requests'
import MapComponentV2 from '../../../modules/apps/Partial/MapComponent-v2'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
  destination?: any
  setDestination?: any
  origin?: any
  setOrigin?: any
  destinationLatLong?: any
  setDestinationLatLong?: any
  originLatLong?: any
  setOriginLatLong?: any
}

const Form1: FC<Props> = ({
  formik,
  isUserLoading,
  setImage,
  image,
  setFiles,
  filess,
  destination,
  setDestination,
  origin,
  setOrigin,
  destinationLatLong,
  setDestinationLatLong,
  originLatLong,
  setOriginLatLong,
}) => {
  // const [image, setImage] = useState<File | any>([])
  const [passagers, setPassagers] = useState<any>([])
  const [drivers, setDrivers] = useState<any>([])
  const [states, setState] = useState<any>([])
  const [distance, setDistance] = useState<any>()
  const [duration, setDuration] = useState<any>()
  const [travelTime, setTravelTime] = useState<any>()

  const [idCountries, setIdCountries] = useState<any>('null')
  const callApi = UseCallApi()
  const getPassager = async () => {
    try {
      const data = await callApi({
        route: `passagers.json`,
        method: 'GET',
      })

      const modifiedData = data.map((item) => ({
        ...item,
        label: item?.user?.firstname + ' ' + item?.user?.lastname,
        value: item.user.id,
      }))

      setPassagers(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  // const getDrivers = async () => {
  //   try {
  //     const data = await callApi({
  //       route: `drivers`,
  //       method: 'GET',
  //     })

  //     const modifiedData = data.map((item) => ({
  //       ...item,
  //       label: item.name,
  //       value: item.id,
  //     }))

  //     setDrivers(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching product data:', error)
  //   }
  // }
  // const getStates = async (id) => {
  //   try {
  //     const data = await callApi({
  //       route: `countries/${id}`,
  //       method: 'GET',
  //     })

  //     const modifiedData = data.states.map((item) => ({
  //       ...item,
  //       label: item.name,
  //       value: item.id,
  //     }))

  //     setState(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching product data:', error)
  //   }
  // }
  // useEffect(() => {
  //   if (formik.getFieldProps('country').value) {
  //     getStates(formik.getFieldProps('country').value)
  //   } else {
  //     setState([])
  //   }
  // }, [idCountries, formik.getFieldProps('country').value])
  useEffect(() => {
    getDrivers(setDrivers)
    getPassager()
  }, [])

  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='country' className='form-label'>
          Passagers
        </label>
        <SearchSelect
          options={passagers}
          defaultValue={formik.values.passager}
          name={'passager'}
          formik={formik}
        />
        {/* <select
          name='passager'
          id='passager'
          className='form-select'
          value={formik.values.passager}
          onChange={(event) => {
            formik.setFieldValue('passager', event.target.value)
            setIdCountries(event.target.value)
          }}
        >
          <option value={'null'}>none</option>
          {passagers &&
            passagers.map((option, index) => (
              <option key={option?.id} value={option?.user?.id}>
                {`${option?.lastname}-${option?.phone}`}
              </option>
            ))}
        </select> */}
      </div>
      <div className='row mb-4'>
        <label htmlFor='driver' className='form-label'>
          Chauffeurs
        </label>
        <SearchSelect
          options={drivers}
          defaultValue={formik.values.driver}
          name={'driver'}
          formik={formik}
        />
        {/* <select
          name='driver'
          id='driver'
          className='form-select'
          value={formik.values.driver}
          onChange={(event) => {
            formik.setFieldValue('driver', event.target.value)
            setIdCountries(event.target.value)
          }}
        >
          <option value={'null'}>none</option>
          {drivers &&
            drivers.map((option, index) => (
              <option key={option?.id} value={option?.user?.id}>
                {`${option?.fulname}-${option?.tel}`}
              </option>
            ))}
        </select> */}
      </div>

      <div className='row'>
      <MapComponentV2/>
        {/* <MapComponent
          setDuration={setDuration}
          duration={duration}
          destination={destination}
          setDistance={setDistance}
          distance={distance}
          setDestination={setDestination}
          origin={origin}
          setOrigin={setOrigin}
          destinationLatLong={destinationLatLong}
          setDestinationLatLong={setDestinationLatLong}
          originLatLong={originLatLong}
          setOriginLatLong={setOriginLatLong}
        /> */}
        {/* <DirectMaps setDistance={setDistance} setTravelTime={setTravelTime} readOnly={false} /> */}
      </div>
      <div className='row mt-3'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>numéro de vol</label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='date drive
          '
          {...formik.getFieldProps('date')}
          type='datetime-local'
          name='dateTrip'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.dateTrip && formik.errors.dateTrip},
            {
              'is-valid': formik.touched.dateTrip && !formik.errors.dateTrip,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.dateTrip && formik.errors.dateTrip && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.dateTrip}</span>
            </div>
          </div>
        )}
      </div>
      <div className='row mt-3'>
        {/* begin::Label */}
        <label className='required fw-bold fs-6 mb-2'>numéro de vol</label>
        {/* end::Label */}

        {/* begin::Input */}
        <input
          placeholder='numéro de vol
          '
          {...formik.getFieldProps('numVol')}
          type='text'
          name='numVol'
          className={clsx(
            'form-control form-control-solid mb-3 mb-lg-0',
            {'is-invalid': formik.touched.numVol && formik.errors.numVol},
            {
              'is-valid': formik.touched.numVol && !formik.errors.numVol,
            }
          )}
          autoComplete='off'
          disabled={formik.isSubmitting || isUserLoading}
        />
        {formik.touched.numVol && formik.errors.numVol && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.numVol}</span>
            </div>
          </div>
        )}
      </div>

      {/* <div className='row mb-7'>
        <label className='required fw-bold fs-6 mb-2'>Status </label>
        
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className={clsx(
              'form-check-input  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.status && formik.errors.status},
              {
                'is-valid': formik.touched.status && !formik.errors.status,
              }
            )}
            {...formik.getFieldProps('status')}
            autoComplete='off'
            disabled={formik.isSubmitting || isUserLoading}
            type='checkbox'
            name='status'
            id='flexSwitchDefault'
          />
        </div>
        {formik.touched.status && formik.errors.status && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.status}</span>
            </div>
          </div>
        )}
       end::Input 
      </div> */}
    </div>
  )
}

export default Form1
