import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const EmailSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'E-mail du nom'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'emailFromName'}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Pas de réponse par e-mail'}
            type={'email'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'noReplyEmail'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"E-mail de l'administrateur"}
            type={'email'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'adminEmail'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du courrier'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'mailFooter'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Identifiant utilisateur EmailGun'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'emailGunUserId'}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'Clé utilisateur EmailGun'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'emailGunUserKey'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Hôte de EmailGun'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'emailGunHost'}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='enableMailgun' className='required form-label'>
            Activer Mailgun
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableMailgun}
            name={'enableMailgun'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Clé de l'application Statistiques de vol"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'flightStatsAppKey'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Identifiant de l'application de statistiques de vol"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'flightStatsAppId'}
          />
        </div>
      </div>
    </div>
  )
}

export default EmailSeting
