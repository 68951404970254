import React from 'react'
import { UseCallApi } from '../../../../_metronic/CallApi'

type Props = {
  isModalOpen?: boolean
  closeModal?: any
  id?: any
  lang?:any
  updateUserStatus?: any
}

const DateSupendu: React.FC<Props> = ({isModalOpen, closeModal, id, updateUserStatus,lang}) => {
  const [dateDebut, setDateDebut] = React.useState('')
  const [dateFin, setDateFin] = React.useState('')
  const handleDateChange = (event) => {
    const {name, value} = event.target
    if (name === 'dateDebut') {
      setDateDebut(value)
    } else if (name === 'dateFin') {
      setDateFin(value)
    }
  }
  const UpdateStatusDrivers = async (id, status) => {
    try {
      const data = await UseCallApi ()({
        route: `updatestatus/${id}`,
        // formData:true,
        body: {status: Number(status),
          dateDebut:dateDebut?dateDebut:null,
          dateFin:dateFin?dateFin:null,
          // lang:`languages/2`
        },
        method: 'PUT',
      })

      return data
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const valider = () => {
    updateUserStatus(id, '3')
    UpdateStatusDrivers(id,"3")
    closeModal()
  }

  if (!isModalOpen) {
    return null
  }

  return (
    <div className='modal' style={{display: 'block'}}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>période de supension</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closeModal}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='container mt-5'>
              <div className='form-group row'>
                <label htmlFor='dateDebut' className='col-form-label col-md-3 text-start'>
                  Date de début:
                </label>
                <div className='col-md-9'>
                  <input
                    type='date'
                    className='form-control'
                    id='dateDebut'
                    name='dateDebut'
                    value={dateDebut}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label htmlFor='dateFin' className='col-form-label col-md-3 text-start'>
                  Date de fin:
                </label>
                <div className='col-md-9'>
                  <input
                    type='date'
                    className='form-control'
                    id='dateFin'
                    name='dateFin'
                    value={dateFin}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={closeModal}
            >
              Close
            </button>
            <button type='button' onClick={valider} className='btn btn-primary'>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateSupendu
