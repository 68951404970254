import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {useFormik} from 'formik'

import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'
import Form1 from './Form1'
import {PageTitle} from '../../../_metronic/layout/core'

const editUserSchema = Yup.object().shape({
  lastname: Yup.string().required('Le prénom est requis'),
  firstname: Yup.string().required('Le nom est requis'),
  typeAccount: Yup.string().required('Le groupe est requis'),
  email: Yup.string().required("L'email est requis").email('Format email invalide'),
  password: Yup.string()
    .required('Le mote de passe est requis')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.'
    ),
})
const AddPartner = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading

  const callApi = UseCallApi()

  // get driver when update
  const getUser = async (id) => {
    try {
      const data = await callApi({
        route: `users/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getUser(id)
    }
  }, [id])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: updateData?.firstname,
      lastname: updateData?.lastname,
      email: updateData?.email,
      password: updateData.password,
      typeAccount: updateData?.role?.id,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const postData = {
        firstname: values.firstname,
        lastname: values.lastname,
        typeAccount: 'Admin',
        email: values.email,
        password: values.password,
        role: `roles/${values.typeAccount}`,
      }
      try {
        const post = async () => {
          setIsLoading(true)
          try {
            const data = await callApi({
              route:
                location.pathname === '/admin-users-management/add'
                  ? 'users'
                  : `users/${id}`,
              method:  location.pathname === '/admin-users-management/add'?'POST':"PUT",
              body: postData,
              formData: location.pathname === '/admin-users-management/add' ? true : false,
            })
            if (data) {
              setIsLoading(false)
              successToas('success')
              navigate('/admin-users-management')
            }
          } catch (error: any) {
            setIsLoading(false)
            errorToas(error.response['data'].detail)
          }
        }
        post()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  //console.log(updateData)

  return (
    <>
      <HeaderWrapper linkss={[]} />
      <PageTitle>
        {location.pathname === '/partner-management/add'
          ? 'Ajouter partenaire'
          : `Modifier partenaire: ${
              updateData && updateData.firstname ? updateData.firstname : ''
            } ${updateData && updateData.lastname ? updateData.lastname : ''}`}
      </PageTitle>

      <Form1 formik={formik} isUserLoading={isUserLoading} />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {
          <>
            {isLoading ? (
              <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e: any) => formik.handleSubmit(e)}
              >
                Sauvegarder
              </button>
            )}
          </>
        }
      </div>
    </>
  )
}

export default AddPartner
