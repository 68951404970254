import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const SmsSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={'SID du compte Twilio'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'twilioAccountSid'}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Jeton d'authentification de compte Twilio"}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'twilioAccountAuthenticationToken'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Numéro de mobile Twilio (avec le signe plus comme préfixe)'}
            type={'url'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'twilioMobileNumberWithPlusSignAsPrefix'}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <label htmlFor='enableProviderPhoneVerification' className='required form-label'>
            Activer la vérification du téléphone du fournisseur{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableProviderPhoneVerification}
            name={'enableProviderPhoneVerification'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='enableUserPhoneVerification' className='required form-label'>
            Activer la vérification du téléphone de l'utilisateur{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableUserPhoneVerification}
            name={'enableUserPhoneVerification'}
            formik={formik}
          />
        </div>
        <div className='row mb-4'>
          <label htmlFor='enableLaterBookingSms' className='required form-label'>
            Activer les SMS de réservation ultérieure{' '}
          </label>
          <SearchSelect
            options={[
              {label: 'oui', value: 1},
              {label: 'non', value: 0},
            ]}
            defaultValue={formik.values.enableLaterBookingSms}
            name={'enableLaterBookingSms'}
            formik={formik}
          />
        </div>
      </div>
    </div>
  )
}

export default SmsSeting
