import * as React from 'react'
import {User} from '../../core/_models'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {UserInfoCell} from './UserInfoCell'
import {UseCallApi} from '../../../../../../../_metronic/CallApi'
import {PassagierInfo} from '../../../../user-management/users-list/table/columns/Drive/PassagierInfo'
import {AppContext} from '../../../../../../../AppContext'
import { UsersListSearchComponent } from '../../../../Admin/Admin-users/adminUsers-list/components/header/UsersListSearchComponent'

type Props = {
  id?: any
  query?: any
}

const ModalManager: React.FC<Props> = ({id, query}) => {
  const [idDriver, setIdDriver] = React.useState<number[]>([])
  const {errorToas, successToas} = React.useContext(AppContext)

  const [headerFilter, setHeaderFilter] = React.useState({
    name: '',
    status: '',
    countries: '',
  })
  const [data, setData] = React.useState<User[]>([])
  const setDriver = (id) => {
    setIdDriver([...idDriver, id])
  }
  const getUsers = async () => {
    try {
      const data = await UseCallApi()({
        route: `users`,
        method: 'GET',
        jsonLd: true,
      })
      //console.log(data['hydra:member'])
      setData(data['hydra:member'])
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  React.useEffect(() => {
    getUsers()
  }, [headerFilter])

  const senData = async (my) => {
    try {
      const data = await UseCallApi()({
        route: `${query}`,
        method: 'POST',
        body: {
          company: `companies/${id}`,
          driver: `drivers/${my}`,
        },
      })
      successToas('success')
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  const valider = () => {
    for (let index = 0; index < idDriver.length; index++) {
      senData(idDriver[index])
    }
  }
  return (
    <div>
      <button
        type='button'
        className='btn btn-light-primary btn-sm'
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_1${id}`}
      >
        {' '}
        <i className='fa-solid fa-list-check pe-3'></i>
        <span>Affecter gestionnaire</span>
      </button>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_1${id}`}>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>sélectionner un gestionnare</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='mb-1 container'>
              <UsersListSearchComponent
                headerFilter={headerFilter}
                setHeaderFilter={setHeaderFilter}
              />
            </div>
            <div
              className='modal-body d-blcok mt-0 pt-0'
              style={{maxHeight: '70vh', overflowY: 'scroll'}}
            >
              <div className='table-responsive'>
                <table className='table gs-7 gy-7 gx-7 table-hover'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                      <th>Chauffeur</th>
                      <th>Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((user: any, index: number) => (
                        <tr key={user?.id}>
                          <td>
                            <PassagierInfo user={user} />
                          </td>
                          <td>{user?.code}</td>
                          <td>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={user.id}
                              onClick={() => setDriver(user.id)}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>{' '}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Fermer
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={valider}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalManager
