/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {BsFillPencilFill, BsFillTrash3Fill} from 'react-icons/bs'
import {Link} from 'react-router-dom'

type Props = {
  id: ID
  editUser?: any
}

const ActionCelellDisponible: FC<Props> = ({id, editUser}) => {
  return (
    <div className='text-start'>
      <Link to={`/detail-drive`} className='btn btn-icon btn-light-primary btn-sm  text-start'>
        <i className='bi bi-eye-fill fs-4'></i>
      </Link>
    </div>
  )
}

export {ActionCelellDisponible}
