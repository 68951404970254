import React, {useEffect, useRef, useState} from 'react'

interface MapProps {
  origin: google.maps.LatLngLiteral
}

const SimpleMaps: React.FC<MapProps> = ({origin}) => {
  const mapRef = useRef<HTMLDivElement | null>(null)
  const polylineRef = useRef<google.maps.Polyline | null>(null)
  const [apiLoaded, setApiLoaded] = useState<boolean>(false)
  const key = 'AIzaSyC-htfZe2EqE6ULV3sdwpUDhJ1X7Deptzg'

  useEffect(() => {
    if (!apiLoaded) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}`
      script.async = true
      script.defer = true
      document.head.appendChild(script)

      script.addEventListener('load', () => {
        setApiLoaded(true)
      })

      return () => {
        script.removeEventListener('load', () => {})
      }
    }
  }, [apiLoaded])

  useEffect(() => {
    if (apiLoaded) {
      const directionsRenderer = new google.maps.DirectionsRenderer()
      const map = new google.maps.Map(mapRef.current!, {
        center: origin,
        zoom: 10,
      })

      directionsRenderer.setMap(map)

      polylineRef.current = new google.maps.Polyline({
        map,
        strokeColor: '#2196F3',
        strokeOpacity: 1,
        strokeWeight: 5,
      })

      // Create a custom badge div for the origin
      const originBadgeDiv = document.createElement('div')
      originBadgeDiv.className = 'map-badge origin-badge'
      originBadgeDiv.textContent = 'Origin'
      const originMarker = new google.maps.Marker({
        position: origin,
        map,
        icon: {
          url: '/media/icons/duotune/maps/car.svg',
          scaledSize: new google.maps.Size(20, 20), // Adjust the size as needed
        },
      })
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(originBadgeDiv) // Position badge at the top-center of the map
    }
  }, [apiLoaded, origin])

  return (
    <div>
      {/* Map container */}
      <div ref={mapRef} style={{width: '100%', height: '70vh'}} />
    </div>
  )
}

export default SimpleMaps
