/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import HearthCheckBox from '../../../../customs/checkBox/hearthCheckBox'
import {Link} from 'react-router-dom'
import {UserStatu} from '../../../../tables/colmuns/UserStatu'
import {AiOutlineUser} from "react-icons/ai"

type Props = {
  user: User
}
const UserInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center position-relative'>
      {/* begin:: Avatar */}
      <div style={{position: 'absolute', top: '-12px', right: '-10px'}}>
        <HearthCheckBox query='drivers' id={user.id} checked={user.favorie} />
      </div>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Link to={`/driver/${user?.id}`}>
          {user && user.user && user.user.photo ? (
            <div className='symbol-label'>
              {/* <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' /> */}
              <img
                src={`${process.env.REACT_APP_API_URL}/${user?.user?.photo}`}
                alt={user.name}
                className='w-100'
              />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${user.initials?.state}`,
                `text-${user.initials?.state}`
              )}
            >
            <AiOutlineUser/>
            </div>
          )}
        </Link>
      </div>
      <div className='d-flex flex-column'>
        <Link to={`/driver/${user.id}`} className='text-gray-800 text-hover-primary mb-1'>
          {user.name}
        </Link>
        <span>{user.email}</span>
      </div>
      <div style={{position: 'absolute', top: '-15px', left: '-12px'}}>
        <UserStatu statu={user.status} />
      </div>
    </div>
  )
}
export {UserInfoCell}
