import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const PaymentSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='row mb-4'>
        <label htmlFor={'Data[SYSTEM_PAYMENT_ENVIRONMENT]'} className='required form-label'>
          Environnement de paiement d'application
        </label>{' '}
        <SearchSelect
          options={[
            {label: 'Test', value: 'Test'},
            {label: 'En direct', value: 'Live'},
          ]}
          name={'Data[SYSTEM_PAYMENT_ENVIRONMENT]'}
          formik={formik}
        />
      </div>

      <div className='row mb-4'>
        <label htmlFor={'Data[APP_PAYMENT_MODE]'} className='required form-label'>
          Mode de paiement
        </label>{' '}
        <SearchSelect
          options={[
            {label: 'Espèces', value: 'Cash'},
            {label: 'Carte', value: 'Card'},
            {label: 'Carte de paiement', value: 'Cash-Card'},
          ]}
          name={'Data[APP_PAYMENT_MODE]'}
          formik={formik}
        />
      </div>

      <div className='row mb-4'>
        <label htmlFor={'Data[APP_PAYMENT_METHOD]'} className='required form-label'>
          Méthode de paiement de l'application
        </label>{' '}
        <SearchSelect
          options={[{label: 'Bande', value: 'Stripe'}]}
          name={'Data[APP_PAYMENT_METHOD]'}
          formik={formik}
        />
      </div>

      <div className='row mb-4'>
        <label htmlFor={'Data[COMMISION_DEDUCT_ENABLE]'} className='required form-label'>
          Permettre à la commission du fournisseur d'être déduite de son portefeuille pour les
          courses en espèces ("Oui" OU "Non")
        </label>{' '}
        <SearchSelect
          options={[
            {label: 'Oui', value: 'Yes'},
            {label: 'Non', value: 'No'},
          ]}
          name={'Data[COMMISION_DEDUCT_ENABLE]'}
          formik={formik}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={'Montant minimum requis dans le portefeuille'}
          type={'number'}
          name={'Data[WALLET_MIN_BALANCE]'}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={"Clé d'éditeur Stripe pour le mode Sandbox"}
          type={'text'}
          name={'Data[STRIPE_PUBLISH_KEY_SANDBOX]'}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={"Clé d'éditeur Stripe pour le mode Live"}
          type={'text'}
          name={'Data[STRIPE_PUBLISH_KEY_LIVE]'}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={'Clé secrète Stripe pour le mode Sandbox'}
          type={'text'}
          name={'Data[STRIPE_SECRET_KEY_SANDBOX]'}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={'Clé secrète Stripe pour le mode Live'}
          type={'text'}
          name={'Data[STRIPE_SECRET_KEY_LIVE]'}
          formik={formik}
          isUserLoading={isUserLoading}
        />
      </div>
    </div>
  )
}

export default PaymentSeting
