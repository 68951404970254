export const generatePromoCode = (set) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // You can modify this charset as needed.
    let promoCode = '';
  
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      promoCode += charset[randomIndex];
    }
  
    set(promoCode);
  };
  