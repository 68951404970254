import {ChangeEvent, FC, useState, useEffect, createRef} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import LabelInput from '../../../_metronic/partials/LabelInput'
import {generatePromoCode} from '../../../_metronic/helpers/functions/generatePromoCode'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelRadio from '../../../_metronic/partials/labelRadio'
import { TranslateText } from '../TranslateText/TranslateText'

type Props = {
  isUserLoading: boolean
  formik?: any
  languages: any
}

const Form1: FC<Props> = ({formik, isUserLoading, languages}) => {
  const callApi = UseCallApi()
  const [promoCode, setPromoCode] = useState()
  const curencies = [{value: 'euro', label: 'Montant forfaitaire (en EUR)'}]
  useEffect(() => {
    const translateText = async (key, text) => {
      return await TranslateText (key, text)
    }

    const inputText = formik.getFieldProps(`descriptionenglish`).value

    if (inputText && inputText.length > 0) {
      languages.forEach(async (item) => {
        if (item.key !== 'en') {
          const translatedText = await translateText(item.key, inputText)
          formik.setFieldValue(`description${item.code}`, translatedText)
        }
      })
    }
  }, [formik.getFieldProps(`descriptionenglish`).value])
  const languageInputs = languages.map((language) => (
    <div key={language.code}>
      <div className="row mb-4">
      <LabelInput
          label={`Description ${language.label}`}
          type={'string'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={`description${language.code}`}
          placeholder={`${language.label} Sujet`}
        />
    
      </div>
      
    </div>
  ))

  // useEffect(() => {
  //   const rowKey = uuidv4() // Generate a unique key for the row

  //   const newPriCeInHoure: any = []

  //   for (let index = 0; index < NumberRow; index++) {
  //     newPriCeInHoure.push(
  //       <div className='d-flex flex-center gap-3 mb-4' key={rowKey}>
  //         <div>
  //           <input
  //             type='number'
  //             name={`price-${index}-1`}
  //             className={clsx(
  //               'form-control ',
  //               {
  //                 'is-invalid':
  //                   formik.touched[`price-${index}-1`] && formik.errors[`price-${index}-1`],
  //               },
  //               {
  //                 'is-valid':
  //                   formik.touched[`price-${index}-1`] && !formik.errors[`price-${index}-1`],
  //               }
  //             )}
  //             placeholder='Prix'
  //             {...formik.getFieldProps(`price-${index}-1`)}
  //           />
  //           {formik.touched[`price-${index}-1`] && formik.errors[`price-${index}-1`] && (
  //             <div className='fv-plugins-message-container'>
  //               <div className='fv-help-block'>
  //                 <span role='alert'>{formik.errors[`price-${index}-1`]}</span>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //         -
  //         <div>
  //           <input
  //             type='number'
  //             name={`price-${index}-2`}
  //             className={clsx(
  //               'form-control ',
  //               {
  //                 'is-invalid':
  //                   formik.touched[`price-${index}-2`] && formik.errors[`price-${index}-2`],
  //               },
  //               {
  //                 'is-valid':
  //                   formik.touched[`price-${index}-2`] && !formik.errors[`price-${index}-2`],
  //               }
  //             )}
  //             placeholder='Prix'
  //             {...formik.getFieldProps(`price-${index}-2`)}
  //           />
  //           {formik.touched[`price-${index}-2`] && formik.errors[`price-${index}-2`] && (
  //             <div className='fv-plugins-message-container'>
  //               <div className='fv-help-block'>
  //                 <span role='alert'>{formik.errors[`price-${index}-2`]}</span>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //         €
  //         <div>
  //           <input
  //             type='number'
  //             step={0.1}
  //             name={`price-${index}-3`}
  //             className={clsx(
  //               'form-control ',
  //               {
  //                 'is-invalid':
  //                   formik.touched[`price-${index}-3`] && formik.errors[`price-${index}-3`],
  //               },
  //               {
  //                 'is-valid':
  //                   formik.touched[`price-${index}-3`] && !formik.errors[`price-${index}-3`],
  //               }
  //             )}
  //             placeholder='Prix'
  //             {...formik.getFieldProps(`price-${index}-3`)}
  //           />
  //           {formik.touched[`price-${index}-3`] && formik.errors[`price-${index}-3`] && (
  //             <div className='fv-plugins-message-container'>
  //               <div className='fv-help-block'>
  //                 <span role='alert'>{formik.errors[`price-${index}-3`]}</span>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //         <button
  //           className='btn btn-icon btn-light-danger btn-sm'
  //           // onClick={() => removeRow(rowKey)}
  //         >
  //           <i className='ki-duotone ki-cross-circle fs-2'>
  //             <span className='path1'></span>
  //             <span className='path2'></span>
  //             <span className='path3'></span>
  //           </i>
  //         </button>
  //       </div>
  //     )
  //   }
  //   setPriCeInHoure(newPriCeInHoure)
  // }, [NumberRow])
  const Genrate = () => {
    generatePromoCode(setPromoCode)
  }
  useEffect(() => {
    formik.setFieldValue('PromoCode', promoCode)
  }, [promoCode])
  return (
    <div>
      <div className='row mb-7 align-items-end'>
        <div className='col'>
          <LabelInput
            type={'textarea'}
            formik={formik}
            label={'Code Promo'}
            name={'PromoCode'}
            isUserLoading={isUserLoading}
            placeholder={'Code Promo'}
          />
        </div>
        <div className='col'>
          <button className='btn btn-primary w-75' onClick={() => Genrate()}>
            {' '}
            Générer un code promo
          </button>
        </div>
      </div>
      <div className='row mb-4 border'>{languageInputs}</div>
      <div className='row mb-7'>
        <div className='col'>
          <LabelInput
            type={'number'}
            formik={formik}
            label={'Remise'}
            name={'remise'}
            isUserLoading={isUserLoading}
            placeholder={0}
          />
        </div>
        <div className='col'>
          <div className='row mb-4'>
            <label htmlFor='country' className='form-label required'>
              Catégorie de véhicule/Type d'icône de carte
            </label>
            <SearchSelect
              options={curencies}
              defaultValue={formik.values.commandes}
              name={'commandes'}
              formik={formik}
            />
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <label htmlFor=''>Validité</label>
        <div className='d-flex mt-5'>
          <LabelRadio
            type={'radio'}
            label={'Permanent'}
            val={'Permanent'}
            formik={formik}
            name={'validite'}
            isUserLoading={isUserLoading}
          />
          <LabelRadio
            type={'radio'}
            label={'Coutume'}
            formik={formik}
            val={'Coutume'}
            name={'validite'}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
      {formik.getFieldProps('validite').value == 'Coutume' && (
        <>
          <div className='row mb-4'>
            <LabelInput
              type={'date'}
              formik={formik}
              label={"Date d'activation :"}
              name={'dateD'}
              isUserLoading={isUserLoading}
              placeholder={"Date d'activation"}
            />
          </div>

          <div className='row mb-4'>
            <LabelInput
              type={'date'}
              formik={formik}
              label={"Date d'expiration : :"}
              name={'dateF'}
              isUserLoading={isUserLoading}
              placeholder={"Date d'expiration"}
            />
          </div>
        </>
      )}
      <div className='col'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={"Limite d'utilisation"}
          name={'limite'}
          isUserLoading={isUserLoading}
          placeholder={0}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor=''>Statut</label>
        <div className='d-flex mt-5'>
          <LabelRadio
            type={'radio'}
            label={'Actif'}
            val={'true'}
            formik={formik}
            name={'statu'}
            isUserLoading={isUserLoading}
          />
          <LabelRadio
            type={'radio'}
            label={'Inactif'}
            formik={formik}
            val={'false'}
            name={'statu'}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default Form1
