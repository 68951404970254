import {ChangeEvent, FC, useState, useEffect} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelInput from '../../../_metronic/partials/LabelInput'
import {getCompanies, getDrivers} from '../../../_metronic/helpers/Requests/Requests'
import LabelSwitch from '../../../_metronic/partials/LabelSwitch'

type Props = {
  isUserLoading: boolean
  formik?: any
  image?: any
  setImage?: any
  setFiles?: any
  filess?: any
}
interface YearObject {
  value: string
  label: string
}
const Form1: FC<Props> = ({formik, isUserLoading, setImage, image, setFiles, filess}) => {
  // const [image, setImage] = useState<File | any>([])
  const [brands, setBrands] = useState<any>([])
  const [yearsArray, setYearsArray] = useState<YearObject[]>([])
  const [drivers, setDrivers] = useState<any>([])
  const [companies, setCompanies] = useState<any>([])
  const type = [
    {
      value: '12',
      label: 'Classe Affaires (trajet)',
    },
    {
      value: '13',
      label: 'ECO (trajet)',
    },
    {
      value: '14',
      label: 'Première classe (trajet)',
    },
  ]
  const callApi = UseCallApi()
  const getBrands = async () => {
    try {
      const data = await callApi({
        route: `brands.json`,
        method: 'GET',
      })

      const modifiedData = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))

      setBrands(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getBrands()
    getDrivers(setDrivers)
    getCompanies(setCompanies)
  }, [])
  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const years: YearObject[] = []

    for (let year = 1979; year <= currentYear; year++) {
      years.unshift({value: year.toString(), label: year.toString()})
    }

    setYearsArray(years)
  }, [])

  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='brand' className='form-label'>
          Marque
        </label>
        <SearchSelect
          options={brands}
          defaultValue={formik.values.brand}
          name={'brand'}
          formik={formik}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='brand' className='form-label'>
          Modèle
        </label>
        <SearchSelect
          options={brands}
          defaultValue={formik.values.model}
          name={'model'}
          formik={formik}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='year' className='form-label'>
          Année
        </label>
        <SearchSelect
          options={yearsArray}
          defaultValue={formik.values.year}
          name={'year'}
          formik={formik}
        />
      </div>

      <div className='row mb-4'>
        <LabelInput
          label={"Plaque d'immatriculation"}
          type={'string'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'immatriculation'}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='company' className='form-label'>
          Entreprise
        </label>
        <SearchSelect options={companies} name={'company'} formik={formik} />
      </div>
      <div className='row mb-4'>
        <label htmlFor='drivers' className='form-label'>
          Chauffeur
        </label>
        <SearchSelect options={drivers} name={'drivers'} formik={formik} />
      </div>
      <div className=' w-25 mb-4'>
        <LabelInput
          label={'Color'}
          type={'color'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'color'}
        />
      </div>
      <div className='row mb-4'>
        <LabelSwitch
          type={'checkbox'}
          label={'Siège enfant disponible ?'}
          formik={formik}
          name={'status'}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='' className='form-label required mb-4'>
          Type de taxi{' '}
        </label>
        {type.map((item: any, index: number) => (
          <div className='mb-10 border p-3' key={item.value}>
            <LabelSwitch
              flex={true}
              type={'checkbox'}
              label={item.label}
              formik={formik}
              name={item.value}
              isUserLoading={isUserLoading}
            />
            {formik.getFieldProps(`${item.value}`).value && (
              <div className='px-6'>
                <LabelSwitch
                  flex={true}
                  type={'checkbox'}
                  label={
                    'Accepter la demande de location pour le type de véhicule de classe Bussines ?'
                  }
                  formik={formik}
                  name={'check' + item.value}
                  isUserLoading={isUserLoading}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Form1
