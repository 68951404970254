import {FC, useState, useEffect, useContext} from 'react'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelInput from '../../../_metronic/partials/LabelInput'
import TypeAcount from '../../../_metronic/assets/json/TypeAcount.json'
import { AppContext } from '../../../AppContext'

type Props = {
  isUserLoading: boolean
  formik?: any
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  const [states, setState] = useState<any>([])
  const [companies,SetCompanies]=useState([])
  const {errorToast}=useContext(AppContext)
  const callApi = UseCallApi()
  
  const getRoles = async () => {
    try {
      const data = await callApi({
        route: `roles.json`,
        method: 'GET',
      })

      const modifiedData = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))

      setState(modifiedData)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getRoles()
  }, [])
  const getCompanies = async (id) => {
    try {
      const data = await callApi({
        route: `companies?type=${id}`,
        method: 'GET',
      })
      const modifiedData = data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      SetCompanies(modifiedData)
    } catch (error: any) {
      errorToast (error.response['data'].detail)
    }
  }
  useEffect(() => {
    if(formik.values.type&&formik.values.type!="null"){

      getCompanies(formik.values.type)
    }
    else{
      SetCompanies([])

    }
  }, [formik.values.type])

  return (
    <div>
    
      <div className='row mb-4'>
        <label htmlFor='state' className='form-label'>
          Type
        </label>
        <SearchSelect
          options={TypeAcount}
          defaultValue={formik.values.type}
          name={'type'}
          formik={formik}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='state' className='form-label'>
          Role
        </label>
        <SearchSelect
          options={states}
          defaultValue={formik.values.typeAccount}
          name={'typeAccount'}
          formik={formik}
        />
      </div>
      
      <div className='row mb-7 '>
        <LabelInput
          type='string'
          formik={formik}
          label='Nom'
          placeholder='nom'
          name='lastname'
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
        <LabelInput
          type='string'
          formik={formik}
          label='Prénom'
          placeholder='Prénom'
          name='firstname'
          defaultValue={formik.values?.firstname}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-7 '>
        <LabelInput
          type='email'
          formik={formik}
          label='Email'
          placeholder='example@gmail.com'
          name='email'
          isUserLoading={isUserLoading}
        />
      </div>
      {formik.values.type &&formik.values.type!="null"&&formik.values.type!="ADMIN"&&
      <div className='row mb-4'>
        <label htmlFor='state' className='form-label'>
          Entreprise
        </label>
        <SearchSelect
          options={companies}
          defaultValue={formik.values.company}
          name={'company'}
          formik={formik}
        />
      </div>
      }
      {/* <div className='row mb-7 '>
        <LabelInput
          type='password'
          formik={formik}
          label='Mot de passe'
          placeholder={'Mote de passe'}
          name='password'
          isUserLoading={isUserLoading}
        />
      </div> */}
    </div>
  )
}

export default Form1
