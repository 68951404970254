import React from 'react'
import HearthCheckBox from '../../../../customs/checkBox/hearthCheckBox'
type Props = {
  user?: any
}
const Info: React.FC<Props> = ({user}) => {
  return (
    <div className='d-flex'>
      <p>{user}</p>
      {/* <div className='ms-3'>
        <HearthCheckBox checked={true} query='countries' id={user.id} />{' '}
      </div> */}
    </div>
  )
}

export default Info
