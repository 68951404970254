import React, {FC} from 'react'
import LabelInput from '../../../../_metronic/partials/LabelInput'
import SearchSelect from '../../../modules/apps/customs/Selects/SearchSelect'
type Props = {
  formik: any
  isUserLoading: any
}
const SocialMediaSeting: FC<Props> = ({formik, isUserLoading}) => {
  return (
    <div className='row'>
      <div className='col'>
        <div className='row mb-4'>
          <LabelInput
            label={"Identifiant d'application Facebook"}
            type={'number'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'facebookApplicationId'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du site Web - Lien Facebook'}
            type={'url'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'webSiteFooterfacebookLink'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du site Web - Lien Twitter'}
            type={'url'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'webSiteFooterTwitterLink'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du site Web - Lien LinkedIn'}
            type={'url'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'webSiteFooterLinkedInLink'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du site Web - Lien Instagram'}
            type={'url'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'webSiteFooterInstagramLink'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={"Nom de l'application Google Plus"}
            type={'name'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'googlePlusAppName'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Identifiant client Oauth Google Plus'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'googlePlusOauthClientId'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Clé secrète du client Google Plus Oauth'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'googlePlusOauthClientSecretKey'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'URL de redirection Oauth de Google Plus'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'googlePlusOauthRedirectUrl'}
          />
        </div>
        <div className='row mb-4'>
          <LabelInput
            label={'Lien vers le site Google Plus'}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={'googlePlusSiteLink'}
          />
        </div>
      </div>
      <div className='col'>
        <div className='row mb-4'>
          <label htmlFor={'Data[PASSENGER_FACEBOOK_LOGIN]'} className='required form-label'>
            Activer la connexion Facebook pour l'utilisateur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[PASSENGER_FACEBOOK_LOGIN]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[DRIVER_GOOGLE_LOGIN]'} className='required form-label'>
            Activer la connexion Google Plus pour le fournisseur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[DRIVER_GOOGLE_LOGIN]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[PASSENGER_GOOGLE_LOGIN]'} className='required form-label'>
            Activer la connexion Google Plus pour l'utilisateur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[PASSENGER_GOOGLE_LOGIN]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[IS_DEST_ANYTIME_CHANGE]'} className='required form-label'>
            La destination peut-elle être modifiée à tout moment depuis l'application utilisateur ?
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[IS_DEST_ANYTIME_CHANGE]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Pied de page du site Web - Lien Google'}
            type={'text'}
            name={'Data[GOOGLE_LINK_FOOTER]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={'Identifiant de candidature LinkedIn'}
            type={'text'}
            name={'Data[LINKEDIN_APP_ID]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[DRIVER_LINKEDIN_LOGIN]'} className='required form-label'>
            Activer la connexion Linkedin pour le fournisseur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[DRIVER_LINKEDIN_LOGIN]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <label htmlFor={'Data[PASSENGER_LINKEDIN_LOGIN]'} className='required form-label'>
            Activer la connexion Linkedin pour l'utilisateur
          </label>{' '}
          <SearchSelect
            options={[
              {label: 'Oui', value: 'Yes'},
              {label: 'Non', value: 'No'},
            ]}
            name={'Data[PASSENGER_LINKEDIN_LOGIN]'}
            formik={formik}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé secrète de l'application Facebook"}
            type={'text'}
            name={'Data[FACEBOOK_APP_SECRET_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>

        <div className='row mb-4'>
          <LabelInput
            label={"Clé secrète de l'application Linkedin"}
            type={'text'}
            name={'Data[LINKEDIN_APP_SECRET_KEY]'}
            formik={formik}
            isUserLoading={isUserLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default SocialMediaSeting
