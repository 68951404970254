import clsx from 'clsx'
import React, {FC} from 'react'
import {BsFillBuildingsFill} from 'react-icons/bs'
type Props = {
  src: any
}
const ImgCell: FC<Props> = ({src}) => {
  return (
    <div>
      {src ? (
        <div className='symbol-label'>
          {/* <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' /> */}
          <img src={src} className='w-100' />
        </div>
      ) : (
        <div className={clsx('symbol-label fs-1', `text-dark`)}>
          <BsFillBuildingsFill />
        </div>
      )}
    </div>
  )
}

export default ImgCell
