/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {Drive} from '../../../core/_models'
import HearthCheckBox from '../../../../../customs/checkBox/hearthCheckBox'
import {Link} from 'react-router-dom'
import {UserStatu} from '../../../../../tables/colmuns/UserStatu'

type Props = {
  user: any
  ref: any
}

const PassagierInfo: FC<Props> = ({user, ref}) => {
  const handleClick = () => {
    ref.current.click()
  }
  return (
    <div className='d-flex align-items-center position-relative'>
      {/* begin:: Avatar */}

      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <Link to={`/driver/${user?.id}`} onClick={handleClick}>
          {user?.photo ? (
            <div className='symbol-label'>
              <img
                src={`${process.env.REACT_APP_API_URL}/${user?.photo}`}
                alt={user.name}
                className='w-100'
              />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${user.initials?.state}`,
                `text-${user.initials?.state}`
              )}
            >
              {user.initials?.label}
            </div>
          )}
        </Link>
      </div>
      <div className='d-flex flex-column'>
        <Link to={`/driver/${user?.id}`} className='text-gray-800 text-hover-primary mb-1'>
          {user?.name}
        </Link>
        <span>{user?.email}</span>
      </div>
      <div style={{position: 'absolute', top: '-15px', left: '-12px'}}>
        <UserStatu statu={user.statu} />
      </div>
    </div>
  )
}

export default PassagierInfo
