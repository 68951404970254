import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import Form1 from './Form1'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'

// const nameValidation = Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Name is required')
const editUserSchema = Yup.object().shape({
  name: Yup.string().required('Le titre est requis'),
  brand: Yup.string().required('Le code de téléphone est requis'),
  status: Yup.string().required('Le code de téléphone est requis'),
})
const AddModel = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const {errorToas, successToas} = useContext(AppContext)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading
  const {refetch} = useQueryResponse()
  const [image, setImage] = useState<File | any>()
  const [filess, setFiles] = useState<any>()
  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }

  // get driver when update
  const getModel = async (id) => {
    try {
      const data = await callApi({
        route: `models/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
      setFiles(`${process.env.REACT_APP_API_URL}/${data?.photo}`)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getModel(id)
    }
  }, [id])

  useEffect(() => {}, [])
  //console.log(updateData)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand: updateData?.brand?.id,
      name: updateData?.name,
      status: updateData.status ? updateData.status : false,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //console.log(values)
      setSubmitting(true)
      const postData = {
        name: values.name,
        brand: `brands/${values.brand}`,
        status: values.status,
      }

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postCity = async () => {
          setIsLoading(true)
          try {
            const data = await callApi({
              route: location.pathname === '/car-model/add' ? 'models' : `models/${id}`,
              method: location.pathname === '/car-model/add' ? 'POST' : `PUT`,
              body: postData,
            })
            if (data) {
              setIsLoading(false)
              successToas('success')
              navigate('/car-model')
            }
          } catch (error: any) {
            setIsLoading(false)
            errorToas(error.response['data'].detail)
          }
        }
        postCity()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }
  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <Form1
        formik={formik}
        isUserLoading={isUserLoading}
        setImage={setImage}
        image={image}
        setFiles={setFiles}
        filess={filess}
      />

      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleNextStep}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Submit
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e: any) => formik.handleSubmit(e)}
                disabled={formik.isSubmitting || isUserLoading || !formik.isValid}
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AddModel
