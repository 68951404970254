import * as React from 'react'
import {Drive} from '../../../core/_models'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {UserInfoCell} from '../UserInfoCell'
import PassagierInfo from './PassagierInfo'
import {UseCallApi} from '../../../../../../../../_metronic/CallApi'
import {AppContext} from '../../../../../../../../AppContext'

type Props = {
  id: any
}

const ModalDrivers: React.FC<Props> = ({id}) => {
  const {successToas} = React.useContext(AppContext)
  const [data, setData] = React.useState<Drive[]>([])
  const buttonRef = React.useRef<any>()
  const valider = () => {
    successToas('success')
  }
  const getDrivers = async () => {
    try {
      const data = await UseCallApi()({
        route: `drivers`,
        method: 'GET',
        jsonLd: true,
      })

      setData(data['hydra:member'])
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  React.useEffect(() => {
    getDrivers()
  }, [])
  return (
    <div>
      <button
        type='button'
        className='btn btn-light-primary btn-sm'
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_1${id}`}
      >
        <i className='fa-solid fa-list-check'></i>
      </button>
      <div className='modal fade' tabIndex={-1} id={`kt_modal_1${id}`}>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Sélectionner un chauffeur</h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                ref={buttonRef}
              ></button>
            </div>
            <div className='modal-body'>
              <div className='table-responsive'>
                <table className='table gs-7 gy-7 gx-7 table-hover'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                      <th>Chauffeur</th>
                      <th> NOM DE L'ENTREPRISE</th>
                      <th> TAXIEUR</th>
                      <th> MOBILE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((user: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <PassagierInfo user={user} ref={buttonRef} />
                          </td>
                          <td>
                            <p>{user?.company?.name}</p>
                          </td>
                          <td>
                            <p>false</p>
                          </td>
                          <td>
                            <p>{user?.tel}</p>
                          </td>
                          <td>
                            <input className='form-check-input' type='radio' name='appType' />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>{' '}
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Fermer
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={valider}
                data-bs-dismiss='modal'
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalDrivers
