/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {Link} from 'react-router-dom'
import {AppContext} from '../../../../../../../AppContext'
import { LuImport } from "react-icons/lu";


type Props = {
  id: ID
  handleDeleteUser: any
  editUser?: any
  myprops?: any
  seIdUser?: any
}

const UserActionsCell: FC<Props> = ({id, editUser, myprops, seIdUser, handleDeleteUser}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {deleteAlert} = useContext(AppContext)
  // //console.log(editUser)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })
  return (
    <div className='d-flex align-items-center'>
      <div className='me-3'>
        <Link to={`/detail-drive/${id}`} className='btn btn-icon btn-light-primary btn-sm '>
          <i className='bi bi-eye-fill fs-4'></i>
        </Link>
      </div>
      <div className='me-3'>
        {/* <Link to={`/product/update-product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'> */}

        <a
          className='btn btn-icon btn-light-success btn-sm '
          title='Import'
          // data-kt-users-table-filter='delete_row'
          onClick={async () => {
            const confirmation = await deleteAlert()
            if (confirmation) {
              handleDeleteUser(id)
            }
          }} // onClick={async () => await deleteItem.mutateAsync()}
        >
         <LuImport />

        </a>
      </div>
      <div className='me-3'>
        {/* <Link to={`/product/update-product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'> */}

        <a
          title='Annuler de la réservation'
          className='btn btn-icon btn-light-danger btn-sm '
          // data-kt-users-table-filter='delete_row'
          onClick={async () => {
            const confirmation = await deleteAlert()
            if (confirmation) {
              handleDeleteUser(id)
            }
          }} // onClick={async () => await deleteItem.mutateAsync()}
        >
          <i className='bi bi-trash-fill fs-4'></i>
        </a>
      </div>
      <div className='d-flex'>
        {/* <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm d-flex align-items-center'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Satatu
          <KTIcon iconName='down' className='fs-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <a className='menu-link px-3'>Valide</a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3'>En attente</a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3'>suspendu</a>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3'>Bloquer</a>
          </div>
        </div> */}
        {/* begin::Menu */}
        {/* <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item 
          <div className='menu-item px-3'>
            <Link className='menu-link px-3' to={`/update-driver/${id}`}>
              Modifier
            </Link>
          </div>
          {/* end::Menu item 

          {/* begin::Menu item 
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Supprimer
            </a>
          </div>
          {/* end::Menu item 
        </div> */}
        {/* end::Menu */}
      </div>
    </div>
  )
}

export {UserActionsCell}
