// MyRow.tsx
import React, {useRef} from 'react'
import clsx from 'clsx'

type Props = {
  label?: any
  refAcordion?: any
  model?: any
  setFormDoc?: any
  formDoc?: any
  formDocuments?: any
  isUserLoading?: any
  refRow?: any
  senTdocument?: any
  acordianIndex?: any
  accordionId?: any
  rowIndex?: any
}

const MyRow: React.FC<Props> = ({rowIndex, acordianIndex, label, model, setFormDoc, formDoc}) => {
  const indexRef = useRef<any>(Math.random())
  const refOption = React.useRef<any>()
  const refLabel = React.useRef<any>()
  const refSelect = React.useRef<any>()
  const [isSelect, setIsSelect] = React.useState(false)
  const [options, setOptions] = React.useState<any>([])
  const addOption = () => {
    if (refOption.current && refOption.current.value !== '') {
      setOptions([...options, refOption.current.value])
      refOption.current.value = ''
    }
  }

  const onchangeSelect = (event, ref) => {
    if (event === 'select') {
      setIsSelect(true)
    } else {
      setIsSelect(false)
    }
    if (model >= 0 && acordianIndex >= 0 && rowIndex >= 0 && formDoc) {
      // Find the index of the element you want to update
      const elementIndex = formDoc[model].formDocuments[
        acordianIndex
      ].elementFormDocuments.findIndex((Element, index) => index === rowIndex)

      if (elementIndex !== -1) {
        const updatedFormDoc = [...formDoc]
        const updatedFormDocuments = [
          ...updatedFormDoc[model].formDocuments[acordianIndex].elementFormDocuments,
        ]
        updatedFormDocuments[elementIndex].type = ref.current.value
        updatedFormDoc[model].formDocuments[acordianIndex].elementFormDocuments =
          updatedFormDocuments
        setFormDoc(updatedFormDoc)
      }
    }
  }
  const removeOption = (indexToRemove) => {
    const updatedOptions = [...options]
    if (indexToRemove >= 0 && indexToRemove < updatedOptions.length) {
      updatedOptions.splice(indexToRemove, 1)
      setOptions(updatedOptions)
    }
  }
  const onchangeLabel = (ref) => {
    // //console.log(formDoc[model].formDocuments[0].elementFormDocuments)
    if (model >= 0 && acordianIndex >= 0 && formDoc) {
      // Find the index of the element you want to update
      const elementIndex = formDoc[model].formDocuments[
        acordianIndex
      ].elementFormDocuments.findIndex((Element, index) => index === rowIndex)

      if (elementIndex !== -1) {
        const updatedFormDoc = [...formDoc]
        const updatedFormDocuments = [
          ...updatedFormDoc[model].formDocuments[acordianIndex].elementFormDocuments,
        ]
        updatedFormDocuments[elementIndex].label = ref.current.value
        updatedFormDoc[model].formDocuments[acordianIndex].elementFormDocuments =
          updatedFormDocuments
        setFormDoc(updatedFormDoc)
      }
    }
  }
  React.useEffect(() => {
    refLabel.current.value = label.label
    refSelect.current.value = label.type
  }, [])
  

  return (
    <div className='row w-100' id={indexRef.current}>
      <div className='col'>
        <label className='required fw-bold fs-6 mb-2'>Label</label>
        <input
          ref={refLabel}
          onChange={() => onchangeLabel(refLabel)}
          placeholder='label'
          className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
          type='text'
          name='label'
          autoComplete='off'
        />
      </div>
      <div className='col'>
        <label className='required fw-bold fs-6 mb-2' htmlFor='type'>
          type
        </label>
        <select
          ref={refSelect}
          className='form-select'
          name='type'
          id='type'
          onChange={(event) => {
            onchangeSelect(event.target.value, refSelect)
          }}
        >
          <option value='text'>Text</option>
          <option value='number'>Number</option>
          <option value='email'>Email</option>
          <option value='date'>Date</option>
          <option value='checkbox'>Checkbox</option>
          <option value='radio'>Radio</option>
          <option value='select'>Select</option>
          <option value='file'>File</option>
          <option value='password'>Password</option>
          <option value='color'>Color</option>
        </select>
      </div>
      <div className={isSelect === true ? `col ` : 'd-none'}>
        <div className='mb-3 d-flex w-100'>
          <div className='w-100'>
            <label className='required fw-bold fs-6 mb-2'>options</label>
            <input
              ref={refOption}
              placeholder='option'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              type='text'
              name='option'
              autoComplete='off'
            />
          </div>
          <div className='  ms-2 d-flex align-items-end'>
            <button className='btn btn-icon btn-light-primary btn-sm' onClick={addOption}>
              <i className='ki-duotone ki-plus-square fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </button>
          </div>
        </div>
        <div className='d-flex flex-column'>
          {options &&
            options.map((option: any, index: number) => (
              <div key={index} className='d-flex'>
                <input
                  className='form-control form-control-solid mb-3  '
                  placeholder='option'
                  readOnly={true}
                  type='text'
                  name='list_option'
                  autoComplete='off'
                  value={option}
                />
                <button
                  className='btn btn-icon btn-light-danger btn-sm'
                  onClick={() => removeOption(index)}
                >
                  <i className='ki-duotone ki-cross-circle fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default MyRow
