import React, {useState} from 'react'
import clsx from 'clsx'
import Button from '@mui/material/Button'
import Acordian from './partial/acardian'
import MyRow from './partial/MyRow'
import {UseCallApi} from '../../../_metronic/CallApi'
import {useParams} from 'react-router-dom'
import {getCountries} from '../../../_metronic/helpers/Requests/Requests'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'

type Props = {
  senTdocument?: any
  id?: any
  setFormDoc?: any
  formDoc?: any
  formik?: any
  isUserLoading?: any
}

const Form2: React.FC<Props> = ({formik, isUserLoading, id, senTdocument, setFormDoc, formDoc}) => {
  const [isSelect, setIsSelect] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [form, setForm] = React.useState([])
  const [countries, setCountries] = React.useState([])
  const [entrepriseAccordions, setEntrepriseAccordions] = useState<any[]>([])

  const [chauffeurAccordions, setChauffeurAccordions] = useState<any[]>([])
  const [passagierAccordions, setPassagierAccordions] = useState<any[]>([])
  const updateIdAtIndex = (index, newIdValue) => {
    const updatedFormDoc = [...formDoc]
    if (index >= 0 && index < updatedFormDoc.length) {
      updatedFormDoc[index].id = newIdValue
    }

    return updatedFormDoc
  }

  //company form
  const getFormsCompany = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?countries=${formik.values.pay}&model=company`,
        method: 'GET',
        jsonLd: true,
      })
      updateIdAtIndex(0, data['hydra:member'][0]?.id)
      const company = data['hydra:member'][0].formDocuments

      //company
      for (let index = 0; index < company.length; index++) {
        addAccordion(0, company[index].title, entrepriseAccordions, setEntrepriseAccordions,            data['hydra:member'][0]?.id
        )
        for (
          let SecondIndex = 0;
          SecondIndex < company[index].elementFormDocuments.length;
          SecondIndex++
        ) {
          const comp = company[index].elementFormDocuments[SecondIndex]

          addRow(
            comp.id,
            comp.label,
            comp.type,
            0,
            index,
            entrepriseAccordions,
            setEntrepriseAccordions
          )
        }
      }
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  //chauffeur
  const getFormsChauffeur = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?countries=${formik.values.pay}&model=chauffeur`,
        method: 'GET',
        jsonLd: true,
      })

      updateIdAtIndex(1, data['hydra:member'][0].id)

      const chauffeurr = data['hydra:member'][0].formDocuments

      if (chauffeurr.length > 0) {
        for (let index = 0; index < chauffeurr.length; index++) {
          addAccordion(
            1,
            chauffeurr[index].title,
            chauffeurAccordions,
            setChauffeurAccordions,
            data['hydra:member'][0]?.id
          )
          if (chauffeurr[index].elementFormDocuments.length > 0) {
            for (
              let SecondIndex = 0;
              SecondIndex < chauffeurr[index].elementFormDocuments.length;
              SecondIndex++
            ) {
              const comp = chauffeurr[index].elementFormDocuments[SecondIndex]

              addRow(
                comp.id,
                comp.label,
                comp.type,
                1,
                index,
                chauffeurAccordions,
                setChauffeurAccordions
              )
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getFormsPassager = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?countries=${formik.values.pay}&model=passager`,
        method: 'GET',
        jsonLd: true,
      })
      updateIdAtIndex(2, data['hydra:member'][0].id)

      const passager = data['hydra:member'][0].formDocuments
      //passager
      for (let index = 0; index < passager.length; index++) {
        addAccordion(2, passager[index].title, passagierAccordions, setPassagierAccordions,data['hydra:member'][0]?.id)
        for (
          let SecondIndex = 0;
          SecondIndex < passager[index].elementFormDocuments.length;
          SecondIndex++
        ) {
          const comp = passager[index].elementFormDocuments[SecondIndex]
          addRow(
            comp.id,
            comp.label,
            comp.type,
            2,
            index,
            passagierAccordions,
            setPassagierAccordions
          )
        }
      }
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  React.useEffect(() => {
    getCountries(setCountries)
  }, [])
  const up = async () => {
    setIsLoading(true)
    setEntrepriseAccordions([])
    setChauffeurAccordions([])
    setPassagierAccordions([])
    setFormDoc((prevFormDoc) => {
      const updatedFormDoc = prevFormDoc.map((item) => {
        return {
          ...item,
          countries: `countries/${formik.values.pay}`,
          formDocuments: [],
        }
      })
      return updatedFormDoc
    })

    // setFormDoc([
    //   {
    //     model: 'company',
    //     countries: `countries/${formik.values.pay}`,
    //     formDocuments: [],
    //   },
    //   {
    //     model: 'chauffeur',
    //     countries: `countries/${formik.values.pay}`,
    //     formDocuments: [],
    //   },
    //   {
    //     model: 'passager',
    //     countries: `countries/${formik.values.pay}`,
    //     formDocuments: [],
    //   },
    // ])

    if (formik.values.pay) {
      await getFormsPassager()
      await getFormsChauffeur()
      await getFormsCompany()
      // //console.log(formik.values.pay);

      // Promise.all([getFormsPassager(), getFormsChauffeur(), getFormsCompany()]).then(() => {
      //   const updatedFormDoc = formDoc.map((item) => ({
      //     ...item,
      //     countries: `countries/${formik.values.pay}`,
      //   }))
      //   setFormDoc(updatedFormDoc)
      // })
    }
    await setIsLoading(false)
  }
  React.useEffect(() => {
    up()
  }, [formik.values.pay])
  const toggleAccordion = (
    accordionId: string,
    setAccordions: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setAccordions((prevAccordions) =>
      prevAccordions.map((accordion) => ({
        ...accordion,
        isOpen: accordion.id === accordionId ? !accordion.isOpen : accordion.isOpen,
      }))
    )
  }
  const addRow = (
    id: number,
    label: string,
    type: string,
    model: number,
    accordionIndex: number,
    accordion: any,

    setAccordions: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setAccordions((prevAccordions) => {
      const updatedAccordions = [...prevAccordions]
      let myId = id
      if (myId === 0) {
        let lastId = updatedAccordions[accordionIndex].rows.slice(-1)[0]?.id
        if (!lastId) {
          lastId = 0
        }
        myId = lastId + 1
      }
      updatedAccordions[accordionIndex].rows.push({
        id: myId,
        label: label,
        type: type,
      })
      // const updatedFormDoc = [...formDoc]
      // const accordionInFormDoc = updatedFormDoc[model].formDocuments[accordionIndex]
      // if (accordionInFormDoc) {
      //   if (!accordionInFormDoc.elementFormDocuments) {
      //     accordionInFormDoc.elementFormDocuments = []
      //   }
      //   const newFormDocument = {
      //     ref: Math.random(),
      //     label: label,
      //     type: type,
      //   }
      //   accordionInFormDoc.elementFormDocuments.push(newFormDocument)
      // }
      //  setFormDoc(updatedFormDoc)

      return updatedAccordions
    })
    setFormDoc((prevFormDoc) => {
      const updatedFormDoc = [...prevFormDoc]
      const accordionInFormDoc = updatedFormDoc[model].formDocuments[accordionIndex]
      const newFormDocument = {
        ref: Math.random(),
        label: label,
        type: type,
      }
      if (accordionInFormDoc) {
        accordionInFormDoc.elementFormDocuments.push(newFormDocument)
      }
      // if (accordionInFormDoc) {
      //   if (!accordionInFormDoc.elementFormDocuments) {
      //     accordionInFormDoc.elementFormDocuments = []
      //   }
      //   const newFormDocument = {
      //     ref: Math.random(),
      //     label: label,
      //     type: type,
      //   }
      //   accordionInFormDoc.elementFormDocuments.push(newFormDocument)
      // }
      // updatedFormDoc[model].countries = 12

      return updatedFormDoc
    })

    // const updatedAccordions = [...accordion]
    //   let myId = id
    //   if (myId === 0) {
    //     let lastId = updatedAccordions[accordionIndex].rows.slice(-1)[0]?.id
    //     if (!lastId) {
    //       lastId = 0
    //     }
    //     myId = lastId + 1
    //   }
    //   updatedAccordions[accordionIndex].rows.push({
    //     id: myId,
    //     label: label,
    //     type: type,
    //   })
    //   const updatedFormDoc = [...formDoc]
    //   const accordionInFormDoc = updatedFormDoc[model].formDocuments[accordionIndex]
    //   if (accordionInFormDoc) {
    //     if (!accordionInFormDoc.elementFormDocuments) {
    //       accordionInFormDoc.elementFormDocuments = []
    //     }
    //     const newFormDocument = {
    //       ref: Math.random(),
    //       label: label,
    //       type: type,
    //     }
    //     accordionInFormDoc.elementFormDocuments.push(newFormDocument)
    //   }
  }

  const addAccordion = (
    model,
    titre,
    Accordion: any,
    setAccordions: React.Dispatch<React.SetStateAction<any[]>>,
    id?: any
  ) => {
    setAccordions((prevAccordions) => {
      const newAccordionId = prevAccordions.length + 1
      const newAccordion = {
        title: titre,
        id: newAccordionId,
        rows: [] as number[],
      }
      return [...prevAccordions, newAccordion]
    })

    // const updatedFormDocuments = [...formDoc[model].formDocuments ,newFormDocument]
    // const updatedFormDoc = [...formDoc]
    // updatedFormDoc[model].formDocuments = updatedFormDocuments
    setFormDoc((prevFormDoc) => {
      const newFormDocument = {
        id: Accordion.length + 1,
        title: titre,

        elementFormDocuments: [],
      }
      const updatedFormDocuments = [...prevFormDoc[model].formDocuments, newFormDocument]
      const updatedFormDoc = [...prevFormDoc]
      updatedFormDoc[model] = {
        ...updatedFormDoc[model],
        formDocuments: updatedFormDocuments,
      }
      if (id) {
        updatedFormDoc[model].id = id;
      }
      return updatedFormDoc
    })

    // setFormDoc(updatedFormDoc)
  }

  const removeAccordion = (
    model: number,
    accordionId: number,
    setAccordions: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    removeAccordionFromFormDoc(model, accordionId)
    setAccordions((prevAccordions) =>
      prevAccordions.filter((accordion, index) => index !== accordionId)
    )
  }
  const removeAccordionFromFormDoc = (model: number, accordionId: number) => {
    setFormDoc((prevFormDoc) => {
      const updatedFormDoc = [...prevFormDoc]
      const accordionIndex = updatedFormDoc[model].formDocuments.findIndex(
        (doc, index) => index === accordionId
      )

      if (accordionIndex !== -1) {
        updatedFormDoc[model].formDocuments.splice(accordionIndex, 1)
      }
      return updatedFormDoc
    })
  }

  const removeRow = (
    model: number,
    accordionIndex: number,
    rowIndex: number,
    setAccordions: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    removeRowFromFormDoc(model, accordionIndex, rowIndex)
    setAccordions((prevAccordions) => {
      const updatedAccordions = [...prevAccordions]
      const accordionToUpdate = updatedAccordions[accordionIndex]

      if (accordionToUpdate && accordionToUpdate.rows) {
        accordionToUpdate.rows.splice(rowIndex, 1) // Remove the row at the specified index
      }

      return updatedAccordions
    })
  }

  const removeRowFromFormDoc = (model: number, accordionIndex: number, rowIndex: number) => {
    setFormDoc((prevFormDoc) => {
      const updatedFormDoc = [...prevFormDoc]
      if (
        updatedFormDoc[model] &&
        updatedFormDoc[model].formDocuments &&
        updatedFormDoc[model].formDocuments[accordionIndex] &&
        updatedFormDoc[model].formDocuments[accordionIndex].elementFormDocuments
      ) {
        updatedFormDoc[model].formDocuments[accordionIndex].elementFormDocuments.splice(rowIndex, 1)
      }
      return updatedFormDoc
    })
  }

  return (
    <div>
      <div className='mb-4'>
        <label htmlFor='country' className='required form-label'>
          Countries
        </label>
        <SearchSelect
          disabled={isLoading}
          options={countries}
          defaultValue={formik.values.pay}
          name={'pay'}
          formik={formik}
        />
      </div>
      {/* Entreprise */}
      <Acordian
        model={0}
        titre={'Entreprise'}
        readonly={true}
        formik={formik}
        isUserLoading={isUserLoading}
        isSelect={isSelect}
        accordionId={`Entreprise`}
      >
        {entrepriseAccordions.map((accordion, accordionIndex) => (
          <div
            key={accordionIndex}
            className='d-flex w-100 justify-content-between align-items-center'
          >
            <Acordian
              formDoc={formDoc}
              setFormDoc={setFormDoc}
              key={`company${accordion.id}`}
              formik={formik}
              isUserLoading={isUserLoading}
              isSelect={isSelect}
              model={0}
              titre={accordion.title}
              acordianIndex={accordionIndex}
              accordionId={`company${accordionIndex}`}
              isOpen={accordion.isOpen}
              onToggle={() => toggleAccordion(accordion.id, setEntrepriseAccordions)}
            >
              {accordion &&
                accordion.rows &&
                accordion?.rows?.map((rowId, rowIndex) => (
                  <div
                    key={`${accordionIndex}_${rowId.id}`}
                    className='d-flex w-100 justify-content-between align-items-center'
                  >
                    <MyRow
                      rowIndex={rowIndex}
                      acordianIndex={accordionIndex}
                      accordionId={`company${accordionIndex}`}
                      senTdocument={senTdocument}
                      model={0}
                      label={accordion.rows[rowIndex]}
                      setFormDoc={setFormDoc}
                      formDoc={formDoc}
                      key={`${accordionIndex}_${rowId.id}`}
                      refAcordion={`ref_0_${accordionIndex}_${`company${accordionIndex}`}`}
                      isUserLoading={isUserLoading}
                      refRow={'ref_0_' + accordionIndex + '_' + rowIndex}
                    />
                    <button
                      className='btn btn-icon btn-light-danger btn-sm ms-3'
                      onClick={() =>
                        removeRow(0, accordionIndex, rowIndex, setEntrepriseAccordions)
                      }
                    >
                      <i className='ki-duotone ki-cross-circle fs-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                    </button>
                  </div>
                ))}
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-primary mt-3'
                  onClick={() =>
                    addRow(
                      0,
                      '',
                      'text',
                      0,
                      accordionIndex,
                      entrepriseAccordions,
                      setEntrepriseAccordions
                    )
                  } // Add a new row when clicked
                >
                  Nouveau élément
                </button>
              </div>
            </Acordian>
            <button
              className='btn btn-icon btn-light-danger btn-sm ms-3'
              onClick={() => removeAccordion(0, accordionIndex, setEntrepriseAccordions)}
            >
              <i className='ki-duotone ki-cross-circle fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </button>
          </div>
        ))}
        <div className='d-flex justify-content-start'>
          <button
            className='btn btn-primary mt-3'
            onClick={() => addAccordion(0, '', entrepriseAccordions, setEntrepriseAccordions)}
          >
            Ajouter document
          </button>
        </div>
      </Acordian>
      {/* Entreprise */}
      <Acordian
        model={1}
        titre={'Chauffeur'}
        readonly={true}
        formik={formik}
        isUserLoading={isUserLoading}
        isSelect={isSelect}
        accordionId={`Chauffeur`}
      >
        {chauffeurAccordions.map((accordion, accordionIndex) => (
          <div
            key={accordion.id}
            className='d-flex w-100 justify-content-between align-items-center'
          >
            <Acordian
              formDoc={formDoc}
              setFormDoc={setFormDoc}
              key={`chauffeur${accordion.id}`}
              formik={formik}
              isUserLoading={isUserLoading}
              isSelect={isSelect}
              model={1}
              titre={accordion.title}
              acordianIndex={accordionIndex}
              accordionId={`chauffeur${accordionIndex}`}
              isOpen={accordion.isOpen}
              onToggle={() => toggleAccordion(accordion.id, setChauffeurAccordions)}
            >
              {accordion &&
                accordion.rows &&
                accordion?.rows?.map((rowId, rowIndex) => (
                  <div
                    key={`${accordionIndex}_${rowId.id}`}
                    className='d-flex w-100 justify-content-between align-items-center'
                  >
                    <MyRow
                      rowIndex={rowIndex}
                      acordianIndex={accordionIndex}
                      accordionId={`chauffeur${accordionIndex}`}
                      senTdocument={senTdocument}
                      model={1}
                      label={accordion.rows[rowIndex]}
                      setFormDoc={setFormDoc}
                      formDoc={formDoc}
                      key={`${accordionIndex}_${rowId.id}`}
                      refAcordion={`ref_1_${accordionIndex}_${`chauffeur${accordionIndex}`}`}
                      isUserLoading={isUserLoading}
                      refRow={'ref_1_' + accordionIndex + '_' + rowIndex}
                    />
                    <button
                      className='btn btn-icon btn-light-danger btn-sm ms-3'
                      onClick={() => removeRow(1, accordionIndex, rowIndex, setChauffeurAccordions)}
                    >
                      <i className='ki-duotone ki-cross-circle fs-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                    </button>
                  </div>
                ))}
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-primary mt-3'
                  onClick={() =>
                    addRow(
                      0,
                      '',
                      'text',
                      1,
                      accordionIndex,
                      chauffeurAccordions,
                      setChauffeurAccordions
                    )
                  } // Add a new row when clicked
                >
                  Nouveau élément
                </button>
              </div>
            </Acordian>
            <button
              className='btn btn-icon btn-light-danger btn-sm ms-3'
              onClick={() => removeAccordion(1, accordionIndex, setChauffeurAccordions)}
            >
              <i className='ki-duotone ki-cross-circle fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </button>
          </div>
        ))}
        <div className='d-flex justify-content-start'>
          <button
            className='btn btn-primary mt-3'
            onClick={() => addAccordion(1, '', chauffeurAccordions, setChauffeurAccordions)}
          >
            Ajouter document{' '}
          </button>
        </div>
      </Acordian>
      {/* passager */}
      <Acordian
        model={2}
        titre={'Passager'}
        readonly={true}
        formik={formik}
        isUserLoading={isUserLoading}
        isSelect={isSelect}
        accordionId={`Passager`}
      >
        {passagierAccordions.map((accordion, accordionIndex) => (
          <div
            key={accordion.id}
            className='d-flex w-100 justify-content-between align-items-center'
          >
            <Acordian
              formDoc={formDoc}
              setFormDoc={setFormDoc}
              key={`passager${accordion.id}`}
              formik={formik}
              isUserLoading={isUserLoading}
              isSelect={isSelect}
              model={2}
              titre={accordion.title}
              acordianIndex={accordionIndex}
              accordionId={`passager${accordionIndex}`}
              isOpen={accordion.isOpen}
              onToggle={() => toggleAccordion(accordion.id, setPassagierAccordions)}
            >
              {accordion &&
                accordion.rows &&
                accordion?.rows?.map((rowId, rowIndex) => (
                  <div
                    key={`${accordionIndex}_${rowId.id}`}
                    className='d-flex w-100 justify-content-between align-items-center'
                  >
                    <MyRow
                      rowIndex={rowIndex}
                      acordianIndex={accordionIndex}
                      accordionId={`passager${accordionIndex}`}
                      senTdocument={senTdocument}
                      model={2}
                      label={accordion.rows[rowIndex]}
                      setFormDoc={setFormDoc}
                      formDoc={formDoc}
                      key={`${accordionIndex}_${rowId.id}`}
                      refAcordion={`ref_2_${accordionIndex}_${`passager${accordionIndex}`}`}
                      isUserLoading={isUserLoading}
                      refRow={'ref_2_' + accordionIndex + '_' + rowIndex}
                    />
                    <button
                      className='btn btn-icon btn-light-danger btn-sm ms-3'
                      onClick={() => removeRow(2, accordionIndex, rowIndex, setPassagierAccordions)}
                    >
                      <i className='ki-duotone ki-cross-circle fs-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                    </button>
                  </div>
                ))}
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-primary mt-3'
                  onClick={() =>
                    addRow(
                      0,
                      '',
                      'text',
                      2,
                      accordionIndex,
                      passagierAccordions,
                      setPassagierAccordions
                    )
                  } // Add a new row when clicked
                >
                  Nouveau élément
                </button>
              </div>
            </Acordian>
            <button
              className='btn btn-icon btn-light-danger btn-sm ms-3'
              onClick={() => removeAccordion(2, accordionIndex, setChauffeurAccordions)}
            >
              <i className='ki-duotone ki-cross-circle fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </button>
          </div>
        ))}
        <div className='d-flex justify-content-start'>
          <button
            className='btn btn-primary mt-3'
            onClick={() => addAccordion(2, '', passagierAccordions, setPassagierAccordions)}
          >
            Ajouter document{' '}
          </button>
        </div>
      </Acordian>
    </div>
  )
}

export default Form2
