import {FC, useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponse,
} from '../core/QueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {usersColumnsDrives} from './columns/_columnsDrives'
import {Drive} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import UsersListPagination from '../components/pagination/DrivesListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {object} from 'yup'
import {usersDesponibleColumns} from './columns/_columnsDisponible'
import {UseCallApi} from '../../../../../../_metronic/CallApi'
// import BookingData from "../../../../../../_metronic/assets/json/BookingData.json"
import {getIduser} from '../core/_requests'
type Props = {
  setData?: any
  data?: any
  headerFilter?: any
  setHeaderFilter?: any
  handleDeleteUser?: any
}
const BookingTable: FC<Props> = ({
  setData,
  data,
  setHeaderFilter,
  headerFilter,
  handleDeleteUser,
}) => {
  const [idUser, seIdUser] = useState<any>()
  const location = useLocation()
  const path = location.pathname
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(10)

  // const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users, [users])
  // const handleDeleteUser = (idUser) => {
  //   setData((prevData) => prevData.filter((user) => user.id !== idUser))
  // }
  // useEffect(() => {
  //   handleDeleteUser(idUser)
  // }, [idUser])

  const columns = useMemo(() => {
    if (path === '/drives') {
      return usersColumnsDrives
    } else if (path === '/disponible') {
      return usersDesponibleColumns
    } else {
      return usersColumns
    }
  }, [path])
  const {myChange, setMychange} = useQueryResponse()

  const users = useQueryResponseData().bookings ;
  
  // const users=BookingData.bookings
  // //console.log(users);
  
  // function Test() {
  //   //console.log(useQueryResponseData()['hydra:member'])
  // }
  useEffect(() => {
    // Test()
    if (users != undefined) {
      setData(users)
    } else {
      setData([])
    }
  }, [users, myChange])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  // fetch data
  const callApi = UseCallApi()
  const getDrivers = async () => {
    try {
      const data = await callApi({
        route: 'drivers.json',
        method: 'GET',
      })
      // //console.log(data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getDrivers()
  }, [])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Drive>) => (
                <CustomHeaderColumn
                  key={column.id}
                  column={column} // Pass the callback function
                />
              ))}
            </tr>
          </thead>
          <tbody className='text-start text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Drive>, i) => {
                prepareRow(row)
                return (
                  <CustomRow
                    row={row}
                    path={path}
                    key={`row-${i}-${row.id}`}
                    seIdUser={seIdUser}
                    handleDeleteUser={handleDeleteUser}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination setPage={setPage} totalItems={totalItems} />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {BookingTable}
