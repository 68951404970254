import React, {FC} from 'react'
import {getFileExtension} from '../../../../../_metronic/helpers/functions/GetFileExtension'

type Props = {
  driver?: any
}
const DocumentDriver: FC<Props> = ({driver}) => {
  return (
    <div className='mb-5'>
      <div className='accordion' id='kt_accordion_1'>
        <div className='accordion-item'>
          <h2 className='accordion-header' id='kt_accordion_1_header_1'>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#carteGrise'
              aria-expanded='false'
              aria-controls='carteGrise'
            >
              Documents
            </button>
          </h2>
          <div
            id='carteGrise'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#kt_accordion_1'
          >
            <div className='accordion-body row '>
              {driver &&
                driver.paperDrivers.length > 0 &&
                driver?.paperDrivers.map((items: any) =>
                  items.elementsPapers.map((item: any) => (
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                      <h1>{items.type}</h1>
                      <label htmlFor='carteGriseRecto' className='form-label me-3'>
                        {item.label} :
                      </label>
                      <div>
                        {getFileExtension(item?.name) == 'pdf' ||
                        getFileExtension(item?.name) == 'docx' ? (
                          <div style={{marginTop: '20px', overflow: 'hidden'}}>
                            <iframe
                              title='Embedded PDF'
                              src={`https://docs.google.com/gview?url=${process.env.REACT_APP_API_URL}/${item.name}&embedded=true`}
                              style={{
                                width: '100%',
                                height: '50vh', // Adjust the height as needed
                                border: 'none',
                                overflow: 'hidden',
                              }}
                              frameBorder={0}
                              scrolling='no' // Add scrolling="no" to prevent iframe scrolling
                            />
                          </div>
                        ) : getFileExtension(item?.name) == 'png' ||
                          getFileExtension(item?.name) == 'jpg' ||
                          getFileExtension(item?.name) == 'jpeg' ? (
                          <div style={{marginTop: '20px', overflow: 'hidden'}}>
                            <img
                              alt='Embedded Image'
                              src={`${process.env.REACT_APP_API_URL}/${item.name}`}
                              style={{
                                width: '100%',
                                height: '50vh',
                                border: 'none',
                                overflow: 'hidden',
                              }}
                            />
                          </div>
                        ) : (
                          <p
                            className='bg-secondary container p-3 rounded shadow'
                            style={{marginTop: '20px', overflow: 'hidden'}}
                          >
                            {item?.value}
                          </p>
                        )}
                      </div>{' '}
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentDriver
