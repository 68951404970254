import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {KTCard} from '../../../../../_metronic/helpers'
// import {MenuInner} from '../../../../../_metronic/layout/components/header/header-menus'
import HeaderWrapper from '../../../../../_metronic/layout/components/header/HeaderWrapper'
import {useContext, useState} from 'react'
import {CountryTable} from './table/CountryTable'
import {AppContext} from '../../../../../AppContext'
import {UseCallApi} from '../../../../../_metronic/CallApi'

// import {HeaderWrapper} from '../../../../../_metronic/layout/components/header'
const Links = [
  {title: 'Liste', link: '/apps/user-management'},
  {title: 'En attente', link: '/en-attente'},
  {title: 'Disponible', link: '/disponible'},
  {title: 'Drives', link: '/drives'},
]
const CountryList = () => {
  const {deleteAlert} = useContext(AppContext)
  const [data, setData] = useState<any>([])
  const callApi = UseCallApi()
  const handleDeleteUser = async (idUser) => {
    const confirmation = await deleteAlert()
    if (confirmation) {
      deleteCountry(idUser)
      setData((prevData) => prevData.filter((user) => user.id !== idUser))
    }
  }
  const deleteCountry = async (id) => {
    try {
      const data = await callApi({
        route: `countries/${id}`,
        method: 'DELETE',
      })
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const [headerFilter, setHeaderFilter] = useState({
    name: '',
    statu: '',
    countries: '',
  })

  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader
          setHeaderFilter={setHeaderFilter}
          headerFilter={headerFilter}
          handleDeleteUser={handleDeleteUser}
        />
        <CountryTable
          headerFilter={headerFilter}
          setData={setData}
          data={data}
          handleDeleteUser={handleDeleteUser}
        />
      </KTCard>
    </>
  )
}

const CountryListWrapper = () => (
  <QueryRequestProvider>
    <HeaderWrapper linkss={Links} />
    <QueryResponseProvider>
      <ListViewProvider>
        <CountryList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CountryListWrapper}
