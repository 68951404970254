import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {UsersListLoading} from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {Field, useFormik} from 'formik'
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import {useQueryResponse} from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import Form1 from './Form1'
import {UseCallApi} from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import {AppContext} from '../../../AppContext'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import Spinner from '../../../_metronic/partials/spinner/Spinner'

// const nameValidation = Yup.string()
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Name is required')
const nameValidation = /^[A-Za-z\s]+$/
const editUserSchema = Yup.object().shape({
  tel: Yup.string().required('Le numéro de téléphone est requis'),
  name: Yup.string()
    .matches(nameValidation, 'Le nom ne peut contenir que des lettres')
    .required('Le nom est requis'),
  email: Yup.string().email("Format d'email incorrect").required("L'email est requis"),
  password: Yup.string().min(6, 'Minimum 6 caractères').required('Le mot de passe est requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
  adress: Yup.string().required("L'adresse est requise"),
  VATNumber: Yup.string().required('le numéro de TVA  est requise'),
  zipCode: Yup.string().required('Le code postal est requis'),
})
const AddCorporate = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [updateData, setUpdateData] = useState<any>({})
  const {errorToas, successToas} = useContext(AppContext)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading

  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})

  //console.log(updateData)

  // get driver when update
  const getDriver = async (id) => {
    try {
      const data = await callApi({
        route: `companies/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }

  useEffect(() => {
    if (id) {
      getDriver(id)
    }
  }, [id])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tel: updateData.tel ? updateData.tel : '',
      type: updateData.type ? updateData.type : '',
      name: updateData.name ? updateData.name : '',
      email: updateData.email ? updateData.email : '',
      password: updateData.password ? updateData.password : '',
      confirmPassword: updateData.password ? updateData.password : '',
      country: updateData?.pay?.id,
      state: updateData?.state?.id,
      city: updateData?.city?.id,
      adress: updateData.adress ? updateData.adress : '',
      zipCode: updateData.zipCode ? updateData.zipCode : '',
      language: updateData?.language,
      currency: '',
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true)
      setSubmitting(true)
      const postData = {
        // company: values.company,
        language: values.language ? `languages/${values.language}` : null,
        zipCode: String(values.zipCode),
        adress: values.adress,
        state: values.state ? `states/${values.state}` : null,
        dispo: false,
        password: values.password,
        tel: values.tel,
        name: values.name,
        type: values.type,
        email: values.email,
      }
      //console.log(postData)

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      try {
        const postDriver = async () => {
          try {
            const data = await callApi({
              route:
                location.pathname == '/add-company' ? 'companies' : `update_company/${id}`,
              method: 'POST',
              body: postData,
              formData: true,
            })
            if (data) {
              successToas('success')
              navigate('/company-management')
            }
          } catch (error: any) {
            setIsLoading(false)

            errorToas(error.response['data'].detail)
          }
        }
        postDriver()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        // setIsLoading(false)
        setSubmitting(false)
      }
    },
  })
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }

  const Links = [
    {title: 'Liste', link: '/apps/user-management'},
    {title: 'En attente', link: '/en-attente'},
    {title: 'Disponible', link: '/disponible'},
    {title: 'Drives', link: '/drives'},
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      <Form1 formik={formik} isUserLoading={isUserLoading} />

      <div className='text-center pt-15 mb-7 d-flex justify-content-center text-white'>
        {isLoading ? (
          <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
            <Spinner></Spinner>
          </button>
        ) : (
          <button
            type='button'
            className='btn btn-primary'
            onClick={(e: any) => {
              setIsLoading(true)
              formik.handleSubmit(e)
            }}
          >
            Sauvegarder
          </button>
        )}
      </div>
    </>
  )
}

export default AddCorporate
