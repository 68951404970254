// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import {UserActionsCell} from './UserActionsCell'

type Props = {
  setFilter?: any
  row: Row<User>
  path?: string
  handleDeleteUser?: any
  seTfilterSatuts?: any
  updateUserStatus?: any
}
const CustomRow: FC<Props> = ({row, handleDeleteUser, seTfilterSatuts, updateUserStatus}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({
            'text-end min-w-100px': cell.column.id === 'actions',
          })}
        >
          {cell.column.id === 'actions' ? (
            <UserActionsCell
              updateUserStatus={updateUserStatus}
              seTfilterSatuts={seTfilterSatuts}
              id={cell.row.original.id}
              myprops={cell}
              editUser={cell.row.original}
              handleDeleteUser={handleDeleteUser} // Pass seIdUser here
            />
          ) : (
            cell.render('Cell')
          )}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
