/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import CustomSelectUsers from './CustomSelectUsers'
import {UseCallApi} from '../../../../../../../_metronic/CallApi'
import {getCountries} from '../../../../../../../_metronic/helpers/Requests/Requests'

type Props = {
  setHeaderFilter?: any
  headerFilter?: any
}
const UsersListSearchComponent: FC<Props> = ({setHeaderFilter, headerFilter}) => {
  const {updateState, state} = useQueryRequest()


  const status = [
    {value: 'driver', label: 'Chauffeur'},
    {value: 'passager', label: 'Passager'},
    
  ]
 

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )
  const recherChauffeur = (e: any) => {
    setSearchTerm(e.target.value)
    const newHeaderFilter = {
      ...headerFilter,
      name: e.target.value,
    }

    // Call the setHeaderFilter function with the updated headerFilter
    setHeaderFilter(newHeaderFilter)
  }
  return (
    <div className='card-title d-flex flex-wrap'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Recherche'
          value={searchTerm}
          onChange={(e) => recherChauffeur(e)}
        />
      </div>
      {/* status */}
      <div className='d-flex align-items-center position-relative my-1'>
        <CustomSelectUsers
          options={status}
          placeholder={'Type'}
          setHeaderFilter={setHeaderFilter}
          filterBy={'type'} 
          headerFilter={headerFilter}
        />
      </div>
      
    </div>
  )
}

export {UsersListSearchComponent}
