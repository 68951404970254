import {Route, Routes} from 'react-router-dom'
import Registration from './components/Registration'
import {ConfirmPassword} from './components/ConfirmPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import "../../../_metronic/assets/css/responsive.css"
import { ForgotPassword } from './components/ForgotPassword copy'
import { ChangePassword } from './components/ChangePassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration/*' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='confirm-password' element={<ConfirmPassword />} />
      <Route path='change-password' element={<ChangePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
