import {ChangeEvent, FC, useState, useEffect, createRef} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import Accordion from '../../../_metronic/partials/Accordion/Accordion'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelRadio from '../../../_metronic/partials/labelRadio'
import LabelFilePreview from '../../../_metronic/partials/LabelFilePerview'
import {v4 as uuidv4} from 'uuid'
import {Link} from 'react-router-dom'
import {TranslateText} from '../TranslateText/TranslateText'

type Props = {
  isUserLoading: boolean
  formik?: any
  languages: any
}

const Form1: FC<Props> = ({formik, isUserLoading, languages}) => {
  const callApi = UseCallApi()

  const translateText = async (key, text) => {
    return await TranslateText(key, text)
  }

  const translateAllType = async () => {
    const inputText = formik.getFieldProps(`typeVehiculeenglish`).value

    if (inputText && inputText.length > 0) {
      const translationPromises = languages
        .filter((item) => item.key !== 'en')
        .map(async (item) => {
          const translatedText = await translateText(item.key, inputText)
          formik.setFieldValue(`typeVehicule${item.code}`, translatedText)
          //console.log(item.code)
        })

      await Promise.all(translationPromises)
    }
  }
  const copyEnglishToAllType = async () => {
    const inputText = formik.getFieldProps(`typeVehiculeenglish`).value

    if (inputText && inputText.length > 0) {
      const translationPromises = languages
        .filter((item) => item.key !== 'en')
        .map(async (item) => {
          formik.setFieldValue(`typeVehicule${item.code}`, inputText)
          //console.log(item.code)
        })

      await Promise.all(translationPromises)
    }
  }
  const translateAllNameType = async () => {
    const inputText = formik.getFieldProps(`nameTypeVehiculeenglish`).value
    if (inputText && inputText.length > 0) {
      languages.forEach(async (item) => {
        if (item.key !== 'en') {
          const translatedText = await translateText(item.key, inputText)
          formik.setFieldValue(`nameTypeVehicule${item.code}`, translatedText)
        }
      })
    }
  }
const copyEnglishToAllNameType = async () => {
  const inputText = formik.getFieldProps(`nameTypeVehiculeenglish`).value
  if (inputText && inputText.length > 0) {
    languages.forEach(async (item) => {
      if (item.key !== 'en') {
        formik.setFieldValue(`nameTypeVehicule${item.code}`, inputText)
      }
    })
  }
}

  const pricePerKMs = [
    {code: 'Prix0To10', number1: 0, number2: 10},
    {code: 'Prix11To100', number1: 11, number2: 100},
    {code: 'Prix101To200', number1: 101, number2: 200},
    {code: 'Prix201ToMore', number1: 201, number2: 'more'},
  ]
  const vehiculeCategory = [
    {
      value: 'car',
      label: 'Car',
    },
    {
      value: 'motoBike',
      label: 'Moto-Bike',
    },
    {
      value: 'cycle',
      label: 'Cycle',
    },
    {
      value: 'truck',
      label: 'Truck',
    },
  ]
  const vehiculeCategoryBooking = [
    {
      value: 'standard',
      label: 'Standard',
    },
    {
      value: 'executif',
      label: 'Exécutif',
    },
    {
      value: 'transporteurPersonnesExecutif',
      label: 'Executive People Carrier',
    },
    {
      value: 'luxe',
      label: 'Luxe',
    },
    {
      value: 'transporteurDePersonnes',
      label: 'Transporteur de personnes',
    },
    {
      value: 'grandMonspace',
      label: 'Grand monospace',
    },
    {
      value: 'minibus',
      label: 'Minibus',
    },
  ]
  const commandes = [
    {
      value: '1',
      label: '--1--',
    },
    {
      value: '2',
      label: '--2--',
    },
    {
      value: '3',
      label: '--3--',
    },
    {
      value: '4',
      label: '--4--',
    },
    {
      value: '5',
      label: '--5--',
    },
    {
      value: '6',
      label: '--6--',
    },
    {
      value: '7',
      label: '--7--',
    },
    {
      value: '8',
      label: '--8--',
    },
    {
      value: '9',
      label: '--9--',
    },
    {
      value: '10',
      label: '--10--',
    },
    {
      value: '11',
      label: '--11--',
    },
    {
      value: '12',
      label: '--12--',
    },
    {
      value: '13',
      label: '--13--',
    },
    {
      value: '14',
      label: '--14--',
    },
    {
      value: '15',
      label: '--15--',
    },
    {
      value: '16',
      label: '--16--',
    },
    {
      value: '17',
      label: '--17--',
    },
    {
      value: '18',
      label: '--18--',
    },
    {
      value: '19',
      label: '--19--',
    },
    {
      value: '20',
      label: '--20--',
    },
    {
      value: '21',
      label: '--21--',
    },
    {
      value: '22',
      label: '--22--',
    },
    {
      value: '23',
      label: '--23--',
    },
    {
      value: '24',
      label: '--24--',
    },
    {
      value: '25',
      label: '--25--',
    },
    {
      value: '26',
      label: '--26--',
    },
    {
      value: '27',
      label: '--27--',
    },
    {
      value: '28',
      label: '--28--',
    },
    {
      value: '29',
      label: '--29--',
    },
    {
      value: '30',
      label: '--30--',
    },
    {
      value: '31',
      label: '--31--',
    },
    {
      value: '32',
      label: '--32--',
    },
    {
      value: '33',
      label: '--33--',
    },
    {
      value: '34',
      label: '--34--',
    },
    {
      value: '35',
      label: '--35--',
    },
    {
      value: '36',
      label: '--36--',
    },
    {
      value: '37',
      label: '--37--',
    },
    {
      value: '38',
      label: '--38--',
    },
  ]

  const selectLocation = [
    {
      value: 'all',
      label: 'all',
    },
    {
      value: 'BordeauxFrance',
      label: 'Bordeaux - France',
    },
    {
      value: 'emiratsArabesUnis',
      label: 'Emirats Arabes Unis',
    },
    {
      value: 'france',
      label: 'France',
    },
    {
      value: 'ileMaurice',
      label: 'ile-Maurice',
    },
    {
      value: 'inde',
      label: 'Inde',
    },
    {
      value: 'LyonFrance',
      label: 'Lyon - France',
    },
  ]
  const languageInputs = languages.map((language) => (
    <div key={language.code}>
      <div className={`row mb-4 align-items-end`}>
        <div className={`${language.key === 'en' ? ' col-6' : ''}`}>
          <LabelInput
            label={`type de véhicule ${language.label}`}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={`typeVehicule${language.code}`}
            placeholder={`${language.label} Sujet`}
          />
        </div>
        {language.key === 'en' && (
          <div className='col-6 row'>
            <div className='col-6 '>
              <button className='btn btn-primary w-100' onClick={translateAllType}>
                Convertir dans toutes les langues
              </button>
            </div>
            <div className='col-6 '>
              <button className='btn btn-primary w-100' onClick={copyEnglishToAllType}>
                Copier english À tous
              </button>
            </div>
          </div>
        )}
      </div>

      <div className={`row mb-4 align-items-end`}>
        <div className={`${language.key === 'en' ? ' col-6' : ''}`}>
          <LabelInput
            label={`${language.label} nom`}
            type={'string'}
            formik={formik}
            isUserLoading={isUserLoading}
            name={`nameTypeVehicule${language.code}`}
            placeholder={`${language.label} nom`}
          />
        </div>

        {language.key === 'en' && (
          <div className='col-6 row'>
            <div className='col-6 '>
     
              <button className='btn btn-primary w-100' onClick={translateAllNameType}>
                Convertir dans toutes les langues
              </button>
            </div>
            <div className='col-6 '>
              <button className='btn btn-primary w-100' onClick={copyEnglishToAllNameType}>
                Copier english À tous
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  ))
  const [priCeInHoure, setPriCeInHoure] = useState<any[]>([])
  const Row = ({index, formik}) => (
    <div className='d-flex flex-center gap-3 mb-4'>
      <div>
        <input
          type='number'
          name={`price-${index}-1`}
          className={clsx(
            'form-control ',
            {
              'is-invalid': formik.touched[`price-${index}-1`] && formik.errors[`price-${index}-1`],
            },
            {
              'is-valid': formik.touched[`price-${index}-1`] && !formik.errors[`price-${index}-1`],
            }
          )}
          placeholder='Prix'
          {...formik.getFieldProps(`price-${index}-1`)}
        />
        {formik.touched[`price-${index}-1`] && formik.errors[`price-${index}-1`] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[`price-${index}-1`]}</span>
            </div>
          </div>
        )}
      </div>
      -
      <div>
        <input
          type='number'
          name={`price-${index}-2`}
          className={clsx(
            'form-control ',
            {
              'is-invalid': formik.touched[`price-${index}-2`] && formik.errors[`price-${index}-2`],
            },
            {
              'is-valid': formik.touched[`price-${index}-2`] && !formik.errors[`price-${index}-2`],
            }
          )}
          placeholder='Prix'
          {...formik.getFieldProps(`price-${index}-2`)}
        />
        {formik.touched[`price-${index}-2`] && formik.errors[`price-${index}-2`] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[`price-${index}-2`]}</span>
            </div>
          </div>
        )}
      </div>
      €
      <div>
        <input
          type='number'
          step={0.1}
          name={`price-${index}-3`}
          className={clsx(
            'form-control ',
            {
              'is-invalid': formik.touched[`price-${index}-3`] && formik.errors[`price-${index}-3`],
            },
            {
              'is-valid': formik.touched[`price-${index}-3`] && !formik.errors[`price-${index}-3`],
            }
          )}
          placeholder='Prix'
          {...formik.getFieldProps(`price-${index}-3`)}
        />
        {formik.touched[`price-${index}-3`] && formik.errors[`price-${index}-3`] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[`price-${index}-3`]}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const addRow = () => {
    setPriCeInHoure((price) => {
      const newRowId = uuidv4()
      uuidv4()
      const newRow = {
        row: <Row index={newRowId} formik={formik} />,
        id: newRowId, // Generate a unique key for the row
        ref: newRowId,
      }
      return [...price, newRow]
    })
  }

  const removeRow = (id) => {
    //console.log(id)

    const updatedPriCeInHoure = priCeInHoure.filter((row) => row.id != id)
    setPriCeInHoure(updatedPriCeInHoure)
  }

  return (
    <div>
      <div className='row mb-4'>
        <label htmlFor='country' className='form-label required'>
          Catégorie de véhicule/Type d'icône de carte
        </label>
        <SearchSelect
          options={vehiculeCategory}
          defaultValue={formik.values.vehiculeCategory}
          name={'vehiculeCategory'}
          formik={formik}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={' Type de véhicule '}
          name={'vehicleType'}
          isUserLoading={isUserLoading}
          placeholder={0}
        />
      </div>
      <div className='row mb-4'>
        <label htmlFor='country' className='form-label required'>
          Type de véhicule pour Booking.com
        </label>
        <SearchSelect
          options={vehiculeCategoryBooking}
          defaultValue={formik.values.vehiculeCategoryBooking}
          name={'vehiculeCategoryBooking'}
          formik={formik}
        />
      </div>
      {languageInputs}
      {/* select  location */}
      <div className='row mb-4'>
        <label htmlFor='selectLocation' className='form-label required'>
          Sélectionnez un emplacement
        </label>
        <div className='row'>
          <div className='col-7'>
            <SearchSelect
              options={selectLocation}
              defaultValue={formik.values.selectLocation}
              name={'selectLocation'}
              formik={formik}
            />
          </div>
          <div className='col-3'>
            {' '}
            <Link to={'/add-location'} target='_blank' className='btn btn-primary'>
              Ajouter un nouvel emplacement
            </Link>
          </div>
        </div>
      </div>
      <div className='border p-3 mb-4'>
        {/* price per kms */}
        <div className='row'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'  Prix ​​par KM (Prix en EUR) '}
              name={'PricePerKMs'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>

          <div className='col mb-4'>
            <Accordion
              titre={'Prix ​​par KM (Prix en EUR)  (rouler plus tard)'}
              readonly={true}
              required={true}
              accordionId={`PricePerKMs`}
            >
              <div>
                {pricePerKMs.map((item: any, index: number) => (
                  <div className='d-flex flex-center gap-3 mb-4' key={index}>
                    <div>
                      <input type='text' className='form-control' value={item.number1} readOnly />
                    </div>
                    -
                    <div>
                      <input type='text' className='form-control' value={item.number2} readOnly />
                    </div>
                    €
                    <div className=''>
                      <input
                        type='number'
                        step={0.1}
                        name={`${item.code}Later`}
                        className={clsx(
                          'form-control ',
                          {
                            'is-invalid':
                              formik.touched[`${item.code}Later`] &&
                              formik.errors[`${item.code}Later`],
                          },
                          {
                            'is-valid':
                              formik.touched[`${item.code}Later`] &&
                              !formik.errors[`${item.code}Later`],
                          }
                        )}
                        placeholder='Prix'
                        {...formik.getFieldProps(`${item.code}Later`)}
                      />
                      {formik.touched[`${item.code}Later`] && formik.errors[`${item.code}Later`] && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors[`${item.code}Later`]}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
        {/* price per min */}
        <div className='row'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={' Prix ​​par minute (Prix en EUR) '}
              name={'PricePerMin'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Prix ​​par minute (Prix en EUR) (rouler plus tard)'}
              name={'PricePerMinLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/* tarif minimum */}
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Tarif minimum (Prix en EUR)'}
              name={'MinimumFare'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={' Tarif minimum (prix en EUR) (rouler plus tard)'}
              name={'MinimumFareLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/* tarif base */}
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Tarif de base (prix en EUR)'}
              name={'BaseFare'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Tarif de base (prix en EUR) (rouler plus tard)'}
              name={'BaseFareLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/* Rider Cancellation Time Limit */}
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Délai d'annulation du passager (en minutes)"}
              name={'RiderCancellationTimeLimit'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <Accordion
              titre={"Délai d'annulation du passager (en heures) (rouler plus tard)"}
              readonly={true}
              required={true}
              accordionId={`RiderCancellationTimeLimitLater`}
            >
              {priCeInHoure.map((row: any, index: number) => (
                <div className='d-flex' key={row.ref}>
                  {row.row}
                  <button
                    className='btn btn-icon btn-light-danger btn-sm'
                    onClick={() => removeRow(row.id)}
                  >
                    <i className='ki-duotone ki-cross-circle fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </button>
                </div>
              ))}
              <div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-primary mt-3'
                    onClick={() => addRow()} // Add a new row when clicked
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        {/* Rider Cancellation Time Limit */}
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Limite de temps d'attente (en minute) "}
              name={'WaitingTimeLimit'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Limite de temps d'attente (en minute) (rouler plus tard)"}
              name={'WaitingTimeLimitLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/* Waiting Charges */}
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Frais d'attente (Prix en EUR)  "}
              name={'WaitingCharges'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Frais d'attente (Prix en EUR)  (rouler plus tard)"}
              name={'WaitingChargesLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/*Airport Waiting Time Limit  */}
        <div className='row mb-7'>
          <div className='col'></div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Limite de temps d'attente à l'aéroport (en minutes) (rouler plus tard) "}
              name={'AirportWaitingTimeLimitMin'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        {/*Airport Waiting Charges */}
        <div className='row mb-7'>
          <div className='col'></div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Frais d'attente à l'aéroport (prix en EUR) (rouler plus tard"}
              name={'AirportWaitingChargesLater'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
      </div>
      {/* bookin */}
      <div className='border p-3 mb-4'>
        <h5 className='mb-7'>Tarifs Booking.Com</h5>
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Prix ​​par KM (Prix en EUR)'}
              name={'pricePerKMsBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Prix ​​par minute (Prix en EUR) '}
              name={'PricePerMinBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Prix ​​Par Heure (Prix En EUR)'}
              name={'PricePerHourBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Tarif minimum (Prix en EUR) '}
              name={'MinimumFareBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={'Tarif de base (prix en EUR) '}
              name={'BaseFareBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Délai d'annulation du passager (en minutes) "}
              name={'RiderCancellationTimeLimitBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
        <div className='row mb-7'>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Limite de temps d'attente (en minute)"}
              name={'WaitingTimeLimitBooking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
          <div className='col'>
            <LabelInput
              type={'number'}
              formik={formik}
              label={"Frais d'attente (Prix en EUR) "}
              name={'WaitingChargesBokking'}
              isUserLoading={isUserLoading}
              placeholder={0}
            />
          </div>
        </div>
      </div>
      {/* commision */}
      <div className='row mb-4'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={'Commissions  (%)'}
          name={'commision'}
          isUserLoading={isUserLoading}
          placeholder={0}
        />
      </div>
      {/* Rider Cancellation Charges */}
      <div className='row mb-4'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={"Frais d'annulation du passager (prix en EUR)"}
          name={'riderCancellationCharges'}
          isUserLoading={isUserLoading}
          placeholder={0}
        />
        <span>
          Remarque : Des frais d'annulation ne seront appliqués que si le mode de paiement est par
          carte de crédit.
        </span>
      </div>
      {/* Capacité sièges disponibles/personne */}
      <div className='row mb-4'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={'Capacité sièges disponibles/personne'}
          name={'availableSeats'}
          isUserLoading={isUserLoading}
          placeholder={1}
        />
        <span>Remarque : Hors prestataire/chauffeur</span>
      </div>
      {/* Sacs disponibles */}
      <div className='row mb-4'>
        <LabelInput
          type={'number'}
          formik={formik}
          label={'Capacité sièges disponibles/personne'}
          name={'sacsDisponibles'}
          isUserLoading={isUserLoading}
          placeholder={1}
        />
      </div>
      {/* Peak Time Surcharge On/Off  */}
      <div className='row mb-4'>
        <LabelRadio
          val={'peakTimeSurcharge'}
          type={'checkbox'}
          formik={formik}
          label={'Surcharge aux heures de pointe activée/désactivée'}
          name={'peakTimeSurcharge'}
          isUserLoading={isUserLoading}
        />
      </div>
      {/* Peak Time Surcharge On/Off For Booking.com */}
      <div className='row mb-4'>
        <LabelRadio
          val='peakTimeSurchargeBooking'
          type={'checkbox'}
          formik={formik}
          label={'Surcharge aux heures de pointe activée/désactivée  pour Booking.com'}
          name={'peakTimeSurchargeBooking'}
          isUserLoading={isUserLoading}
        />
      </div>
      {/* Night Charges On/Off */}
      <div className='row mb-4'>
        <LabelRadio
          val='nightCharges'
          type={'checkbox'}
          formik={formik}
          label={'Charges de nuit activées/désactivées'}
          name={'nightCharges'}
          isUserLoading={isUserLoading}
        />
      </div>
      {/* Night Charges On/Off For Booking.com */}
      <div className='row mb-4'>
        <LabelRadio
          val='nightChargesBooking'
          type={'checkbox'}
          formik={formik}
          label={'Charges de nuit activées/désactivées'}
          name={'nightChargesBooking'}
          isUserLoading={isUserLoading}
        />
      </div>
      <div className='row mb-4'>
        <LabelFilePreview
          name='vehicleTypePictureGray'
          formik={formik}
          label={'Image du type de véhicule (image grise)'}
          isUserLoading={isUserLoading}
        />
        <span>Remarque : Téléchargez uniquement une taille d'image png de 360 px * 360 px.</span>
      </div>
      <div className='row mb-4'>
        <LabelFilePreview
          name='vehicleTypePictureOrange'
          formik={formik}
          label={'Image du type de véhicule (image orange)'}
          isUserLoading={isUserLoading}
        />
        <span>Remarque : Téléchargez uniquement une taille d'image png de 360 px * 360 px.</span>
      </div>
      <div className='row mb-4'>
        <label htmlFor='country' className='form-label required'>
          Catégorie de véhicule/Type d'icône de carte
        </label>
        <SearchSelect
          options={commandes}
          defaultValue={formik.values.commandes}
          name={'commandes'}
          formik={formik}
        />
      </div>
    </div>
  )
}

export default Form1
