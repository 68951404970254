import {ChangeEvent, FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {UseCallApi} from '../../../_metronic/CallApi'
import SearchSelect from '../../modules/apps/customs/Selects/SearchSelect'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelTextArea from '../../../_metronic/partials/LabelTextArea'
import Curencies from '../../../_metronic/assets/json/Curencies.json'
import LabelSwitch from '../../../_metronic/partials/LabelSwitch'

type Props = {
  isUserLoading: boolean
  formik?: any
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  // const [image, setImage] = useState<File | any>([])
  const [languages, setLanguages] = useState([])
  const [countries, setCountries] = useState<any>([])
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)

  const callApi = UseCallApi()

  return (
    <div>
      <div className='row mb-4'>
        <LabelInput
          label={'Industrie'}
          type={'text'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'Industry'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Emplacement'}
          type={'text'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'location'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Nom de la SOCIETE'}
          type={'text'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'corporate'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Email'}
          type={'email'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'email'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Mobile'}
          type={'phone'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'phone'}
        />
      </div>
      <div className='row mb-4'>
        <LabelInput
          label={'Rabais(%)'}
          type={'number'}
          formik={formik}
          isUserLoading={isUserLoading}
          name={'discount'}
        />
      </div>
      <div className='row mb-4'>
        <LabelTextArea
          formik={formik}
          name={'message'}
          label={'Message'}
          isUserLoading={isUserLoading}
          placeholder={'Message'}
        />
      </div>
      <div className='col mb-7'>
        <label htmlFor='language' className='form-label'>
          Devise
        </label>
        <SearchSelect
          options={Curencies}
          defaultValue={formik.values.curency}
          name={'curency'}
          formik={formik}
        />
      </div>
      <div className='col mb-7'>
        <LabelSwitch
          type={'checkbox'}
          label={'Status'}
          formik={formik}
          name={'status'}
          isUserLoading={isUserLoading}
        />
      </div>
    </div>
  )
}

export default Form1
