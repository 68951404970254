import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { UsersListLoading } from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import { Field, useFormik } from 'formik'
import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import { useQueryResponse } from '../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import From1AddDriver from './From1AddDriver'
import From2AddDriver from './From2AddDriver'
import HeaderWrapper from '../../../_metronic/layout/components/header/HeaderWrapper'
import { UseCallApi } from '../../../_metronic/CallApi'
import 'react-toastify/dist/ReactToastify.css'
import { AppContext } from '../../../AppContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../_metronic/partials/spinner/Spinner'

const nameValidation = /^[A-Za-z\s]+$/
const editUserSchema = Yup.object().shape({
  tel: Yup.string().required('Le numéro de téléphone est requis'),
  nom: Yup.string()
    .matches(nameValidation, 'Le nom ne peut contenir que des lettres')
    .required('Le nom est requis'),
  prenom: Yup.string()
    .matches(nameValidation, 'Le prénom ne peut contenir que des lettres')
    .required('Le prénom est requis'),
  sexe: Yup.string().required('Veuillez sélectionner le sexe'),
  email: Yup.string().email("Format d'email incorrect").required("L'email est requis"),
  password: Yup.string().min(6, 'Minimum 6 caractères').required('Le mot de passe est requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
  adress: Yup.string().required("L'adresse est requise"),
  zipCode: Yup.string().required('Le code postal est requis'),

  state: Yup.string(),
  city: Yup.string(),
  company: Yup.string(),
  // paymentEmail: Yup.string()
  //   .email("Format d'email incorrect")
  //   .required("L'email de paiement est requis"),
  // AccountHolderName: Yup.string().required('Le nom du titulaire du compte est requis'),
  // AccountNumber: Yup.string().required('Le numéro de compte est requis'),
  // BankName: Yup.string().required('Le nom de la banque est requis'),
  // BankLocation: Yup.string().required("L'emplacement de la banque est requis"),
  // BIC_SWIFT: Yup.string().required('Le code BIC/SWIFT est requis'),
})
const AddDriver = () => {
  const { id, idusers } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [updateData, setUpdateData] = useState<any>({})
  const [currentStep, setCurrentStep] = useState(1)
  const { errorToas, successToas } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [myIdCountries, setMyIdCountries] = useState(0)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [image, setImage] = useState<File | any>()
  const [formDoc, setFormDoc] = useState<any>([])
  const [filess, setFiles] = useState<any>()
  const callApi = UseCallApi()
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File | null>>({})
  const [paperUsers, setpaperUsers] = useState<any[]>([])
  const getDriver = async (id) => {
    try {
      const data = await callApi({
        route: `drivers/${id}`,
        method: 'GET',
      })
      setUpdateData(data)
      setFiles(`${process.env.REACT_APP_API_URL}/${data?.user.photo}`)
    } catch (error: any) {
      errorToas(error.response['data'].detail)
    }
  }
  useEffect(() => {
    if (id) {
      getDriver(id)
    }
  }, [id])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tel: updateData ? updateData.tel : '',
      nom: updateData ? updateData?.user?.lastname : '',
      prenom: updateData ? updateData?.user?.firstname : '',
      email: updateData ? updateData.email : '',
      password: updateData ? updateData.password : 'aqwaqw',
      confirmPassword: updateData ? updateData.password : 'aqwaqw',
      sexe: updateData ? updateData.sexe : '',
      country: updateData ? updateData.countries?.id : '',
      state: updateData ? updateData.state?.id : '',
      city: updateData ? updateData.city?.id : '',
      adress: updateData ? updateData.adress : '',
      zipCode: updateData ? updateData.zipCode : '',
      company: updateData.company ? updateData.company.id : '',
      language: updateData.language ? updateData.language.lang : '',
      currency: '',
      paymentEmail: updateData ? updateData.paymentEmail : '',
      AccountHolderName: updateData ? updateData.accountHolderName : '',
      AccountNumber: updateData ? updateData.accountNumber : '',
      BankName: updateData ? updateData.bankName : '',
      BankLocation: updateData ? updateData.bankLocation : '',
      BIC_SWIFT: updateData ? updateData.bic_swift : '',
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // setpaperUsers((...element)=>(

      // ))

      // const elementsPapers = paperUsers.map((element) => ({
      //   name: element.name,
      //   label: element.label,
      // }))
      setSubmitting(true)
      // const data = {
      //   paperUsers: [
      //     {
      //       state: 'string',
      //       title: 'string',
      //       elementsPapers,
      //     },
      //   ],
      // }
      const postData = {
        password: values.password,
        email: values.email,
        roles: ['ROLE_DRIVER'],
        typeAccount: 'DRIVER',
        lang: `languages/${values.language}`,
        photo: image ? image[0] : null,
        firstname: values.prenom,
        lastname: values.nom,
        ...paperUsers.reduce((acc, obj) => {
          obj.elementsPapers.forEach((element) => {
            const key = element.name
            if (values[key]) {
              acc[element.name] = values[key]
            }
          })
          return acc
        }, {}),
        paperUsers,
        driver: {
          id: id ? id : null,
          // company: `companies/1`,
          // company: `companies/${values.company}`,
          countries: `countries/${values.country}`,
          city: `cities/${values.city}`,
          state: `states/${values.state}`,
          bic_swift: values.BIC_SWIFT,
          bankName: values.BankName,
          accountNumber: values.AccountNumber,
          accountHolderName: values.AccountHolderName,
          paymentEmail: values.paymentEmail,
          zipCode: Number(values.zipCode),
          bankLocation: values.BankLocation,
          adress: values.adress,
          // state: values.state,
          // state: JSON.parse('true'),
          // dispo: JSON.parse('false'),
          // password: values.password,
          // tel: values.tel,
          // fulname: `${values.prenom} ${values.nom}`,
          // email: values.email,

          sexe: values.sexe,
          // createdAt: '2023-08-15T11:15:16.614Z',
          // updatedAt: '2023-08-15T11:15:16.614Z',
          lastLogin: 'string',
        },
      }
      //console.log(postData);

      try {
        const postDriver = async () => {
          try {
            //console.log('-------------');

            const response = await callApi({
              route: location.pathname == '/add-driver' ? 'users' : `update_user/${idusers}`,
              method: 'POST',
              body: postData,
              formData: true,
            })
            if (response.status === 201) {
              //console.log(response);

              successToas('success')
              navigate('/apps/user-management/users')
            }
          } catch (error: any) {
            errorToas(error.response['data'].detail)
            setTimeout(() => setIsLoading(false), 1000)
          }
        }
        postDriver()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        // //console.log('end')
        setSubmitting(false)
        // Add any cleanup or redirect logic after the form submission is complete
      }
    },
  })
  const getForm = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?&model=chauffeur&countries=${formik.getFieldProps('country').value
          }`,
        method: 'GET',
        jsonLd: true,
      })
      const form = data['hydra:member'][0].formDocuments

      setFormDoc(form) // Clear formDoc by setting it to an empty array
      // Clear paperUsers by setting it to an empty array
      setpaperUsers([])
      let elementsPapers: any = []
      let objects: any = []
      for (let index = 0; index < form.length; index++) {
        const elements = form[index].elementFormDocuments

        const title = form[index].title
        // let myId: null = null // Initialize 'id' with null
        // if (updateData) {
        //   myId = updateData.paperUsers[index].id
        // }
        elementsPapers = []
        for (let secondIndex = 0; secondIndex < elements.length; secondIndex++) {
          const element = elements[secondIndex]

          // elementsPapers.push(element)
          // let id: null = null // Initialize 'id' with null
          // if (updateData) {
          //   id = updateData.paperUsers[index].elementsPapers[secondIndex].id
          //     ? updateData.paperUsers[index].elementsPapers[secondIndex].id
          //     : null
          // }

          const obj = {
            // id: id ? id : null,
            label: element.label,
            name: element.id,
          }

          elementsPapers.push(obj)
        }

        objects.push({ title, state: 'state', elementsPapers })
        // objects.push({title, state: 'state', id: myId, elementsPapers})
      }

      setpaperUsers(objects)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getForm()
  }, [formik.getFieldProps('country').value])
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }
  const handleFileUpload = (title: string, file: File | null) => {
    setUploadedFiles((prevState) => ({
      ...prevState,
      [title]: file,
    }))
  }
  const Links = [
    { title: 'Liste', link: '/apps/user-management' },
    { title: 'En attente', link: '/en-attente' },
    { title: 'Disponible', link: '/disponible' },
    { title: 'Drives', link: '/drives' },
  ]
  return (
    <>
      <HeaderWrapper linkss={Links} />
      {currentStep === 1 && (
        <From1AddDriver
          setMyidCountries={setMyIdCountries}
          formik={formik}
          isUserLoading={isUserLoading}
          setImage={setImage}
          image={image}
          setFiles={setFiles}
          filess={filess}
        />
      )}
      {currentStep === 2 && (
        <From2AddDriver
          form={formDoc}
          uploadedFiles={uploadedFiles}
          handleFileUpload={handleFileUpload}
          formik={formik}
          updateData={updateData}
          isUserLoading={isUserLoading}
        />
      )}
      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {currentStep > 1 && (
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={handlePreviousStep}
            disabled={formik.isSubmitting || isUserLoading}
          >
            Précédente
          </button>
        )}
        {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleNextStep}
            disabled={formik.isSubmitting || isUserLoading || !formik.isValid ? true : false}
          >
            Suivant
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={(e: any) => {
                  formik.handleSubmit(e)
                  setIsLoading(true)
                }}
                disabled={formik.isSubmitting || !formik.isValid ? true : false}
              >
                Submit
              </button>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AddDriver
