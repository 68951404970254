import React, {FC, useState} from 'react'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import * as Yup from 'yup'

import {Field, useFormik} from 'formik'
import clsx from 'clsx'
import Spinner from '../../../../../../../_metronic/partials/spinner/Spinner'
import {Link} from 'react-router-dom'
const editUserSchema = Yup.object().shape({
  code: Yup.string().required('Le code est requis'),
  name: Yup.string().required('Le nom est requis'),
  country: Yup.string().required('Le code de téléphone est requis'),
  state: Yup.string().required('Le code de téléphone est requis'),
})
const PagesTable: FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const [currency, setCurrency] = useState<any[]>([
    {id: 1, name: "Page d'inscription	"},
    {id: 2, name: "Page d'inscription du cavalier	"},
    {id: 3, name: 'Page de connexion du cavalier	'},
    {id: 4, name: "Connexion d'atterrissage dans la page	"},
  ])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // country: updateData?.pay?.id,
      // state: updateData.state ? updateData.state.id : '',
      // code: updateData?.codeP,
      // name: updateData?.name,
      // status: updateData.status ? updateData.status : false,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      //console.log(values)
      setSubmitting(true)
      // const postData = {
      //   name: values.name,
      //   state: `states/${values.state}`,
      //   dispo: false,
      //   code: values.code,
      //   pay: `countries/${values.country}`,
      //   status: values.status,
      // }

      // const formdata = new FormData()
      // // formdata.append('data')
      // //console.log(postData)

      // try {
      //   const postCity = async () => {
      //     try {
      //       const data = await callApi({
      //         route:
      //           location.pathname === '/city-management/add' ? 'cities' : `cities/${id}`,
      //         method: location.pathname === '/city-management/add' ? 'POST' : `PUT`,
      //         body: postData,
      //       })
      //       if (data) {
      //         setIsLoading(false)
      //         successToas('success')
      //         navigate('/city-management')
      //       }
      //     } catch (error: any) {
      //       setIsLoading(false)
      //       errorToas(error.response['data'].detail)
      //     }
      //   }
      //   postCity()
      //   // //console.log({...values, image})
      //   // Handle form submission here, e.g., call an API to create the user
      // } catch (ex) {
      //   console.error(ex)
      // } finally {
      //   setSubmitting(false)
      // }
    },
  })
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {/* Add table headers here */}
              <th>Nom de la page</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className='text-start text-gray-600 fw-bold'>
            {currency.length > 0 ? (
              currency.map((item: any) => (
                <tr key={item.id}>
                  <td className='w-75'>{item.name}</td>
                  <td>
                    <div className='me-3'>
                      <Link
                        to={`/pages-management/update`}
                        className='btn btn-icon btn-light-warning btn-sm '
                      >
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='text-center pt-15 mb-7'>
          {isLoading ? (
            <button type='button' className='btn btn-primary' disabled={true} aria-readonly>
              <Spinner />
            </button>
          ) : (
            <button
              type='submit'
              className='btn btn-primary'
              onClick={(e: any) => formik.handleSubmit(e)}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </KTCardBody>
  )
}

export {PagesTable}
