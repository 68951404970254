import {ID, Response} from '../../../../../../_metronic/helpers'
export type Drive = {
  id?: ID
  tel?: string
  passagier?: string
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  sexe?: string
  adress?: string
  zipCode?: number
  company?: string
  currency?: string
  task?: any
  paymentEmail?: string
  AccountHolderName?: string
  password?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  permis?: any
  permis_verso?: any
  carte_grise_recto?: any
  carte_grise_verso?: any
  assurance_verso?: any
  assurance_recto?: any
  carte_de_conduire_recto?: any
  carte_de_conduire_verso?: any
  piece_authentification_recto?: any
  piece_authentification_verso?: any
  walet?: any
  document?: any
  favori?: any
  statu?: number
  city?: string
  distance?: number
  initials?: {
    label: string
    state: string
  }
}

export type DrivesQueryResponse = Response<Array<Drive>>

export const initialDrive: Drive = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
}
