import React, {FC, useMemo} from 'react'

import {useLocation} from 'react-router-dom'
import {PassagierInfo} from '../../user-management/users-list/table/columns/Drive/PassagierInfo'

type Props = {
  data?: any
  drives?: any
}
const ListChauffeur: FC<Props> = ({data}) => {
  const location = useLocation()
  const path = location.pathname
  return (
    <div className='mb-5'>
      <div className='accordion' id='driversliste'>
        <div className='accordion-item'>
          <h2 className='accordion-header' id='driversliste_header_1'>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#drivers'
              aria-expanded='false'
              aria-controls='drivers'
            >
              Les chauffeurs
            </button>
          </h2>
          <div
            id='drivers'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#driversliste'
          >
            <div className='accordion-body row '>
              <div className='table-responsive'>
                <table className='table gs-7 gy-7 gx-7 table-hover'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                      <th>Chauffeur</th>
                      <th>Code</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((user: any, index: number) => (
                        <tr key={user?.id}>
                          <td>
                            <PassagierInfo user={user} />
                          </td>
                          <td>{user?.code}</td>
                          <td>
                            <a
                              className='btn btn-icon btn-light-danger btn-sm '
                              data-kt-users-table-filter='delete_row'
                            >
                              <i className='bi bi-trash-fill fs-4'></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListChauffeur
