import React, {useState, useEffect, useContext} from 'react'
import Tab from './Tab'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import {getUserByToken, register} from '../core/_requests'
import From1AddDriver from '../../../components/Driver/From1AddDriver'
import {Link, useNavigate} from 'react-router-dom'
import Spinner from '../../../../_metronic/partials/spinner/Spinner'
import From2AddDriver from '../../../components/Driver/From2AddDriver'
import {UseCallApi} from '../../../../_metronic/CallApi'
import {AppContext} from '../../../../AppContext'

const editUserSchema = Yup.object().shape({
  tel: Yup.string().required('Le numéro de téléphone est requis'),
  nom: Yup.string().required('Le nom est requis'),
  prenom: Yup.string().required('Le prénom est requis'),
  sexe: Yup.string().required('Veuillez sélectionner le sexe'),
  email: Yup.string().email("Format d'email incorrect").required("L'email est requis"),
  password: Yup.string().min(6, 'Minimum 6 caractères').required('Le mot de passe est requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Les mots de passe doivent correspondre')
    .required('La confirmation du mot de passe est requise'),
  adress: Yup.string().required("L'adresse est requise"),
  zipCode: Yup.string().required('Le code postal est requis'),

  state: Yup.string().required('Le code postal est requis'),
  city: Yup.string().required('Le code postal est requis'),
  company: Yup.string().required("Le nom de l'entreprise est requis"),
  // paymentEmail: Yup.string()
  //   .email("Format d'email incorrect")
  //   .required("L'email de paiement est requis"),
  // AccountHolderName: Yup.string().required('Le nom du titulaire du compte est requis'),
  // AccountNumber: Yup.string().required('Le numéro de compte est requis'),
  // BankName: Yup.string().required('Le nom de la banque est requis'),
  // BankLocation: Yup.string().required("L'emplacement de la banque est requis"),
  // BIC_SWIFT: Yup.string().required('Le code BIC/SWIFT est requis'),
})

const DriverForm = () => {
  const [isUserLoading, setIsUserLoading] = useState(false) // or true if initially loading
  const [image, setImage] = useState<File | any>()
  const navigate = useNavigate()

  const {successToas, errorToas} = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [filess, setFiles] = useState<any>()
  const [currentStep, setCurrentStep] = useState(1) // Step 1 by default
  const [formDoc, setFormDoc] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [paperDrivers, setpaperDrivers] = useState<any[]>([])

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }
  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }
  const getForm = async () => {
    try {
      const data = await UseCallApi()({
        route: `form_model_documents?&model=chauffeur&countries=${
          formik.getFieldProps('country').value
        }`,
        method: 'GET',
        jsonLd: true,
      })
      const form = data['hydra:member'][0].formDocuments

      setFormDoc(form) // Clear formDoc by setting it to an empty array
      // Clear paperDrivers by setting it to an empty array
      setpaperDrivers([])
      let elementsPapers: any = []
      let objects: any = []
      for (let index = 0; index < form.length; index++) {
        const elements = form[index].elementFormDocuments

        const title = form[index].title
        // let myId: null = null // Initialize 'id' with null
        // if (updateData) {
        //   myId = updateData.paperDrivers[index].id
        // }
        elementsPapers = []
        for (let secondIndex = 0; secondIndex < elements.length; secondIndex++) {
          const element = elements[secondIndex]

          // elementsPapers.push(element)
          // let id: null = null // Initialize 'id' with null
          // if (updateData) {
          //   id = updateData.paperDrivers[index].elementsPapers[secondIndex].id
          //     ? updateData.paperDrivers[index].elementsPapers[secondIndex].id
          //     : null
          // }

          const obj = {
            // id: id ? id : null,
            label: element.label,
            name: element.id,
          }

          elementsPapers.push(obj)
        }

        objects.push({title, state: 'state', elementsPapers})
        // objects.push({title, state: 'state', id: myId, elementsPapers})
      }

      setpaperDrivers(objects)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tel: null,
      nom: '',
      prenom: '',
      email: '',
      password: '',
      confirmPassword: '',
      sexe: '',
      country: '',
      state: '',
      city: '',
      adress: '',
      zipCode: '',
      company: '',
      language: '',
      currency: '',
      paymentEmail: '',
      AccountHolderName: '',
      AccountNumber: '',
      BankName: '',
      BankLocation: '',
      BIC_SWIFT: '',
    },

    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // setpaperDrivers((...element)=>(

      // ))

      // const elementsPapers = paperDrivers.map((element) => ({
      //   name: element.name,
      //   label: element.label,
      // }))
      setSubmitting(true)
      // const data = {
      //   paperDrivers: [
      //     {
      //       state: 'string',
      //       title: 'string',
      //       elementsPapers,
      //     },
      //   ],
      // }
      const postData = {
        password: values.password,
        email: values.email,
        roles: ['ROLE_DRIVER'],
        typeAccount: 'DRIVER',
        photo: image ? image[0] : null,
        lastname:values.prenom,
        firstname:values.nom,


        driver: {
          company: `companies/1`,
          // company: `companies/${values?.company}`,
          countries: `countries/${values.country}`,
          city: `cities/${values.city}`,
          lang: `languages/${values.language}`,
          state: `states/${values.state}`,
          bic_swift: values.BIC_SWIFT,
          bankName: values.BankName,
          accountNumber: values.AccountNumber,
          accountHolderName: values.AccountHolderName,
          paymentEmail: values.paymentEmail,
          zipCode: Number(values.zipCode),
          bankLocation: values.BankLocation,
          adress: values.adress,
          // state: values.state,
          // state: JSON.parse('true'),
          // dispo: false,
          password: values.password,
          tel: values.tel,
          email: values.email,
          ...paperDrivers.reduce((acc, obj) => {
            obj.elementsPapers.forEach((element) => {
              const key = element.name
              if (values[key]) {
                acc[element.name] = values[key]
              }
            })
            return acc
          }, {}),
          sexe: values.sexe,
          paperDrivers,
          createdAt: '2023-08-15T11:15:16.614Z',
          updatedAt: '2023-08-15T11:15:16.614Z',
          lastLogin: 'string',
        },
      }

      try {
        const postDriver = async () => {
          try {
            const data = await UseCallApi()({
              route: 'users',
              method: 'POST',
              body: postData,
              formData: true,
            })
            if (data) {
              successToas('success')
              navigate('/')
            }
          } catch (error: any) {
            errorToas(error.response['data'].detail)
            setTimeout(() => setIsLoading(false), 1000)
          }
        }
        postDriver()
        // //console.log({...values, image})
        // Handle form submission here, e.g., call an API to create the user
      } catch (ex) {
        console.error(ex)
      } finally {
        // //console.log('end')
        setSubmitting(false)
        // Add any cleanup or redirect logic after the form submission is complete
      }
    },
  })
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: registrationSchema,
  //   onSubmit: async (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     try {
  //       const {data: auth} = await register(
  //         values.email,
  //         values.firstname,
  //         values.lastname,
  //         values.password,
  //         values.changepassword
  //       )
  //       saveAuth(auth)
  //       const {data: user} = await getUserByToken(auth.api_token)
  //       setCurrentUser(user)
  //     } catch (error) {
  //       console.error(error)
  //       saveAuth(undefined)
  //       setStatus('The registration details is incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })
  useEffect(() => {
    getForm()
  }, [formik.getFieldProps('country').value])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>
      <Tab />
      {/* end::Heading */}
      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>
      {currentStep === 1 && (
        <From1AddDriver
          formik={formik}
          isUserLoading={isUserLoading}
          setImage={setImage}
          image={image}
          setFiles={setFiles}
          filess={filess}
        />
      )}
      {currentStep === 2 && (
        <From2AddDriver form={formDoc} formik={formik} isUserLoading={isUserLoading} />
      )}
      {/* Step navigation */}
      <div className='text-center pt-15 mb-7'>
        {currentStep > 1 && (
          <button
            type='button'
            className='btn btn-light me-3 w-100 mb-5'
            onClick={handlePreviousStep}
            disabled={formik.isSubmitting || isUserLoading}
          >
            Previous
          </button>
        )}
        {currentStep < 2 && (
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={handleNextStep}
          >
            Next
          </button>
        )}
        {currentStep === 2 && (
          <>
            {isLoading ? (
              <button
                type='button'
                className='btn btn-primary w-100 d-flex flex-center'
                disabled={true}
                aria-readonly
              >
                <Spinner />
              </button>
            ) : (
              <button
                type='submit'
                className='btn btn-primary w-100'
                onClick={(e: any) => {
                  formik.handleSubmit(e)
                  setIsLoading(true)
                }}
              >
                Submit
              </button>
            )}
          </>
        )}
        {currentStep !== 2 && (
          <div>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 my-5'
              >
                Cancel
              </button>
            </Link>
          </div>
        )}
      </div>
      {/*      
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
       
      </div> */}
    </form>
  )
}

export default DriverForm
