import {FC} from 'react'
import {useListView} from '../../core/ListViewProvider'
import {DrivesListGrouping} from './DrivesListGrouping'
import {UsersListSearchComponent} from './DrivesListSearchComponent'
import {DrivesListToolbar} from './DrivesListToolbar'
type Props = {
  handleDeleteUser?: any
  setHeaderFilter?: any
  headerFilter?: any
}
const BookingListHeader: FC<Props> = ({setHeaderFilter, headerFilter, handleDeleteUser}) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setHeaderFilter={setHeaderFilter} headerFilter={headerFilter} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <DrivesListGrouping handleDeleteUser={handleDeleteUser} />
        ) : (
          <DrivesListToolbar />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BookingListHeader}
