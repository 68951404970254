import {FC, useState} from 'react'
import LabelInput from '../../../_metronic/partials/LabelInput'
import LabelFilePreview from '../../../_metronic/partials/LabelFilePerview'
import GeneralSeting from './partial/GeneralSeting'
import EmailSeting from './partial/EmailSeting'
import AppearanceSeting from './partial/AppearanceSeting'
import SmsSeting from './partial/SmsSeting'
import SocialMediaSeting from './partial/SocialMediaSeting'
import AppSeting from './partial/AppSeting'
import InstallationSeting from './partial/InstallationSeting'
import PaymentSeting from './partial/PaymentSeting'

type Props = {
  isUserLoading: boolean
  formik?: any
}

const Form1: FC<Props> = ({formik, isUserLoading}) => {
  const [counter, setCounter] = useState(0)

  return (
    <div>
      <div className='row mb-7 '>
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 0 && 'active'}`}
              onClick={(e) => setCounter(0)}
            >
              Général
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 1 && 'active'}`}
              onClick={(e) => setCounter(1)}
            >
              E-mail
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 2 && 'active'}`}
              onClick={(e) => setCounter(2)}
            >
              Apparence
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 3 && 'active'}`}
              onClick={(e) => setCounter(3)}
            >
              Sms
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 4 && 'active'}`}
              onClick={(e) => setCounter(4)}
            >
              Réseaux sociaux
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 5 && 'active'}`}
              onClick={(e) => setCounter(5)}
            >
              Paramètres de l'application{' '}
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 6 && 'active'}`}
              onClick={(e) => setCounter(6)}
            >
              Paramètres d'installation{' '}
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link  fs-3 ${counter == 7 && 'active'}`}
              onClick={(e) => setCounter(7)}
            >
              Paiement{' '}
            </button>
          </li>
        </ul>
      </div>
      <div className='row mb-7 '>
        {counter == 0 ? (
          <GeneralSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 1 ? (
          <EmailSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 2 ? (
          <AppearanceSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 3 ? (
          <SmsSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 4 ? (
          <SocialMediaSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 5 ? (
          <AppSeting formik={formik} isUserLoading={isUserLoading} />
        ) : counter == 6 ? (
          <InstallationSeting formik={formik} isUserLoading={isUserLoading} />
        ) : (
          <PaymentSeting formik={formik} isUserLoading={isUserLoading} />
        )}
      </div>
    </div>
  )
}

export default Form1
